import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Faq from '../../components/Faq'
import Meta from '../../components/Meta'
import vatlImg from "../../assets/images/Vat-Consultant-1.png"
import individualImg from "../../assets/images/income-Tax-1.png"
import etinImg from "../../assets/images/e-tin.png"
import corporateImg from "../../assets/images/Corporate-Tax-Rate-in-Bangladesh.png"
import binImg from "../../assets/images/BIN-Registration-1.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import corporateImg2 from "../../assets/images/Corporate-Tax-Rate-in-Bangladesh.png"

function CorporateTax() {
    const faqItems = [
        {
            title: 'What is the minimum tax for limited company in Bangladesh?',
            content: <strong>0.6%,  Alternative minimum tax: A minimum tax is imposed at 0.6% on gross receipts from all
                sources for a company or a firm that has gross receipts of more than BDT 5 million,
                irrespective of profit or loss, where the minimum tax is higher than the corporate tax liability.</strong>
        },
        {
            title: 'What is minimum corporate income tax?',
            content: <strong>BIR reverts Corporate Income Tax rates, Minimum Corporate tax ...
                3% The rate of minimum corporate income tax (MCIT) for domestic and resident foreign corporations,
                including offshore banking units and regional operating headquarters, shall now revert to two percent (2%) based on the gross income of such corporations.</strong>
        },
        {
            title: 'What is the corporate tax of the bank?',
            content: <strong>The tax rate for listed banks, insurance companies and NBFIs is 37.5 per cent. It is 40 per cent for the non-listed firms in the same categories.</strong>
        }
    ];
    return (
        <>
            <Meta title="Corporate TAX"
                description="This page provides information about BSTI permission and related processes."
                image={corporateImg}
                keywords="keyword1, keyword2, keyword3"
            />
            
            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 ">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Corporate TAX</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Taxation</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Corporate TAX</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={corporateImg} alt='Corporate-Tax-Rate-in-Bangladesh-1' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>What is Corporate Tax</b>
                    </p>
                    <p>
                        Corporate tax is a tax imposed on the profits earned by corporations or businesses.
                        In most countries, corporations are separate legal entities that are subject to tax
                        on their income, just like individuals. Corporate tax is typically calculated as a
                        percentage of the corporation’s taxable income, which is the amount of income that is
                        subject to taxation after allowable deductions and exemptions. The tax rate and rules
                        vary from country to country, and some countries may offer tax incentives or
                        exemptions to encourage business growth and investment. Corporate tax revenue is an
                        important source of income for governments, and it is used to fund public services
                        and programs.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>What is the corporate tax rate in Bangladesh</b>
                    </p>
                    <p>
                        In Bangladesh, the corporate tax rate is 25% for domestic companies, which includes
                        both publicly traded and privately held companies. However, if the company is engaged
                        in certain sectors such as the power generation, banking, insurance, and non-banking
                        financial institutions, then the corporate tax rate can be different. For example,
                        the corporate tax rate for the power generation sector is 20%. Additionally, there is
                        a provision for a reduced tax rate of 10% for newly listed companies on a stock
                        exchange in Bangladesh for the first two years of listing. It’s important to note
                        that tax rates and regulations are subject to change, so it’s always a good idea to
                        consult with a tax professional or the local tax authority for the most up-to-date
                        information.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>What are the tax filing and payment deadlines for corporations in Bangladesh</b>
                    </p>
                    <p>
                        In Bangladesh, the tax filing and payment deadlines for corporations are as follows:
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The tax year for corporations in Bangladesh runs from July 1 to June 30 of the following year.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The deadline for filing the annual tax return is September 30 of the following year.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Corporate taxpayers must make advance tax payments in four instalments during the tax year, by the 15th day of September, December, March, and June.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The final tax payment, which is the balance of the tax due after deducting the advance tax payments, is due by September 30.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                In addition to the annual tax return, corporations are required to submit monthly or quarterly withholding tax statements and VAT returns.
                            </span>
                        </div>
                        It’s important to note that these deadlines are subject to change, and penalties may
                        apply for late or incorrect filings or payments. Therefore, it’s important for
                        corporations to consult with a tax professional or the local tax authority to ensure
                        compliance with the tax laws and regulations.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Are there any special tax rules for foreign companies doing business in Bangladesh</b>
                    </p>
                    <p>
                        Yes, there are special tax rules for foreign companies doing business in Bangladesh.
                        Foreign companies are subject to corporate tax on their Bangladesh-sourced income,
                        including income from business activities conducted through a permanent establishment
                        (PE) in Bangladesh. A PE is generally defined as a fixed place of business through
                        which the foreign company carries out its business activities, such as an office,
                        factory, or warehouse.<br /><br />

                        The tax rate for foreign companies is the same as for domestic companies, which is
                        currently 25% on taxable income. However, there are some tax incentives available for
                        foreign investors in certain sectors, such as infrastructure, power generation, and
                        export-oriented industries. These incentives include tax holidays, reduced tax rates,
                        and tax exemptions.<br /><br />

                        Foreign companies are also required to withhold tax on payments made to non-residents,
                        such as royalties, technical fees, and dividends. The withholding tax rates vary
                        depending on the type of payment and the residency of the recipient.<br /><br />

                        Additionally, there are specific rules for transfer pricing, which is the pricing of
                        transactions between related parties, such as a foreign parent company and its
                        subsidiary in Bangladesh. The transfer pricing rules aim to ensure that these
                        transactions are conducted at arm’s length, meaning that they are priced as if they
                        were conducted between unrelated parties.
                    </p>
                    <p>
                        <img className="mx-auto responsive-img" src={corporateImg2}
                            alt='Corporate-Tax-Rate-in-Bangladesh' style={{ width: 1400, height: 300 }}
                        />
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Tax Rate For Company</b>
                    </p>
                    <Table striped bordered hover variant="light blue" >
                        <thead>
                        </thead>
                        <tbody>
                            <tr>
                                <td>(i)</td>
                                <td>Publicly Traded Company</td>
                                <td>25%</td>
                            </tr>
                            <tr>
                                <td>(ii)</td>
                                <td>Non-publicly Traded Company</td>
                                <td>32.5%</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>(iii) Bank, Insurance & Financial institutions (Except Merchant bank):</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>1. Publicly listed and 4th generation Banks & FI</td>
                                <td>40%</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>2. Other Banks & FI</td>
                                <td>42.5%</td>
                            </tr>
                            <tr>
                                <td>(iv)</td>
                                <td>Merchant bank</td>
                                <td>37.5%</td>
                            </tr>
                            <tr>
                                <td>(v)</td>
                                <td>Cigarette manufacturing company/Others</td>
                                <td>45%</td>
                            </tr>
                            <tr>
                                <td>(vi)</td>
                                <td>Mobile Phone Operator Company</td>
                                <td>45%</td>
                            </tr>
                            <tr>
                                <td>(vii)</td>
                                <td>Publicly traded mobile company</td>
                                <td>40%</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>If any non-publicly traded company transfers a minimum of 20% shares of its paid-up capital through IPO (Initial Public Offering) it would get 10% rebate on total tax in the year of transfer.</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className="color-text fs-5 mb-4">
                        <b>How can companies ensure compliance with Bangladesh’s corporate tax laws and regulations</b>
                    </p>
                    <p>
                        To ensure compliance with Bangladesh’s corporate tax laws and regulations, companies
                        can take the following steps:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Maintain accurate accounting records: Proper accounting records should be maintained to support all financial transactions and tax returns. The records should include invoices, receipts, bank statements, and other financial documents.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Monitor changes in tax laws and regulations: Tax laws and regulations in Bangladesh can change frequently, so it’s important for companies to stay up-to-date on any changes that may affect their tax obligations. Companies can do this by monitoring government announcements, consulting with tax professionals, and attending tax seminars.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                File tax returns on time: Companies must file their tax returns on time to avoid penalties and interest charges. The deadlines for filing tax returns in Bangladesh are usually in September of each year, but companies should check with the local tax authority for specific deadlines.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Make tax payments on time: They should make their tax payments on time to avoid penalties and interest charges. Tax payments are usually made in four installments throughout the tax year, with a final payment due by September 30.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Seek professional advice: Companies can seek advice from tax professionals, such as accountants or tax lawyers, to ensure that they are in compliance with all tax laws and regulations in Bangladesh. These professionals can also provide guidance on tax planning strategies to minimize tax liabilities.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Maintain proper documentation: They should maintain proper documentation of all tax-related transactions and filings, such as tax returns, invoices, receipts, and correspondence with the tax authorities. This documentation can be used to support tax filings and to respond to any inquiries or audits by the tax authorities.
                            </span>
                        </div>
                        By following these steps, companies can ensure compliance with Bangladesh’s corporate tax laws and regulations and minimize their risk of penalties, interest charges, or other tax-related issues.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>What is the penalty for late or incorrect tax filing in Bangladesh</b>
                    </p>
                    <p>
                        In Bangladesh, penalties and interest charges can be imposed for late or incorrect tax filing. The specific penalties depend on the type of tax and the duration of the delay or incorrect filing. Here are some examples of the penalties that may apply:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Late filing penalty: If a taxpayer fails to file a tax return by the due date, a penalty of 2% of the tax due may be imposed for each month or part of a month that the return is late, up to a maximum of 25% of the tax due.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Incorrect filing penalty: If a taxpayer files an incorrect tax return that understates the tax liability, a penalty of 50% of the understated tax may be imposed, in addition to the tax due.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Late payment penalty: If a taxpayer fails to pay the tax due by the due date, a penalty of 2% of the tax due may be imposed for each month or part of a month that the payment is late, up to a maximum of 25% of the tax due.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Interest charges: Interest charges may be imposed on any unpaid tax, penalties, or other charges, at a rate of 2% per month or part of a month.
                            </span>
                        </div>
                        It’s important to note that penalties and interest charges are subject to change and may depend on the specific circumstances of the taxpayer. Therefore, it’s advisable for companies to consult with a tax professional or the local tax authority for guidance on tax compliance and penalties in Bangladesh.
                    </p>

                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={binImg}
                        title="BIN Registration"
                        buttonText="Read More"
                        buttonLink="/bin-registration"
                    />
                    <MarqueeCard
                        imageUrl={corporateImg}
                        title="Corporate TAX"
                        buttonText="Read More"
                        buttonLink="/corporate-tax"
                    />
                    <MarqueeCard
                        imageUrl={etinImg}
                        title="E-TIN Certificate"
                        buttonText="Read More"
                        buttonLink="/etin-certificate"
                    />
                    <MarqueeCard
                        imageUrl={individualImg}
                        title="Individual TAX"
                        buttonText="Read More"
                        buttonLink="/individual-tax"
                    />
                    <MarqueeCard
                        imageUrl={vatlImg}
                        title="Value Added TAX"
                        buttonText="Read More"
                        buttonLink="/value-added-tax"
                    />
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Corporate Tax FAQ</h2>
                <Faq items={faqItems} />
            </div>

            <Footer />
        </>
    )
}

export default CorporateTax