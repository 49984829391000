import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { featuredProperties } from '../../data/featuredProperties';
import { services } from '../../data/services';
import PropertyConsultancyForm from '../../components/PropertyConsultancyForm';


function PropertyConsultancyPage() {
  // Define an array of services provided by your consultancy firm
 

  return (
    <div className="property-consultancy-page">
      <NavBar/>
      <div  className="services-section container-xxl py-5">
        <h2>Our Services</h2>
        <div className="service-list py-5">
          {services.map((service, index) => (
            <div key={index} className="service">
              <img src={service.image} alt={service.title} />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="featured-properties-section container-xxl py-2">
        <h2 style={{textAlign: "center"}}>Featured Properties</h2>
        <div className="property-list" style={{paddingTop:'45px'}}>
          {featuredProperties.map((property, index) => (
            <div key={index} className="property">
              <img src={property.image} alt={property.title} />
              <h3>{property.title}</h3>
              <p>{property.location}</p>
              <p>{property.price}</p>
            </div>
          ))}
        </div>
      </div>
      {/* Add more sections such as client testimonials, contact form, etc. */}
      <div>
        <PropertyConsultancyForm/>
      </div>
      <Footer/>
    </div>
  );
}

export default PropertyConsultancyPage;
