import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import BtclImg from "../../assets/images/JK-Banner.png"
import copyrightImg from "../../assets/images/copyRight-Registration-2.png"
import industrialImg from "../../assets/images/Industrial-Design.png"
import iprImg from "../../assets/images/Intellectual-property-rights-Bangladesh.png"
import patentImg from "../../assets/images/Patent-registration.png"
import trademarkImg from "../../assets/images/Trade-license-Registration.png"
import webdevelopImg from "../../assets/images/web-development-1.jpg"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import trademarkImg2 from "../../assets/images/trademark-Registration-Process.png"

function Trademark() {
   const faqItems = [
      {
         title: 'What is the classification of trademark in Bangladesh?',
         content: <strong>In Bangladesh a trademark application can be filed in 45 classes: Classes 1 to 34 for
            goods and classes 35 to 45 is for service marks. The Trademarks Rules, 1963 were published
            in exercise of the powers conferred by section 84 of the Trademarks Act, 1940 and the Rules
            came into effect from April 02, 2004.</strong>
      },
      {
         title: 'What is the year limit for trademarks?',
         content: <strong>A registered trademark can potentially last indefinitely if it is regularly renewed every
            10 years and if the trademark owner continues to meet all other legal requirements and
            obligations associated with trademark maintenance.</strong>
      },
      {
         title: 'How long is a Certificate of Registration valid in Bangladesh?',
         content: <strong>A Certificate of Registration is valid from the filing date, typically seven years.</strong>
      }
   ];
   return (
      <>
         <Meta title="Trademark"
            description="This page provides information about BSTI permission and related processes."
            image={trademarkImg}
            keywords="keyword1, keyword2, keyword3"
         />
         <NavBar />

         <div className="container-fluid hero-header bg-light py-5 mb-5">
            <div className="container py-5">
               <div className="row g-5 align-items-center">
                  <div className="col-lg-6">
                     <h1 className="display-4 mb-3 animated slideInDown">Trdaemark (TM)</h1>
                     <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item"><Link to="#">Intellectual Property</Link></li>
                           <li className="breadcrumb-item active" aria-current="page">Trdaemark (TM)</li>
                        </ol>
                     </nav>
                  </div>
                  <div className="col-lg-6 animated fadeIn">
                     <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={trademarkImg} alt='Trade-mark-Registration' />
                  </div>
               </div>
            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <p className="color-text fs-5 mb-4">
                  <b>Trademark Registration in Bangladesh</b>
               </p>
               <p>
                  Trademark Registration in Bangladesh is performed through the Department of Patents, Designs
                  and Trademarks (DPDT). Any person claiming to be the proprietor of a trademark already in use
                  or proposed to be used in Bangladesh may apply in writing for registration of a Trademark in
                  the prescribed manner. An applicant has to file application for the registration of a
                  trademark to the Trademark Registry Wing of the DPDT. Service Marks can also be registered in
                  Bangladesh. Applicants can apply for registration of service marks in Bangladesh. The
                  International Nice Classification of Services is applicable for this purpose.
               </p>
            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <div className="row g-5 align-items-center">
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                     <img className="img-fluid" src={trademarkImg2} alt='trademark-Registration-Process' />
                  </div>
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                     <div className="h-100">
                        <p className="color-text fs-5 mb-4">
                           TM Search
                        </p>
                        <p>
                           The applicant may conduct a search for similar trademarks with the DPDT. This is
                           not mandatory for the registration of trademarks.
                        </p>
                        <p className="color-text fs-5 mb-4">
                           Application of Trademark Registration
                        </p>
                        <p>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 An application for the registration of a trademark shall include the following-
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Name of the Mark /Logo/Device prints or representation.
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Name of the Applicant.
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Address and nationality of the Applicant.
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Status of the applicant i.e. Merchandisers / Manufacturers /Service Providers
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Specification of Goods/Services and Class.
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 User date of the mark (whether the mark is in use or proposed to be used in Bangladesh).
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 General/specific power of attorney may be required.
                              </span>
                           </div>
                        </p>
                        <p className="color-text fs-5 mb-4">
                           Acceptance or Rejection of Trademark by the Registrar
                        </p>
                        <p>
                           After filing the application, the Registrar may either accept or reject or order
                           to correct or modify the application. An application for registration of a trade
                           mark may be accepted either absolutely or subject to conditions or limitations.
                           The Registrar, on receipt of the application, issues Official Filing Receipt.
                           The document contains all relevant filing details on the trademark e.g.
                           Application Number, date of application, the trademark etc.). If the Registrar
                           reject any application, s/he should issue a show cause letter to the applicant.
                           The applicant must submit reply to the show cause notice and may seek a hearing
                           in the matter within three (3) months otherwise, the application will be deemed
                           abandoned.
                        </p>
                        <p className="color-text fs-5 mb-4">
                           Journal Publication
                        </p>
                        <p>
                           After an application is accepted by the Registrar, s/he shall provide a Journal
                           Notification to the applicant for the advertisement of the mark. Afterwards the
                           applicant is required to deposit journal fees though pay order/treasury
                           challah/bank draft. Then DPDT will send the mark to Bangladesh Government Press
                           (BG Press) for publication.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="container-xxl py-0">
            <div className="container">
               <p className="color-text fs-5 mb-4">
                  <b>Opposition of the Mark</b>
               </p>
               <p>
                  After BG Press publishes the mark, any person may within two (2) months from the date of the
                  publication give notice of Opposition to the Registrar using prescribed form.<br /><br />

                  The Registrar shall send a copy of the Notice of Opposition to the Applicant and the
                  Applicant shall within Two month of receipt of the Notice of Opposition, file a
                  Counter-Statement of the grounds for which it relies for its application to be registered.
                  Failure to file the Counter-Statement within the prescribed period will result in the
                  application being deemed abandoned. The applicant may seek extension of time for filing
                  Counter-Statement along with Govt. fees.<br /><br />

                  Where a Counter-Statement is filed, the Registrar shall furnish a copy thereof to the
                  Opponent. The parties are required to file evidence by way of Affidavit and the Registrar
                  shall, after hearing the parties, decide on whether the application should be registered or
                  not. The Registrar’s decision shall be subject to appeal to the High Court.
               </p>

               <p className="color-text fs-5 mb-4">
                  <b>­­Registration of the Mark</b>
               </p>
               <p>
                  If there is no opposition, DPDT will inform the applicant to pay certification fees. The
                  applicant is required to submit the money receipt of the certification fees to DPDT.<br /><br />

                  On the registration of a trade mark the Registrar shall issue to the applicant a certificate
                  in the prescribed form of the registration thereof sealed with the seal of the Trade Marks
                  Registry.
               </p>
               <p className="color-text fs-5 mb-4">
                  <b>Term and Renewal</b>
               </p>
               <p>
                  A registered trademark is valid for an initial period of seven (7) years from the date of
                  filing and renewable thereafter for successive periods of Ten (10) years.<br /><br />

                  Renewal fees must be paid before the expiry date but not more than six months prior the
                  expiry. Late renewals available, normally up to four months after expiry date, with payment
                  of late fees. Extensions at the direction of Registrar are liberally granted with payment of
                  additional fees.
               </p>

               <p className="color-text fs-5 mb-4">
                  <b>Assignment of Trademark</b>
               </p>
               <p>
                  The Registered proprietor of a Trade mark has power to assign the registered trademark with or
                  without goodwill of the business in respect of all or some of the goods for which it is registered.
                  Where a person becomes entitled by assignment or transmission to a registered trademark, he shall
                  make application to the Registrar (in the prescribed manner) to register his title and the Registrar
                  shall, on receipt of the application and on proof of title to his satisfaction, register the
                  Assignee as the proprietor of the trademark. LICENSING OF TRADEMARK<br /><br />

                  Licenses must be recorded with the authority to be effective. Application for record must be
                  made to the Registrar.
               </p>

               <p className="color-text fs-5 mb-4">
                  <b>Remedy for Infringement</b>
               </p>
               <p>
                  Where the rights of a proprietor of a registered trademark has infringed, s/he can initiate
                  civil proceeding or criminal proceeding for remedy. Any suit regarding the infringement of
                  trademark, or to establish the right or any ratified right respecting trademark shall be
                  instituted in the Court of District Judge within whose jurisdiction the infringement
                  occurred. A criminal proceeding has to be instituted in the Court of Metropolitan Magistrate
                  or any other 1st class Judicial Magistrate. Remedy in a suit for infringement may be availed
                  in the form of injunction, damages, an accounts of profit, destruction or erasure of
                  falsifying trademark, delivery up the goods marked with false trademark.
               </p>
               <p className="color-text fs-5 mb-4">
                  <b>Procedure of Registration of Trademarks</b>
               </p>
               <p>
                  There is a specific application form for trademark registration. The above mentioned form is
                  available as Form TM-1 in the Sub-link Trademarks of the original link ‘Form and Fees’ on the
                  website of this Department (www.dpdt.gov.bd or http://dpdt.portal.gov.bd). After downloading
                  the Form, four sets of this will have to be filled up on computer appropriately. The
                  completed Form with prescribed fees (Article 13 of this document) and necessary documents
                  will have to be submitted before Information and Service Centre situated on the Ground Floor
                  of this office (91 Motijeel C/A, Dhaka). After receiving the properly filled-up application,
                  the Information and Service Centre will issue the applicant with an acknowledgement receipt
                  containing a serial number. Noticeably, the applications of Chittagong Division will have to
                  be submitted before the branch office of this Department in Chittagong (Room No. 218,
                  Government Works Building-1, Chittagong). The details of the procedure to fill-up and submit
                  the application have been furnished below.<br /><br />
                  <b>1.</b>  The application Form must be filled-up in paper format of ISO A4 size and minimum 80 G.M.S.<br /><br />
                  <b>2.</b> <b>Description of Class:</b> Separate application will have to be submitted for different
                  goods/services and the goods or services fall under which class must be mentioned in the
                  specific area of the application. The classification of different goods/services will be
                  available in the sub-link NICE Classification of the original link resource on the website
                  of this Department.<br /><br />
                  <b>3.</b> <b>Description of Marks or Label:</b> Label of suitable size and durable paper must be affixed
                  on the specific area of application. No carton /packet/photocopy are acceptable as label.
                  The desired Trademark on the label will have to be mentioned specifically with the sign of
                  TM. Without proper evidence, no sign like (R), BSTI, ISO or these sorts of logo of any
                  organization cannot be used on the label. If other Languages except Bengali/English exist
                  on label/logo, translation and transliteration of the same will have to be mentioned in
                  Column No. 6 of the application. NOC from the respective person or guardian will have to be
                  submitted in case of using the photo of any person in the label/logo.<br /><br />
                  <b>4.</b> The description of marks will have to be submitted in Colum No. 3 of application Form.<br /><br />
                  <b>5.</b> <b>Description of Goods/Services: </b>The description of goods/services will have to be
                  mentioned in the specific area of application Form.<br /><br />
                  <b>6.</b> Name of Person along with information (in case of proprietorship) and name of company
                  along with information (in case of limited company) will have to be mentioned in part-A and
                  part-B respectively of Column No.-7 of Application Form. If the applicant is a company, the
                  law under which the company has been incorporated will have to be mentioned. E.g. A Company
                  organized and existing under the laws of Bangladesh.<br /><br />
                  <b>7.</b> Trading Style of company will have to be mentioned in part-C of Colum No.7 of Application
                  Form. E.g. Manufacturer, Importer and Service Provider.<br /><br />
                  <b>8.</b> Full address of the applicant to communicate will have to be mentioned in part-A or B of
                  Column No. 07 of Application Form.<br /><br />
                  <b>9.</b> Using period of the trademark with certain date will have to be mentioned in Column No.10
                  of Application Form. “Proposed to be used” will have to be written in the above mentioned
                  area if the mark in not in use.<br /><br />
                  <b>10.</b> Full name, signature, designation, E-mail, Mobile/Telephone number will have to be
                  mentioned in the specific area of Application Form.<br /><br />
                  <b>11.</b> Evidence in support of Priority will have to be submitted if the same is claimed by the
                  applicant and Column No. 11 of Application Form will have to be filled up properly.<br /><br />
                  <b>12.</b> The application may be submitted by the applicant himself or his lawyer or the enlisted
                  agent appointed by the applicant. If the application is submitted by the lawyer or enlisted
                  agent, original/attested GPA/PA in form (TM-10) with appropriate stamp
                  (Tk. 1000/- for GPA, TK. 500/- for PA) will have to be submitted. In case of foreign
                  applicant, the application must be submitted by the local attorney/agent.<br /><br />
                  <b>13.</b> Application Fee (Tk. 3500/ for every application) will have to be paid by Pay-order/Bank
                  Draft from any scheduled bank, payable to the Registrar of DPDT<br /><br />
                  <b>14.</b> If the applicant wishes to know whether the desired mark has already been
                  registered/applied for any Goods/Services before filing the application, he may know the
                  same within 2 (two) weeks by filing a request with prescribe fee (Tk. 1000/-) on Form TM-4.<br /><br />
                  <b>15.</b> If the application submitted by the applicant has not been objected after examination,
                  the applied mark is accepted for Journal Publication. TMR5 notice will be issued to the
                  applicant to inform this fact and for submitting prescribed fee of Tk. 1000/- for Journal
                  Publication.<br /><br />
                  <b>16.</b> If Journal Fee has not been paid within 1 (one) month after receiving the notice, the
                  trademarks will deem to have been abandoned subject to issue a warning notice.<br /><br />
                  <b>17.</b> If prescribed fee for journal publication is paid duly, the mark is sent to B.G press for printing.<br /><br />
                  <b>18.</b> Two months’ time is stipulated for opposition after Journal Publication from B. G. press.<br /><br />
                  <b>19.</b> The applicant may pay prescribed fee of Tk. 15000/- by Form TM-11 for Registration
                  Certificate after visualizing the Journal if the mark is not opposed within two months.
               </p>
            </div>
         </div>

         <div className='container-xxl center-background1'>
            <h2> Related Articles</h2>
         </div>

         <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
               <MarqueeCard
                  imageUrl={webdevelopImg}
                  title="Foreign Company Formation"
                  buttonText="Read More"
                  buttonLink="/web-development"
               />
               <MarqueeCard
                  imageUrl={trademarkImg}
                  title="Trademark"
                  buttonText="Read More"
                  buttonLink="/trademark"
               />
               <MarqueeCard
                  imageUrl={patentImg}
                  title="Patent"
                  buttonText="Read More"
                  buttonLink="/patent"
               />
               <MarqueeCard
                  imageUrl={iprImg}
                  title="IPR Enforcement"
                  buttonText="Read More"
                  buttonLink="/ipr-enforcement"
               />
               <MarqueeCard
                  imageUrl={industrialImg}
                  title="Industrial Design"
                  buttonText="Read More"
                  buttonLink="/industrial-design"
               />
               <MarqueeCard
                  imageUrl={copyrightImg}
                  title="Copyright"
                  buttonText="Read More"
                  buttonLink="/copyright"
               />
               <MarqueeCard
                  imageUrl={BtclImg}
                  title="BTCL Domain Registration"
                  buttonText="Read More"
                  buttonLink="/btcl-domain-registration"
               />

            </Marquee>
         </div>

         <div className="container py-5 my-0">
            <h2 className="color-text text-center mb-4">Trdaemark FAQ</h2>
            <Faq items={faqItems} />
         </div>

         <Footer />
      </>
   )
}

export default Trademark