import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import RJSCFillingImg from '../../assets/images/RJSC-Filling.png'
import RJSCFillingprocessImg from '../../assets/images/RJSC-Return-Filling-process.png'
import RJSCFillingprocess1Img from '../../assets/images/RJSC-Return-Filing-1783x2048.jpg'
import BSTIPermissionImg from '../../assets/images/BSTI-permission.png'
import compnayFormationImg from "../../assets/images/Company-Formation.jpg"
import onePersionCompanyImg from "../../assets/images/One-man-Company-e1605617207411.png"
import societyRegistrationIMg from "../../assets/images/Society-Registration.png"
import partnershipImg from "../../assets/images/Partnership-.png"
import rjscImg from "../../assets/images/RJSC-Filling.png"
import  loanImg from "../../assets/images/Bank-loan.png"
import imprortImg from "../../assets/images/Import-Registration.png"
import exportImg from "../../assets/images/Export-Inmport-New.jpg"
import tradeImg from "../../assets/images/Trade-license-Registration.png"
import projectImg from "../../assets/images/Project-Profile-1.jpg"
import uddoktaImg from "../../assets/images/বিশাল-মূল্যছাড়.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'

function RJSCReturnFilling() {

     const faqItems = [
          {
            title: 'How do I check my RJSC submission status?',
            content: <strong>You can check your submission status at RJSC web. Enter Submission No.</strong>
          },
          {
            title: 'What is RJSC certificate?',
            content: <strong>RJSC is the sole authority that keeps records of all registered entities (companies, trade
               organizations, societies and partnership firms).</strong>
          },
          {
            title: 'What is Form 12 RJSC?',
            content: <strong>Particulars of Directors, Manager and Managing Agents and of any change therein.
            The Companies Act, 1994 (See Section 115)</strong>
          }
        ];

  return (
    <>
      <Meta title="RJSC Return Filling"
          description="This page provides information about BSTI permission and related processes."
          image={rjscImg}
          keywords="keyword1, keyword2, keyword3"
      />
      
      <NavBar/>

      <div className="container-fluid hero-header bg-light py-5 ">
          <div className="container py-5">
              <div className="row g-5 align-items-center">
                  <div className="col-lg-6">
                      <h1 className="display-4 mb-3 animated slideInDown">RJSC Return Filling</h1>
                      <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item"><Link to="#">Startup</Link></li>
                           <li className="breadcrumb-item active" aria-current="page">RJSC Return Filling</li>
                        </ol>
                     </nav>
                 </div>
                 <div className="col-lg-6 animated fadeIn">
                     <img className="img-fluid animated pulse infinite" style={{animationDuration: '3s'}} src={RJSCFillingImg} alt='RJSC-Filling' />
                 </div>
             </div>
         </div>
      </div>

      <div className="container-xxl py-5">
         <div className="container">
            <p>
               RJSC return submission | RJSC Return Filing for Registered Companies are to file returns in 
               prescribed forms & schedules, and RJSC upon satisfaction accepts and archives such records. 
               There are two (2) types of returns, a) Annual Returns, and  b) Returns for any Change in the 
               Entity’
            </p>
            <p className="color-text fs-5 mb-4">
                <b>RJSC RETURN Submission:</b>
            </p>
            <p>
               Every calendar year must submit the company's annual return to RJSC, an Annual General 
               Meeting must be held. The annual general meeting (AGM) must be conducted within 18 months 
               of the company date of incorporation, after which no more than 15 months can elapse between 
               one annual general meeting (AGM) and to the next.
            </p>

            <p className="color-text fs-5 mb-4">
                <b>WHAT DOCUMENT(S) REQUIRED FOR RJSC RETURN Submission?</b>
            </p>
         </div>
      </div>

      <div className="container-xxl py-5">
         <div className="container">
             <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img className="img-fluid" src={RJSCFillingprocessImg} alt='Company-Registration-Process' />
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="h-100">
                         <p className="color-text fs-5 mb-4">RJSC RETURN FILING FOR PRIVATE COMPANY</p>
                         <p>

                         <div className="d-flex align-items-center mb-2">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                  <b>Schedule X:</b>  Annual summary of share capital and list of shareholders and 
                                  Directors: to be filed within 21 days of an annual general meeting (AGM). 
                                  [Section 36].
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                  <b>Balance Sheet:</b> to be filed within 30 days of an annual general meeting (AGM).
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                 <b>Profit & Loss Account:</b> to be filed within 30 days of an annual general meeting (AGM).
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                  <b>Form 23B:</b> Notice by Auditor: to be filed within 30 days of receiving 
                                  appointment information from the company [Section 210 (2)].
                             </span>
                        </div>
                        </p>
                        <p className="color-text fs-5 mb-4">RJSC RETURN FILING FOR PUBLIC COMPANY</p>
                        <p>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                  <b>Schedule X:</b> Annual summary of share capital and list of shareholders,
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                 <b>Directors:</b> To be filed within 21 days of an annual general meeting (AGM). 
                                 [Section 36].
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                 <b>Balance Sheet:</b> to be filed within 30 days of an annual general meeting (AGM).
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                 <b>Profit & Loss Account:</b> to be filed within 30 days of an annual general meeting (AGM).
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                 <b>Form 23B: </b>Notice by Auditor: to be filed within 30 days of receiving 
                                    appointment information from the company [Section 210 (2)].
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                 <b> Filled in Form IX: </b>Consent of Director to act: to be filed within 30 days of 
                                     appointment [Section 92]
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                 <b>Filled in Form XII: </b>Particulars of the Directors, Manager and 
                                    Managing Agents and of any change therein: to be filed within 14 days 
                                    from the date of appointment or change [Section-115]
                             </span>
                        </div>
                        </p>
                        <p className="color-text fs-5 mb-4">RJSC RETURN FILING FOR FOREIGN COMPANY</p>
                        <p>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                  Balance sheet
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                 Profit & loss account or income or expenditure account (if not trading for profit).
                             </span>
                        </div>   
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div className="container-xxl py-5">
         <div className="container">
             <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="h-100">
                         <p className="color-text fs-5 mb-4">RJSC RETURN FILING FOR TRADE ORGANISATION</p>
                         <p>

                         <div className="d-flex align-items-center mb-2">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                  <b>Filled in Form IX:</b>Consent of Director to act: to be filed within 
                                  30 days of appointment [Section 92]
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                  <b>Filled in Form XII:</b>Particulars of the Directors, Manager and Managing Agents and 
                                     of any change therein: to be filed within 14 days from the date of appointment or change 
                                    [Section 115]
                             </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                 <b>Balance Sheet: </b> to be filed within 30 days of an annual general meeting (AGM) Income and Expenditure Account
                             </span>
                        </div>
                        </p>
                        <p className="color-text fs-5 mb-4">RJSC RETURN FILING FOR SOCIETY</p>
                        <p>
                        <div className="d-flex align-items-center mb-4">
                             <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                             <span>
                                  The annual list of Managing Body: to be filed within 14 days of an annual general 
                                  meeting (AGM) or in January if the rules do not provide for an annual general 
                                  meeting (AGM).
                             </span>
                        </div>
                        
                        </p>
                        <p className="color-text fs-5 mb-4">
                            IN RJSC RETURN FILING WHAT DOCUMENT(S) SHALL I SUBMIT IN RETURNS FOR CHANGE?
                        </p>
                    </div>
                </div>

                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img className="img-fluid" src={RJSCFillingprocess1Img} alt='Company-Registration-Process' />
                </div>
            </div>
        </div>
      </div>

      <div className="container-xxl py-0">
         <div className="container">
         <p className="color-text fs-5 mb-4">
               <b>IN CASE OF PRIVATE COMPANY FOR RJSC RETURN FILING</b>            
         </p>
               <p>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form III:</b>Notice of consolidation, division, subdivision 
                            or conversion into the stock of shares: to be filed within 15 days of 
                            consolidation and division, etc. [Section 53 & 54].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form IV:</b>Notice of increase share capital: to be filed 
                              within 15 days of increase of share capital/member [Section 56].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form VIII:</b>Special Resolution/ Extraordinary Resolution including a 
                              name change, conversion into public company, alteration of the memorandum of 
                              association, alteration of articles of association etc, to be filed within 15 days 
                             of the meeting [Section 88 (1)].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form VIII:</b>Special Resolution/ Extraordinary Resolution 
                              including a name change, conversion into public company, alteration of 
                              the memorandum of association, alteration of articles of association 
                              etc, to be filed within 15 days of the meeting [Section 88 (1)].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form IX:</b> Consent of Director to act: to be filed within 30
                              days of appointment [Section 92].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form XII: </b> Particulars of the Directors, Manager and 
                         Managing Agents and of any change therein: to be filed within 14 days 
                         from the date of appointment or change [Section 115].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form XV:</b> Return of allotment: to be filed within 60 days 
                              of allotment [Section 151].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form XVIII:</b> Particulars of mortgages or charges: to be 
                              filed within 21 days of creation of the mortgage or charge [Section 159 
                              & 391].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form XIX:</b> Particulars of Modification of Mortgage or 
                              Charge: to be filed within 21 days of the date modification 
                              [Section 167(3) & 319].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form XXVIII:</b> Memorandum of satisfaction of mortgage charge:
                              to be filed within 21 days of the date satisfaction [Section 12 & 391].
                    </span>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form 117:</b> Instrument of Transfer of Shares. Digital copy 
                              of original Memorandum & Articles of Association
                    </span>
               </div>   
               </p>
               <p>
               <div className="container">
                    <p className="color-text fs-5 mb-4">
                    <b>IN CASE OF PUBLIC COMPANY</b>            
                    </p>
               </div>

               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form III:</b> Notice of consolidation, division, subdivision or 
                           conversion into stock of shares: to be filed within 15 days of consolidation and 
                           division etc. [Section 53 & 54].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form IV: </b>Notice of increase share capital: to be filed within 15 
                            days of increase of share capital or member [Section 56].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form VI:</b> Notice of situation of Registered Office and of any change 
                           therein: to be filed within 28 days of establishment or change [Section 77].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form VII:</b> Statutory report: to be filed after sending copy of the 
                           statutory report to the members not less than 21 days before meeting [Section 83].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form VIII:</b> Special Resolution/ Extraordinary Resolution including 
                            name change, conversion onto private company, alteration of the memorandum of 
                            association, alteration of articles of association etc.: to be filed within 15 
                            days of the meeting [Section 88 (1)].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form IX:</b> Consent of Director to act: to be filed within 30 days of 
                            appointment [Section 92].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form XII:</b> Particulars of the Directors, Manager and Managing Agents
                            and of any change therein: to be filed within 14 days from the date of 
                            appointment or change [Section 115].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form XV:</b> Return of allotment: to be filed within 60 days of allotment 
                            [Section 151].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form XVIII:</b> Particulars of mortgages or charges: to be filed within 
                           21 days of creation of the mortgage or charge [Section 159 & 391].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form XIX: </b>Particulars of Modification of Mortgage or Charge: to be 
                            filed within 21 days of modification [Section 167(3) & 319].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form XXVIII: </b>Memorandum of satisfaction of mortgage charge: to be 
                           filed within 21 days of satisfaction [Section 12 & 391].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form 117:</b>Instrument of Transfer of Share. Prospectus for the issue 
                            of shares: to be filed at least 3 days before the 1st allotment of share or 
                            debenture [Section 141].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Prospectus:</b> The following conversion of Private Company into Public company 
                           [Section 231]. Digital copy of original Memorandum & Articles of Association
                    </span>
               </div> 

               <div className="container">
                    <p className="color-text fs-5 mb-4">
                    <b>IN CASE OF FOREIGN COMPANY</b>            
                    </p>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in form XL:</b> Notice of alteration in the charter, etc. [Section 277].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in form XLI:</b> Notice of alteration in the address of the registered or 
                           principal office of company [Section 277].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in form XLII:</b> Notice of situation of the principal place of business 
                            in Bangladesh or of any change therein [Section 379 (I)]
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b> Filled in form XXXVIII: </b>List of Directors and Managers [Section 379]
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in form XXXIX: </b>Return of persons authorized to accept service 
                          [Section 379].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form XVIII: </b>Particulars of mortgages or charges: to be filed within 
                           21 days of the creation of the mortgage or charge [Section 159 & 391]
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form XIX:</b> Particulars of Modification of Mortgage or Charge: to be 
                           filed within 21 days of modification [Section 167(3) & 319]
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form XXVIII:</b> Memorandum of satisfaction of mortgage charge: to be 
                           filed within 21 days of satisfaction [Section 12 & 391]
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                       <b>Digital MoA, AoA: </b> Digital copy of original Memorandum & Articles of Association
                    </span>
               </div> 

               <div className="container">
                    <p className="color-text fs-5 mb-4">
                    <b>IN CASE OF TRADE ORGANIZATION</b>            
                    </p>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                       <b> Filled in Form VI: </b>Notice of situation of registered office and of any change 
                           therein: to be filed within 28 days of establishment or change [Section 77].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form IX:</b> Consent of Director to act: to be filed within 30 days of appointment 
                           [Section 92].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form XII:</b> Particulars of the Directors, Manager, and Managing 
                           Agents and of any change therein: to be filed within 14 days from the date of 
                           appointment or change [Section 115].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form XVIII:</b> Particulars of mortgages or charges: to be filed within
                           21 days of the creation of the mortgage or charge [Section 159 & 391].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form XIX:</b> Particulars of Modification of Mortgage or Charge: to be 
                           filed within 21 days of modification [Section 167(3) & 319].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form XXVIII:</b> Memorandum of satisfaction of mortgage charge: to be 
                           filed within 21 days of satisfaction [Section 12 & 391]
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Digital MoA, AoA: </b>Digital copy of original Memorandum & Articles of Association
                    </span>
               </div> 

               <div className="container">
                    <p className="color-text fs-5 mb-4">
                    <b>IN CASE OF SOCIETY</b>            
                    </p>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        Filing of Change of Address
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                       Filing of Alteration of Name
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                       Digital copy of original Memorandum of Association
                    </span>
               </div> 

               <div className="container">
                    <p className="color-text fs-5 mb-4">
                    <b>IN CASE OF PARTNERSHIP FIRM</b>            
                    </p>
               </div>
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form II:</b> Notice of alteration of name or principal place of business of the firm.
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                         <b>Filled in Form V:</b> Intimation for the recording of changes in the constitution
                           of a firm [Section 63& Rule 4 (6)].
                    </span>
               </div> 
               <div className="d-flex align-items-center mb-4">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                        <b>Filled in Form VI:</b> Notice of intimation for dissolution of the partnership 
                          firm [Section 63 (1) & Rule (6)]
                    </span>
               </div> 
               </p>
               <p>
                  Bangladesh is becoming one of the most promising outbound markets in the world. Due to the 
                  swift growth of the economy in this country, the company formation procedure is broadly 
                  asking questions to the new entrepreneurs. All the questions are answered above how you 
                  should register your company.
               </p>
         </div>
     </div>

     <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>
            
            <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
                <MarqueeCard
                    imageUrl={uddoktaImg}
                    title="Startup Packages"
                    buttonText="Read More"
                    buttonLink="/startup-packages"
                />
                <MarqueeCard
                    imageUrl={projectImg}
                    title="Project Profile"
                    buttonText="Read More"
                    buttonLink="/project-profile"
                />
                <MarqueeCard
                    imageUrl={tradeImg}
                    title="Trade License"
                    buttonText="Read More"
                    buttonLink="/trade-license"
                />
                <MarqueeCard
                    imageUrl={exportImg}
                    title="Export License"
                    buttonText="Read More"
                    buttonLink="/export-license"
                />
                <MarqueeCard
                    imageUrl={imprortImg}
                    title="Import License"
                    buttonText="Read More"
                    buttonLink="/import-license"
                />
                <MarqueeCard
                    imageUrl={loanImg}
                    title="Loan Consultancy"
                    buttonText="Read More"
                    buttonLink="/loan-consultancy"
                />
                <MarqueeCard
                    imageUrl={rjscImg}
                    title="RJSC Return Filling"
                    buttonText="Read More"
                    buttonLink="/rjsc-return-filing"
                />
                 <MarqueeCard
                    imageUrl={partnershipImg}
                    title="Partnership Registration"
                    buttonText="Read More"
                    buttonLink="/partnership-registration"
                />
                 <MarqueeCard
                    imageUrl={societyRegistrationIMg}
                    title="Society Registration"
                    buttonText="Read More"
                    buttonLink="/society-registration"
                />
                 <MarqueeCard
                    imageUrl={onePersionCompanyImg}
                    title="One Permission Company"
                    buttonText="Read More"
                    buttonLink="/one-person-company"
                />
                 <MarqueeCard
                    imageUrl={compnayFormationImg}
                    title="Company Formation"
                    buttonText="Read More"
                    buttonLink="/company-formation"
                />
                 <MarqueeCard
                    imageUrl={BSTIPermissionImg}
                    title="BSTI Permission"
                    buttonText="Read More"
                    buttonLink="/btcl-domain-registration"
                />
               
            </Marquee>
            </div>
     
     <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">RJSC Return Filling FAQ</h2>
        <Faq items={faqItems} />
      </div>

      <Footer/>
    
    </>
  )
}

export default RJSCReturnFilling