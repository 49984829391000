import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import { FaSearch } from 'react-icons/fa';

function SearchForm() {
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();

    const handleSearchInputChange = (event, { newValue }) => {
        setSearchQuery(newValue);

        // Filter suggestions based on the input query
        const filteredSuggestions = allPages.filter((page) =>
            page.value.toLowerCase().includes(newValue.toLowerCase())
        );

        setSuggestions(filteredSuggestions);
    };

    const handleSearchKeyPress = (event) => {
        if (event.key === 'Enter' && searchQuery.trim() !== '') {
            // Trim the search query and replace spaces with dashes for a clean URL
            const formattedQuery = searchQuery.trim().replace(/\s+/g, '-');

            // Construct the URL dynamically and navigate to it
            navigate(`/${formattedQuery}`);
        }
    };

    const handleSuggestionSelect = (event, { suggestion }) => {
        // Navigate to the selected suggestion
        const formattedQuery = suggestion.value.trim().replace(/\s+/g, '-');
        navigate(`/${formattedQuery}`);
    };

    const renderSuggestion = (suggestion) => (
        <div className="suggestions-container">
            <div className="suggestion-item"
                style={{
                    color: 'white',
                    fontSize: '13px',
                    fontWeight: '500',
                    listStyleType: 'none',
                    width: '250px',
                    height: '35px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    backgroundColor: '#4CAF50',  // Background color
                    borderRadius: '10px',  // Rounded corners
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',  // Shadow
                    transition: 'background-color 0.3s ease, transform 0.3s ease',  // Add transition effect
                    margin: '3px',  // Margin for spacing
                    padding: '0 20px',  // Padding for text spacing
                }}

                // Add hover effect
                onMouseOver={(event) => {
                    // Change background color on hover
                    event.target.style.backgroundColor = '#04aa6d';
                }}
                onMouseOut={(event) => {
                    // Revert to original background color on hover out
                    event.target.style.backgroundColor = '#4CAF50';
                }}>
                {suggestion.value}
            </div>
        </div>
    );

    // Manually set keys for the allPages array
    const allPages = [
        { key: 'export-license', value: 'Export License' },
        { key: 'etin-certificate', value: 'ETIN Certificate' },
        { key: 'rjsc-return-filing', value: 'RJSC Return Filing' },
        { key: 'company-formation', value: 'Company Formation' },
        { key: 'one-person-company', value: 'One Person Company' },
        { key: 'society-registration', value: 'Society Registration' },
        { key: 'partnership-registration', value: 'Partnership Registration' },
        { key: 'loan-consultancy', value: 'Loan Consultancy' },
        { key: 'import-license', value: 'Import License' },
        { key: 'bsti-permission', value: 'BSTI Permission' },
        { key: 'trade-license', value: 'Trade License' },
        { key: 'project-profile', value: 'Project Profile' },
        { key: 'startup-packages', value: 'Startup-Packages' },
        { key: 'individual-tax', value: 'Individual Tax' },
        { key: 'corporate-tax', value: 'Corporate Tax' },
        { key: 'value-added-tax', value: 'Value Added Tax' },
        { key: 'bin-registration', value: 'Bin Registration' },
        { key: 'account-service', value: 'Account Service' },
        { key: 'bookkeeping-services', value: 'Bookkeeping Services' },
        { key: 'small-business', value: 'Small Business' },
        { key: 'acounting-software', value: 'Acounting Software' },
        { key: 'erp-software', value: 'ERP Software' },
        { key: 'vat-software', value: 'VAT Software' },
        { key: 'hr-management-software', value: 'HR Management Software' },
        { key: 'company-portfolio', value: 'Company Portfolio' },
        { key: 'trademark', value: 'Trademark' },
        { key: 'copyright', value: 'Copyright' },
        { key: 'patent', value: 'Patent' },
        { key: 'industrial-design', value: 'Industrial Design' },
        { key: 'ipr-enforcement', value: 'IPR Enforcement' },
        { key: 'btcl-domain-registration', value: 'BTCL Domain Registration' },
        { key: 'web-development', value: 'Web Development' },
        { key: 'civil-litigation', value: 'Civil Litigation' },
        { key: 'criminal-litigation', value: 'Criminal Litigation' },
        { key: 'foreign-company-formation', value: 'Foreign Company Formation' },
        { key: 'foreign-direct-investment', value: 'Foreign Direct Investment' },
        { key: 'bida-permission', value: 'BIDA Permission' },
        { key: 'work-permit-visa', value: 'Work Permit VISA' },
        { key: 'foreign-delegates', value: 'Foreign Delegates' },
        { key: 'foreign-company-director-service', value: 'Foreign Company Director Service' },
        { key: 'foreign-company-registered-address', value: 'Foreign Company Registered Address' },
        { key: 'fire-license', value: 'Fire License' },
        { key: 'factory-license', value: 'Factory License' },
        { key: 'customs-bond-license', value: 'Customs Bond License' },
        { key: 'environment-clearance-certificate', value: 'Environment Clearance Certificate' },
    ];

    const inputProps = {
        placeholder: 'Search a service..',
        value: searchQuery,
        onChange: handleSearchInputChange,
        onKeyPress: handleSearchKeyPress,
        className: 'form-control me-2 my-3',
        style: { paddingLeft: '30px', borderRadius: '10px', color: '#4CAF50', border: '2px solid #4CAF50' }

    };

    return (
        <div style={{ marginTop: '4px', fontSize: '14px', padding: '2px', width: '200px', height: '40px', position: 'relative' }}>
            <FaSearch style={{ position: 'absolute', left: '10px', top: '90%', transform: 'translateY(-50%)', color: '#4CAF50' }} />
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={() => { }}
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={(suggestion) => suggestion.value}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={handleSuggestionSelect}
                inputProps={inputProps}
            />
        </div>
    );
}

export default SearchForm;
