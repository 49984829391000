import { Helmet } from "react-helmet";
import React from 'react';

function Meta(props) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props?.title}</title>
      <meta name="description" content={props?.description} />
      {/* Open Graph meta tags */}
      <meta property="og:title" content={props?.title} />
      <meta property="og:description" content={props?.description} />
      {props?.image && <meta property="og:image" content={props?.image} />}
      <meta property="og:image:alt" content={props?.title} />
      <meta name="keywords" content={props?.keywords} />
    </Helmet>
  );
}

export default Meta;
