import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';

import civilImg from "../../assets/images/Civil-Litigation.png"
import criminalImg from "../../assets/images/Criminal-Litigation-1.png"
import fireImg from "../../assets/images/Fire-License-In-Bangladesh.png"
import factoryImg from "../../assets/images/Fire-License-In-Bangladesh-2.png"
import bondImg from "../../assets/images/Fire-License-In-Bangladesh-4.png"
import environmentImg from "../../assets/images/Environmental.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import factoryImg2 from "../../assets/images/Small-businesses-or-entrepreneurs-for-factory-license-DIFE.png"

function FactoryLicense() {
    const faqItems = [
        {
            title: 'How can I get factory license in Bangladesh?',
            content: <strong>How to Obtain Factory Establishment Licenses in Bangladesh
                1. Visit the Labour Inspection Management Application Website.
                2. Navigate to &quot;Forms&quot; in the top menu and select “Form 77” (License Application).
                3. Create a User ID and Password.
                4. Auto Login/Login to the LIMA Portal using the new User ID and Password.</strong>
        },
        {
            title: 'How can I set up a factory in Bangladesh?',
            content: <strong>In order to set up a factory in export processing zones (EPZs), the business-owner
                needs to prepare TIN (tax identification number) certificate, VAT (Value Added Tax) certificate,
                trade license, Bangladesh Textile Mills Association (BTMA) certificate, permit from Export
                Promotion Bureau (EPB), fire license, boiler ...</strong>
        },
        {
            title: 'How many types of trade are there in business?',
            content: <strong>two types
                Generally, there are two types of trade—domestic and international. Domestic trades occur between parties in the same countries.
                International trade occurs between two or more countries.</strong>
        }
    ];
    return (
        <>
            <Meta title="Factory License"
                description="This page provides information about BSTI permission and related processes."
                image={factoryImg}
                keywords="keyword1, keyword2, keyword3"
            />

            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Factory License</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Regulatory License</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Factory License</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={factoryImg} alt='Fire-License-In-Bangladesh-2' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>What is the process for obtaining a Factory License (DIFE)<br />
                            The process for obtaining a factory license (DIFE) in Bangladesh typically
                            involves the following steps:
                        </b>
                    </p>

                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Submission of application: </b>The factory owner must submit an
                                application for a factory license (DIFE) to the Department of Inspection for
                                Factories and Establishments (DIFE), which is part of the Ministry of Labor
                                and Employment.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Inspection of premises: </b>Once the application is submitted, DIFE
                                inspectors will visit the premises to verify the information provided in the
                                application and to check compliance with safety and environmental standards.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Payment of fees: </b>After the inspection is completed, the factory owner
                                must pay the necessary fees for the issuance of the factory license (DIFE).
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Issuance of license: </b>If the inspection is successful and all requirements are met, the DIFE will issue the factory license (DIFE) to the factory owner.
                            </span>
                        </div>
                        The specific requirements for obtaining a factory license (DIFE) in Bangladesh may
                        vary depending on the type of factory and the products being manufactured. However,
                        in general, the factory owner will need to provide information such as the type of
                        products being manufactured, the number of workers employed, and the safety and
                        environmental measures in place to ensure that the factory is safe for workers and
                        the surrounding community.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            Requirements for obtaining a factory license (DIFE)
                        </b>
                    </p>
                    <p>
                        The requirements for obtaining a factory license (DIFE) in Bangladesh may vary
                        depending on the type of factory and the products being manufactured. However, in
                        general, the following are the requirements for obtaining a factory license (DIFE) in
                        Bangladesh:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Registration: </b>The factory must be registered with the Registrar of Joint Stock Companies and Firms (RJSC).
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Building and location: </b>Building and location must comply with local zoning and building regulations, and must be suitable for the type of manufacturing activities being conducted.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Safety measures: </b>It must have adequate safety measures in place to protect workers and prevent accidents, including fire safety equipment, emergency exits, and first aid supplies.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Environmental standards: </b>Environmental standards set by the Department of Environment (DOE), including waste management and pollution control measures.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Employment and labor standards: </b>The factory must comply with employment and labor standards, including minimum wage requirements, working hours, and labor laws.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Submission of documents: </b>Owner must submit various documents, including the factory registration certificate, layout plan of the factory, ownership documents, and proof of compliance with safety and environmental standards.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Payment of fees: </b>The owner must pay the necessary fees for the issuance of the factory license (DIFE).
                            </span>
                        </div>
                        It is important to note that the specific requirements may vary depending on the
                        type of factory and the products being manufactured. Therefore, it is recommended to
                        consult with the Department of Inspection for Factories and Establishments (DIFE) or
                        seek professional guidance for further information.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            Consequences of operating a factory without a valid Factory License (DIFE)
                        </b>
                    </p>
                    <p>
                        Operating a factory without a valid factory license (DIFE) in Bangladesh is illegal
                        and can result in a range of consequences, including:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Closure of the factory: </b>The Department of Inspection for Factories
                                and Establishments (DIFE) can order the closure of the factory if it is
                                found to be operating without a valid license.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Fines: </b>The factory owner may be fined for operating without a valid
                                factory license (DIFE).
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Legal action: </b>The government may take legal action against the
                                factory owner for operating without a valid factory license (DIFE).
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Loss of credibility: </b>Operating without a valid factory license (DIFE)
                                can result in a loss of credibility and reputation for the factory owner.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Negative impact on workers: </b>Without a valid factory license (DIFE)
                                can put the safety and well-being of workers at risk, which can result in
                                injuries or even fatalities.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Negative impact on the environment: </b>If operating without a valid
                                factory license (DIFE) can also result in negative environmental impact due
                                to the lack of compliance with environmental standards.
                            </span>
                        </div>
                        Therefore, it is highly recommended for factory owners to obtain a valid factory
                        license (DIFE) before operating a factory in Bangladesh to avoid legal and financial
                        consequences, and to ensure the safety and well-being of workers and the surrounding
                        community.
                    </p>
                    <p>
                        <img className="mx-auto responsive-img" src={factoryImg2}
                            alt='Small-businesses-or-entrepreneurs-for-factory-license-DIFE' style={{ width: 1400, height: 300 }}
                        />
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>
                            Small businesses or entrepreneurs for factory license (DIFE)
                        </b>
                    </p>
                    <p>
                        Yes, there are various types of assistance available for small businesses or
                        entrepreneurs who need to obtain a factory license (DIFE) in Bangladesh.
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Government support: </b>The Government of Bangladesh has several programs
                                to support small businesses and entrepreneurs. The Department of Inspection
                                for Factories and Establishments (DIFE) can provide information and guidance
                                to small business owners and entrepreneurs on the process for obtaining a
                                factory license (DIFE) and the requirements to comply with safety and
                                environmental standards.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Business associations: </b>Various business associations in Bangladesh,
                                such as the Bangladesh Garment Manufacturers and Exporters Association
                                (BGMEA), can provide support and guidance to small businesses and
                                entrepreneurs who need to obtain a factory license (DIFE).
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Consulting firms: </b>There are several consulting firms in Bangladesh
                                that provide professional services to small businesses and entrepreneurs,
                                including assistance with obtaining a factory license (DIFE). Like,“JK
                                Associates” is a top-ranked Corporate Consultancy and Law firm in Bangladesh
                                with an international presence providing specialized services in
                                Intellectual Property Rights, Corporate Affairs, Foreign Trade and
                                Investment, Taxation, and Litigation. ‘JK Associates’ is always there to
                                give you legal help or advice. So, contact us
                            </span>
                        </div>
                        ☎ 02-8090785 (9.30am – 6.30pm), +8801511-404971, +8801973-404971, +8801601-404971<br />
                        📧 info@jkassociates.com.bd<br />
                        📍 Head Office: 752/2, City Bank Building (6th floor) Begum Rokeya Ave, Mirpur, Dhaka 1216<br />
                        📍 Branch: 74, RH Home Center, B/1 Green Road Dhaka- 1215<br />
                        Get Directions or Send a Message
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Non-governmental organizations (NGOs): </b>Several NGOs in Bangladesh
                                work to support small businesses and entrepreneurs. They may provide
                                assistance with obtaining a factory license (DIFE) as part of their broader
                                support programs.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Online resources: </b>There are various online resources available that
                                provide information and guidance on the process for obtaining a factory
                                license (DIFE) in Bangladesh. These resources include government websites,
                                business associations, and consulting firms.
                            </span>
                        </div>
                        It is recommended for small businesses and entrepreneurs to seek assistance and
                        guidance from these resources to ensure compliance with safety and environmental
                        standards and to avoid legal and financial consequences.
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={environmentImg}
                        title="Environment Clearance Certificate"
                        buttonText="Read More"
                        buttonLink="/environment-clearance-certificate"
                    />
                    <MarqueeCard
                        imageUrl={bondImg}
                        title="Custom Bond License"
                        buttonText="Read More"
                        buttonLink="/customs-bond-license"
                    />
                    <MarqueeCard
                        imageUrl={factoryImg}
                        title="Factory License"
                        buttonText="Read More"
                        buttonLink="/factory-license"
                    />
                    <MarqueeCard
                        imageUrl={fireImg}
                        title="Fire License"
                        buttonText="Read More"
                        buttonLink="/fire-license"
                    />
                    <MarqueeCard
                        imageUrl={criminalImg}
                        title="Criminal Litigation"
                        buttonText="Read More"
                        buttonLink="/criminal-litigation"
                    />
                    <MarqueeCard
                        imageUrl={civilImg}
                        title="Civil Litigation"
                        buttonText="Read More"
                        buttonLink="/civil-litigation"
                    />


                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Factory License FAQ</h2>
                <Faq items={faqItems} />
            </div>

            <Footer />
        </>
    )
}

export default FactoryLicense