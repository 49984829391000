import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import TradelicenseRegistration from '../../assets/images/Trade-license-Registration.png'
import tradelicenseregistrationprocess  from '../../assets/images/trade-license-registration-process.png'
import RequiredDocuments from '../../assets/images/Required-Documents-RENEWAL-OF-Trade-License.png'
import BSTIPermissionImg from '../../assets/images/BSTI-permission.png'
import compnayFormationImg from "../../assets/images/Company-Formation.jpg"
import onePersionCompanyImg from "../../assets/images/One-man-Company-e1605617207411.png"
import societyRegistrationIMg from "../../assets/images/Society-Registration.png"
import partnershipImg from "../../assets/images/Partnership-.png"
import rjscImg from "../../assets/images/RJSC-Filling.png"
import  loanImg from "../../assets/images/Bank-loan.png"
import imprortImg from "../../assets/images/Import-Registration.png"
import exportImg from "../../assets/images/Export-Inmport-New.jpg"
import tradeImg from "../../assets/images/Trade-license-Registration.png"
import projectImg from "../../assets/images/Project-Profile-1.jpg"
import uddoktaImg from "../../assets/images/বিশাল-মূল্যছাড়.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'

function TradeLicense() {
    const faqItems = [
        {
          title: 'Accordion Item #1',
          content: <strong>One Person Company</strong>
        },
        {
          title: 'Accordion Item #2',
          content: <strong>This is the second item's accordion body.</strong>
        },
        {
          title: 'Accordion Item #3',
          content: <strong>This is the third item's accordion body.</strong>
        }
      ];

  return (
    <>
      <Meta title="Trade License"
            description="This page provides information about BSTI permission and related processes."
            image={tradeImg}
            keywords="keyword1, keyword2, keyword3"
      />
      
      <NavBar/>

      <div className="container-fluid hero-header bg-light py-5">
          <div className="container py-5">
              <div className="row g-5 align-items-center">
                  <div className="col-lg-6">
                      <h1 className="display-4 mb-3 animated slideInDown">Trade License</h1>
                      <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item"><Link to="#">Startup</Link></li>
                           <li className="breadcrumb-item active" aria-current="page">Trade License</li>
                        </ol>
                     </nav>
                 </div>
                 <div className="col-lg-6 animated fadeIn">
                     <img className="img-fluid animated pulse infinite" style={{animationDuration: '3s'}} src={TradelicenseRegistration} alt='Trade-license-Registration' />
                 </div>
             </div>
         </div>
      </div>

      <div className="container-xxl py-5">
         <div className="container">
            <p>
               Trade License is being introduced in Bangladesh under The Municipal Corporations [Taxation] 
               Rules, 2009. It is issued when any entrepreneur applied through the license form. The process 
               is managed by the City Corporation or city council where the business operates. A license is 
               issued exclusively in the name of the company and such a license is not transferable. A 
               renewed license is provided by the concerned staff of the zonal taxation office. A fee for 
               trade license has to be deposited at any Bank as indicated in the form..
            </p>
            <p className="color-text fs-5 mb-4">
                <b>Required Documents for Trade License:</b>
            </p>
            <p>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    <strong>In the case of general Trade License - </strong>Attested Copy of Rent Receipt or 
                    Rental Agreement and also the copy of the Holding Tax payment receipt.
                </span>
            </div>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    <strong>In the case of  industries - </strong>Everything mentioned in serial no. 1 plus
                </span>
            </div>
            <span className='px-5'>1. No objection declaration on the surrounding</span><br/>
            <span className='px-5'>2. Location Map</span><br/>
            <span className='px-5'>3. Copy of fire certificate</span><br></br>
            <span className='px-5'>4. Declaration on the non-judicial stamp of Tk. 150/- to abide by the rules and regulation of DCC</span><br/>
            <span className='px-5'>5. One copy of passport size photograph</span><br/>
            
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    <strong>In the case of Clinic/Private Hospital: </strong>permission from the 
                    Director-General of Health.<br/><strong>In the case of Limited Company :</strong> 
                </span>
            </div>
            <span className='px-5'>1. Memorandum of Article</span><br/>
            <span className='px-5'>2. Certificate of In-Corporation</span><br/>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    <strong>In the case of Printing Press and Residential Hotel- </strong>Permission from 
                    Deputy Commissioner (DC).
                </span>
            </div>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    <strong>In the case of a Recruitment Agency - </strong>License from Manpower export 
                    Bureau (BMET).
                </span>
            </div>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    <strong>In case of Arms and Ammunition - </strong>Copy of Arms License.
                </span>
            </div>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    <strong>In the case of Drug and Narcotics - </strong>Copy of Drug/Narcotics License.
                </span>
            </div>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                   <strong>In the case of Travelling Agency - </strong>Approval from civil aviation authority.
                </span>
            </div>
            </p>
            <p className="color-text fs-5 mb-4">
                <b>Renewal of Trade License</b>
            </p>
            <p>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    Contact concerned zonal Taxation office along-with the previous License
                </span>
            </div>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    The concerned staff of the zonal taxation office will provide a new Trade License
                </span>
            </div>
            <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                   <strong>Fees: </strong>Fees for trade license renewal is same as applicable for a new 
                   License, which has to be deposited at any Bank indicated on the Trade License
                </span>
            </div>
            </p>
            <p>
               Trade License is mandatory for every form of business entity in Bangladesh. It is issued by 
               the local government of the respective areas. Every business entity must obtain a Trade 
               License from each local government where it operates. If a business entity has more than one 
               place of business, it must obtain a Trade License from each local government.  It is issued 
               for one year and has to be renewed annually. Trade License attracts some government fees, 
               which usually depends on the types of business.
            </p>
            <p>
               Below, we have described the process of obtaining a license for businesses that operate 
               within the Dhaka City Corporation area. Other local governments have similar rules. You are 
               advised to contact the respective local government for more information.
            </p>
            <h2 className="color-text fs-5 mb-4 text-center">
                <b>TRADE LICENSE FOR A COMMERCIAL FIRM</b>
            </h2>
         </div>
      </div>

      <div className="container-xxl py-5">
         <div className="container">
             <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img className="img-fluid" src={tradelicenseregistrationprocess} alt='trade-license-registration-process' />
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="h-100">
                         <p className="color-text fs-5 mb-4">PROCESS STEPS </p>
                         <p>
                             <span>
                                <b>STEP 1: </b> PROCURE THE PROPER FORM FROM THE PROPER OFFICE.<br/>
                                         Dhaka City Corporation (DCC) has two forms for a trade license depending 
                                         on the type of business. A commercial firm must use the “K” Form. Even 
                                         though the ten zonal offices use the same K Form, a business must buy the 
                                         form from its respective zonal office. A seal and the initials of the 
                                         officer selling the form is what distinguishes it from that of other 
                                         zones. The form costs Tk. 10.
                             </span>
                             <br/>
                             
                             <span>
                                 <b>STEP 2: </b>GET CERTIFICATION FROM THE LOCAL WARD COMMISSIONER.<br/>
                                     After the form is completed it has to be submitted to the local ward commissioner for 
                                     validation.
                             </span>
                             </p>
                            
                            <p>
                            <span>
                                <b>STEP 3: </b>COLLECT LICENSE BOOK BY TK. 50 AND SUBMIT APPLICATION WITH 
                                              SUPPORTING DOCUMENTATION TO DCCS ZONAL OFFICE.<br/>
                                              For the K Form, a rent receipt for the premises where the 
                                              business is operating from or, if owned, the municipal tax 
                                              payment receipt has to be provided. Supporting documents 
                                              include:
                             </span>
                             <div className="d-flex align-items-center mb-2">
                                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                <span>
                                   3 copies of PP size photo of the owner
                                </span>
                             </div>
                             <div className="d-flex align-items-center mb-1">
                                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                <span>
                                    Rent receipt or premises ownership proof
                                </span>
                             </div>
                             <div className="d-flex align-items-center mb-1">
                                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                <span>
                                   Upon submission of the form, the LS usually goes to the business entity 
                                   for a visit to verify the information provided.
                                </span>
                             </div>
                             </p>
                         <p>
                         <p>
                            <span>
                                <b>STEP 4: </b>PAY PREDETERMINED FEE AND COLLECT TRADE LICENSE.<br/>
                                   After inspection by the LS is concluded, the business is asked to go to 
                                   the DCC office to pay the predetermined fee and collect their trade 
                                   license. The fee schedule depends on the business category under which 
                                   the application was filed.
                            </span><br/>
                            <span>
                                <b>STEP 5: </b>SIGNBOARD FEE FOR TRADE LICENSE<br/>
                                  When collecting the trade license, a signboard fee has to be paid as well. 
                                  For all types of business, the signboard fees will payable 30% of the 
                                  License fee.
                            </span>
                         </p>
                         </p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div className="container-xxl py-0">
         <div className="container">
            <h2 className="color-text fs-5 mb-4 text-center">
                <b>TRADE LICENSE FOR A MANUFACTURING FIRM</b>
            </h2>
         </div>
         <div className="h-100">
            <p className="color-text fs-5 mb-4">PROCESS STEPS </p>
            <p>
                <span>
                    <b>STEP 1: </b> PROCURE THE PROPER FORM FROM THE PROPER OFFICE.<br/>
                       The “I” Form will have to be purchased for Tk. 10 from the DCC zonal office where the 
                       manufacturing firm has to submit its application.
                </span>
                 <br/>
                             
                <span>
                    <b>STEP 2: </b> GET CERTIFICATION FROM THE LOCAL WARD COMMISSIONER.<br/>
                      The completed form has to be validated by the local ward commissioner.
                </span>
            </p>
                            
            <p>
                <span>
                    <b>STEP 3: </b>SUBMIT APPLICATION WITH SUPPORTING DOCUMENTATION.<br/>
                     <b>Supporting documents include:</b>
                </span>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            3 copies of PP size photo of the owner
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            Rent receipt or premises ownership proof
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                           No objection certificate from the neighborhood
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            A written undertaking on a Tk 150 non-judicial stamped paper
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            Fire License from the local fire department Environmental Certificate from DOE
                        </span>
                </div>
            </p>
            <p>
            <p>
                <span>
                    <b>STEP 4: </b>AWAIT ENQUIRY BY THE LICENSING SUPERVISOR (LS).<br/>
                       Upon submission of the form, the LS usually goes to the business entity for a 
                       visit to verify the information provided.
                </span><br/>
                <span>
                    <b>STEP 5: </b>PAY PREDETERMINED FEE AND COLLECT TRADE LICENSE.<br/>
                       After inspection by the LS is concluded, the business is asked to go to the DCC 
                       office to pay the predetermined fee and collect their trade license. The fee schedule 
                       depends on the business category under which the application was filed.
                </span><br/>
                <span>
                    <b>STEP 6: </b>SIGNBOARD FEE<br/>
                        When collecting the trade license, a signboard fee has to be paid as well. For all 
                        types of business, the signboard fees will payable 30% of the License fee.
                </span>
            </p>
            <p>
               <img className="mx-auto responsive-img" src={RequiredDocuments} alt='Required-Documents-RENEWAL-OF-Trade-License' style={{width: 1400, height: 300}}/>
            </p>
            <p className="color-text fs-5 mb-4 ">
                <b>RENEWAL OF TRADE LICENSE</b>
            </p>
            <p>
              The renewal process is comparatively routine and no inspection is required. When the license 
              comes up for renewal the business has to go the LS.
            </p>
            </p>
            <div className="h-100">
            <p className="color-text fs-5 mb-4">PROCESS STEPS </p>
            <p>
                <span>
                    <b>STEP 1: </b>PICK-UP DEMAND BILL FROM THE LICENSE BOOK (THIS BOOK IS VALID FOR FIVE YEARS)<br/>
                        Upon checking the expiring trade license, the LS fills in the particulars in a 
                        demand bill and gives the booklet to the business. The demand bill is a four-page 
                        booklet similar to a bank deposit slip. The same information is filled into all the 
                        pages: one page is for the bank and one is for the business.
                </span>
                 <br/>
                             
                <span>
                    <b>STEP 2: </b>PAY RELEVANT FEE AT DESIGNATED BANK<br/>
                       Deposit designated bank through demand bill and it will automatically renew the 
                       license.<br/><br/>
                       One of the important steps in starting a business in Bangladesh is acquiring a 
                       license. A businessman must obtain a business license from the respective business 
                       area’s City Corporation or City Council. Here, in this post in detail, we will 
                       explain the procedure of obtaining a trade license in Bangladesh, the fees, and 
                       finally the renewal process of trade license in Bangladesh.
                </span>
            </p>
            <p className="color-text fs-5 mb-4 ">
                <b>Step by Step Process of acquiring Trade License for any Business Entity</b>
            </p>
            <p>
                The acts/legal authorities which overseen the process of obtaining the trade license in Bangladesh are
            </p>
                            
            <p>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            The Companies Act- 1994,
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            Dhaka City Corporation Ordinance – 1983,
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            Municipal Taxation Rules – 1986.
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            And, the responsible organization’s which authorize the trade license are 
                            Municipal/City Corporation or Union Parishad.
                        </span>
                </div>
                <p>
                   Below is an overview of the process of obtaining a trade license for companies operating 
                   within the zone of Dhaka City Corporation. Many local authorities have similar rules.
                </p>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                       <b>Step 1:</b>  Collect the prescribed Application Form from the City Corporation/ Municipal
                         Corporation/ Union Parishad’s Office.
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                          <b>Step 2:</b> Submit the completed Application Form with supporting documents to the concerned section.
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                          <b> Step 3:</b> Inspection by the Licensing Supervisor.
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                           <b>Step 4:</b> Required fees to deposit at the concerned office.
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            <b>Step 5: </b>Obtain Trade License from the City Corporation/ Municipal Corporation/ Union Parishad office.
                        </span>
                </div>
                <p>
                  The trade license filled-in application form must be accompanied by the required documents.
                  <br/>
                  <b>Required Documents:</b> 
                  <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            Application Form
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                           National ID Card of the Entrepreneur
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                           Holding Tax Payment receipt 
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            Recent Passport Size Picture of the Entrepreneur
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                           Declaration  of  non –Judicial stamp to abide by the rules & regulation of City Corporation & Municipal Corporation
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            A certified copy of the memorandum and articles of association
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                           A copy of the certificate of incorporation
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            Agreement of Partnership
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                           Tax Identification Number certificate
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                           A copy of the lease agreement of the registered office
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                           Work Permit from Bangladesh Investment Development Authority
                        </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                        <span>
                            Statement of Bank Solvency.
                        </span>
                </div>
                </p>
            </p>
            </div>
         </div>
      </div>

      <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>
            
            <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
                <MarqueeCard
                    imageUrl={uddoktaImg}
                    title="Startup Packages"
                    buttonText="Read More"
                    buttonLink="/startup-packages"
                />
                <MarqueeCard
                    imageUrl={projectImg}
                    title="Project Profile"
                    buttonText="Read More"
                    buttonLink="/project-profile"
                />
                <MarqueeCard
                    imageUrl={tradeImg}
                    title="Trade License"
                    buttonText="Read More"
                    buttonLink="/trade-license"
                />
                <MarqueeCard
                    imageUrl={exportImg}
                    title="Export License"
                    buttonText="Read More"
                    buttonLink="/export-license"
                />
                <MarqueeCard
                    imageUrl={imprortImg}
                    title="Import License"
                    buttonText="Read More"
                    buttonLink="/import-license"
                />
                <MarqueeCard
                    imageUrl={loanImg}
                    title="Loan Consultancy"
                    buttonText="Read More"
                    buttonLink="/loan-consultancy"
                />
                <MarqueeCard
                    imageUrl={rjscImg}
                    title="RJSC Return Filling"
                    buttonText="Read More"
                    buttonLink="/rjsc-return-filing"
                />
                 <MarqueeCard
                    imageUrl={partnershipImg}
                    title="Partnership Registration"
                    buttonText="Read More"
                    buttonLink="/partnership-registration"
                />
                 <MarqueeCard
                    imageUrl={societyRegistrationIMg}
                    title="Society Registration"
                    buttonText="Read More"
                    buttonLink="/society-registration"
                />
                 <MarqueeCard
                    imageUrl={onePersionCompanyImg}
                    title="One Permission Company"
                    buttonText="Read More"
                    buttonLink="/one-person-company"
                />
                 <MarqueeCard
                    imageUrl={compnayFormationImg}
                    title="Company Formation"
                    buttonText="Read More"
                    buttonLink="/company-formation"
                />
                 <MarqueeCard
                    imageUrl={BSTIPermissionImg}
                    title="BSTI Permission"
                    buttonText="Read More"
                    buttonLink="/btcl-domain-registration"
                />
               
            </Marquee>
            </div>
      
      <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">Trade License FAQ</h2>
        <Faq items={faqItems} />
      </div>

      <Footer/>
    
    </>
  )
}

export default TradeLicense