import React from 'react';
import { Link } from 'react-router-dom';

function MarqueeCard({ imageUrl, title, text, buttonText, buttonLink }) {
  return (
    <div className="card" style={{ width: "18rem" }}>
      <img src={imageUrl} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title">{title}</h5>

        <Link to={buttonLink} className="btn btn-primary">{buttonText}</Link>
      </div>
    </div>
  );
}

export default MarqueeCard;
