import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import VisaConsultancyForm from '../../components/VisaConsultancyForm';
import VisaConsultancyPackages from '../../components/VisaConsultancyPackage';


function VisaConsultancyPage() {
  const services = [
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Business VISA Invitation",
      description: "We assist with the entire visa application process, including form filling, document preparation, and submission."
    },
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Work Permit VISA",
      description: "Our experts provide guidance on immigration laws, visa types, and eligibility criteria."
    },
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Private Investment VISA",
      description: "We verify the authenticity of documents to ensure compliance with visa requirements."
    },
    // Add more services as needed
  ];

  return (
    <div className="visa-consultancy-page">
      <NavBar/>
      <div className="section services-section">
        <h2>Our Services</h2>
        <div className="service-list">
          {services.map((service, index) => (
            <div key={index} className="service">
              <img src={service.image} alt={service.title} />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
      <VisaConsultancyPackages/>
      <div>
        <VisaConsultancyForm/>
      </div>
      <Footer/>
    </div>
  );
}

export default VisaConsultancyPage;
