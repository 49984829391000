import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Faq from '../../components/Faq'
import Meta from '../../components/Meta'
import vatlImg from "../../assets/images/Vat-Consultant-1.png"
import individualImg from "../../assets/images/income-Tax-1.png"
import etinImg from "../../assets/images/e-tin.png"
import corporateImg from "../../assets/images/Corporate-Tax-Rate-in-Bangladesh.png"
import binImg from "../../assets/images/BIN-Registration-1.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import individualImg2 from "../../assets/images/Income-Tax-process-606x1024.png"
import individualImg3 from "../../assets/images/Income-Tax-Rate-in-Bangladesh.png"

function IndividualTax() {
    const faqItems = [
        {
            title: 'What are the types of tax in Bangladesh?',
            content: <strong>In Bangladesh, the principal taxes are Customs duties, Value-Added-Tax (VAT), supplementary duty, income tax and corporation tax.</strong>
        },
        {
            title: 'What is the minimum tax in BD?',
            content: <strong>No minimum tax</strong>
        },
        {
            title: 'How is personal income tax calculated in Bangladesh?',
            content: <strong>Formula: Gross tax= Total Income X Rate - amount deducted Example:
                Let income is TK. 4,95,550, As it is within 2,20,001-5,20,000 range and rate is 10%, According to formula gross tax=4,95,550 X10% - 22,000= 49,555 – 22,000 = TK.</strong>
        }
    ];
    return (
        <>
            <Meta title="Individual TAX"
                description="This page provides information about BSTI permission and related processes."
                image={individualImg}
                keywords="keyword1, keyword2, keyword3"
            />
            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 ">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Individual TAX</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Taxation</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Individual TAX</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={individualImg} alt='income-Tax-1' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p>
                        Income Tax at a Glance Among direct taxes, income tax is the main source of revenue.
                        It is a progressive tax system. Income tax is imposed on the basis of ability to pay.
                        The more a taxpayer earns the more he should pay is the basic principle of charging
                        income tax. It aims at ensuring equity and social justice. In Bangladesh, income tax
                        is being administered under the tax legislation named “THE INCOME TAX ORDINANCE,
                        1984 (XXXVI OF 1984) an INCOME TAX RULES, 1984.”
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid" src={individualImg2} alt='Income-Tax-process-606x1024' />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4"> Income Tax Authorities: (Section 3 of the Ordinance)</p>
                                <p>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            The National Board of Revenue;
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Chief Commissioner of Income Tax;
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Directors-General of Inspection (Taxes);
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Commissioner of Income Tax(Appeals);
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Commissioner of Taxes (Large Taxpayer Unit);
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Director General (Training);
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Director-General, Central Intelligence Cell;
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Commissioners of Income Tax;
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Additional Commissioners of Income Tax(Appeal/Inspecting);
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Joint Commissioners of Income Tax(Appeal/Inspecting);
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Deputy Commissioners of Income Tax;
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Tax recovery officers;
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Assistant Commissioners of Income Tax;
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Extra Assistant Commissioners of Taxes; and
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Inspectors of Taxes.
                                        </span>
                                    </div>
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    (3) Heads of Income: (Section 20 of the Ordinance)
                                </p>
                                <p>
                                    For the purpose of computation of total income and charging tax thereon,
                                    sources of income can be classified into 7 categories, which are as
                                    follows :
                                </p>

                                <p>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Salaries.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Interest on securities.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Income from house property.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Agricultural Income.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Income from business or profession.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Capital gains.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Income from other sources.
                                        </span>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p>
                        <img className="mx-auto responsive-img" src={individualImg3}
                            alt='Income-Tax-Rate-in-Bangladesh' style={{ width: 1400, height: 300 }}
                        />
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(4) Tax Rate:</b>
                    </p>
                    <p>
                        Other than Company: For individuals other than female taxpayers, senior taxpayers of
                        65 years and above, retarded taxpayers and gazette war-wounded freedom fighter,
                        income tax is payable for the
                    </p>
                    <Table striped bordered hover variant="light blue" >
                        <tbody>
                            <tr>
                                <td>On first upto</td>
                                <td>Tk.3,00,000-</td>
                                <td>Nil</td>
                            </tr>
                            <tr>
                                <td>On next upto</td>
                                <td>Tk. 1,00,000/-</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>On next upto</td>
                                <td>Tk. 3,00,000/-</td>
                                <td>10%</td>
                            </tr>
                            <tr>
                                <td>On next upto</td>
                                <td>Tk. 4,00,000/-</td>
                                <td>15%</td>
                            </tr>
                            <tr>
                                <td>On next upto</td>
                                <td>Tk. 5,00,000/-</td>
                                <td>20%</td>
                            </tr>
                            <tr>
                                <td>On balance amount</td>
                                <td></td>
                                <td>25%</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>
                        For female taxpayers, senior taxpayers of age 65 years and above, income tax is payable for
                        the
                    </p>
                    <Table striped bordered hover variant="light blue" >
                        <tbody>
                            <tr>
                                <td>On first upto</td>
                                <td>Tk. 3,50,000/-</td>
                                <td>Nil</td>
                            </tr>
                            <tr>
                                <td>On next upto</td>
                                <td>Tk.1,00,000/-</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>On next upto</td>
                                <td>Tk.3,00,000/-</td>
                                <td>10%</td>
                            </tr>
                            <tr>
                                <td>On next upto</td>
                                <td>Tk.4,00,000/-</td>
                                <td>15%</td>
                            </tr>
                            <tr>
                                <td>On next upto</td>
                                <td>Tk.500,000/-</td>
                                <td>20%</td>
                            </tr>
                            <tr>
                                <td>On balance amount</td>
                                <td></td>
                                <td>25%</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                For retarded taxpayers, the tax-free income threshold limit is TK.4,50,000/-.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                For gazette war-wounded freedom fighters, the tax-free income threshold limit is Tk. 4, 75, 000/-.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The minimum tax for any individual assesses living in Dhaka and Chittagong City Corporation area   is Tk. 5,000/-.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The minimum tax for any individual assesses living in other City Corporations area is Tk. 4,000/-.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The minimum tax for any individual assessee living in any other area is Tk. 3,000/-.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Non-resident Individual 30% (other than non-resident Bangladeshi)
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>For Company:</b>
                    </p>
                    <Table striped bordered hover variant="light blue" >
                        <tbody>
                            <tr>
                                <td>(i)</td>
                                <td>Publicly Traded Company</td>
                                <td>25%</td>
                            </tr>
                            <tr>
                                <td>(ii)</td>
                                <td>Non-publicly Traded Company</td>
                                <td>32.5%</td>
                            </tr>
                            <tr>
                                <td>(iii) Bank, Insurance & Financial institutions (Except Merchant bank):</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1. Publicly listed and 4th generation Banks & FI</td>
                                <td></td>
                                <td>40%</td>
                            </tr>
                            <tr>
                                <td>2. Other Banks & FI</td>
                                <td></td>
                                <td>42.5%</td>
                            </tr>
                            <tr>
                                <td>(iv)</td>
                                <td>Merchant bank</td>
                                <td>37.5%</td>
                            </tr>
                            <tr>
                                <td>(v)</td>
                                <td>Cigarette manufacturing company/Others</td>
                                <td>45%</td>
                            </tr>
                            <tr>
                                <td>(vi)</td>
                                <td>Mobile Phone Operator Company</td>
                                <td>45%</td>
                            </tr>
                            <tr>
                                <td>(vii)</td>
                                <td>Publicly traded mobile company</td>
                                <td>40%</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>
                        If any non-publicly traded company transfers a minimum of 20% shares of its paid-up
                        capital through IPO (Initial Public Offering) it would get 10% rebate on total tax in
                        the year of transfer.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(5) Tax Rebate for investment: [Section 44(2)/only allowable for Resident/Non-Resident Bangladeshi]</b>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(a) Rate of Rebate:</b>
                    </p>
                    <p>
                        The amount of allowable investment is – actual investment or 25% of the total
                        (taxable) income or Tk. 1,50,00,000/- whichever is less. The tax rebate amounts to
                        15% of the allowable investment.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Types of investment qualified for the tax rebate are:-</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Life insurance premiums up to 10% of the face value.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Contribution to Provident Fund to which Provident Fund Act, 1925 applies.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Self contribution and employer’s contribution to the Recognized Provident Fund.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Contribution to Superannuation Fund.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Contribution up to TK 60,000 to deposit pension scheme sponsored by any scheduled bank or a financial institution.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Investment in an approved debenture or debenture stock, Stocks, or Shares.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Contribution to Benevolent Fund and Group Insurance premium.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Contribution to Zakat Fund.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Donation to a charitable hospital approved by the National Board of Revenue.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Donation to philanthropic or educational institution approved by the Government.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Donation to socio-economic or cultural development institution established in Bangladesh by Aga Khan Development Network.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Donation to ICDDRB.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Donation to philanthropic institution-CRP, Savar, Dhaka.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                A donation up to tk. 5 lac to
                            </span>
                        </div>
                        <span className='px-5'>
                            1. Shishu Swasthya Foundation Hospital, Mirpur, Shishu Hospital, Jessore and Hospital for Sick Children, Sathkhira run by Shishu Swasthya Foundation, Dhaka.
                        </span><br></br><br></br>
                        <span className='px-5'>
                            2. Diganta Memorial Cancer Hospital, Dhaka.
                        </span><br></br><br></br>
                        <span className='px-5'>
                            3. The ENT and Head-Neck Cancer Foundation of Bangladesh, Dhaka and
                        </span><br></br><br></br>
                        <span className='px-5'>
                            4. Jatiya Protibandhi Unnayan Foundation, Mirpur, Dhaka.
                        </span><br />
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Donation to Dhaka Ahsania Mission Cancer Hospital.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Donation to Sylhet Diabetic Samity, Islamia Eye Hospital and MA Ispahani Institute of Ophthalmology, Kidney Foundation, and National Heart Foundation of Bangladesh.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Donation to Asiatic Society of Bangladesh.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Donation to Muktijodha Jaguar.
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(6) Who should submit Income Tax Return?</b>
                    </p>
                    <p>
                        If the total income of any individual other than female taxpayers, senior male
                        taxpayers of 65 years and above, retarded taxpayers and war-wounded gazette freedom
                        fighter during the income year exceeds Tk 2,50,000/-. If the total income of any
                        female taxpayer, the senior male taxpayer of 65 years and above during the income
                        year exceeds Tk 3,00,000/-.
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                If total income of any retarded taxpayer during the income year exceeds TK. 3,75,000.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                If the total income of any gazette war-wounded freedom fighter taxpayer during the income year exceeds Tk. 4,25.000/-.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                If any person was assessed for tax during any of the 3 years immediately preceding the income year.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                A person who lives in any city corporation/paurashava/divisional HQ/district HQ and owns a motor car/owns membership of a club registered under VAT Law.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                If any person runs a business or profession having a trade license and operates a bank account.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                any professional registered as a doctor, lawyer, income tax practitioner, Chartered Accountant, Cost
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Management Accountant, Engineer, Architect, and Surveyor, etc.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Member of a Chamber of Commerce and Industries or a trade Association.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Any person who participates in a tender.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Candidate for Paurashava, City Corporation, Upazilla, or Parliament elections.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Any company registered under the Company Act, 1913 or 1994.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Any Non-government organization (NGO) registered with NGO Affairs Bureau.
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(7) Time to submit Income Tax Return: [Section 75(2) of the Ordinance]</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>For Company Taxpayers:</b>By the fifteenth day of July next following the
                                income year or, where the fifteenth day of July falls before the expiry of
                                six months from the end of the income year, before the expiry of such six
                                months.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>For Other Taxpayers:</b>Unless the date is extended, by the Thirtieth day of September next following the income year.
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(8) Submission of withholding tax return and time to submit:</b>
                    </p>
                    <p>
                        Person who makes any TDS (Tax deduction at source) on payment, must file a separate
                        return of withholding tax under section 75A of the Ordinance.<br /><br />

                        15th day of October, January, April, and July. Or extended date up to 15 days by DCT.
                        Statement of TDS, Copy of Treasury challan /pay-orders should be submitted with the
                        return.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(9) Consequences of Non-Submission of Return and Return of withholding tax. (Section 124 of the Ordinance):</b>
                    </p>
                    <p>
                        The imposition of penalty amounting to 10% of tax on last assessed income subject to
                        a minimum of Tk. 1,000/-
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                In case of an individual assesses whose income was not assessed previously Tk. 5,000/-.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                In case of an individual assesses whose income was assessed previously, fifty percent (50%) of the tax payable on the last assessed income or Tk. 1,000/-, whichever is higher.
                            </span>
                        </div>
                        In case of a continuing default by any type of assessee, a further penalty of Tk. 50/- for every day of delay.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(10) Consequences of using fake TIN: </b>
                    </p>
                    <p>
                        DCT can impose a penalty not exceeding TK.20,000/-<br /><br />

                        For continuous use of fake TIN deliberately- 3 years imprisonment, up to TK. 50,000/- fine or both.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(11) Assessment Procedures: </b>
                    </p>
                    <p>
                        For a return submitted under the normal scheme, assessment is made after giving an
                        opportunity of hearing.<br /><br />

                        For returns submitted under Universal Self-Assessment Scheme, the acknowledgment
                        slip is considered to be an assessment order of the Deputy Commissioner of Taxes.
                        Universal Self-Assessment may be subject to “process and audit”.<br /><br />

                        Provided that a return of income filed under universal self-assessment scheme,
                        shall not be selected for audit where such return shows at least twenty percent
                        higher income than the income assessed or shown in the return of the immediately
                        preceding assessment year and such return-
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Is accompanied by corroborative evidence in support of income exempted from tax;
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Does not show receipt of gift during the year;
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Does not show any income chargeable to tax at a rate reduced under section 44, or
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Does not show or result in any refund.
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(12) An appeal against the order of DCT: (Under section 153 & 158 of the Ordinance) </b>
                    </p>
                    <p>
                        A taxpayer can file an appeal against DCT’s order to the Commissioner (Appeals)/
                        Additional or Joint Commissioner of Taxes (Appeals) and to the Taxes Appellate
                        Tribunal against an Appeal order.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>(13) Tax withholding functions: </b>
                    </p>
                    <p>
                        In Bangladesh withholding taxes are usually termed as a Tax deduction and collection
                        at source. Under this system, both private and public limited companies or any other
                        organization specified by law are legally authorized and bound to withhold taxes at
                        some point in making payment and deposit the same to the Government Exchequer. The
                        taxpayer receives a certificate from the withholding authority and gets credits of
                        tax against assessed tax on the basis of such a certificate.
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={binImg}
                        title="BIN Registration"
                        buttonText="Read More"
                        buttonLink="/bin-registration"
                    />
                    <MarqueeCard
                        imageUrl={corporateImg}
                        title="Corporate TAX"
                        buttonText="Read More"
                        buttonLink="/corporate-tax"
                    />
                    <MarqueeCard
                        imageUrl={etinImg}
                        title="E-TIN Certificate"
                        buttonText="Read More"
                        buttonLink="/etin-certificate"
                    />
                    <MarqueeCard
                        imageUrl={individualImg}
                        title="Individual TAX"
                        buttonText="Read More"
                        buttonLink="/individual-tax"
                    />
                    <MarqueeCard
                        imageUrl={vatlImg}
                        title="Value Added TAX"
                        buttonText="Read More"
                        buttonLink="/value-added-tax"
                    />
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Individual Tax FAQ</h2>
                <Faq items={faqItems} />
            </div>

            <Footer />
        </>
    )
}

export default IndividualTax