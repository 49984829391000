
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import Base from './screen/Base';
import CompanyFormation from './screen/Startup Screen/CompanyFormation';
import OnePersonCompany from './screen/Startup Screen/OnePersonCompany';
import SocietyRegistration from './screen/Startup Screen/SocietyRegistration';
import RJSCReturnFilling from './screen/Startup Screen/RJSCReturnFilling';
import LoanConsultancy from './screen/Startup Screen/LoanConsultancy';
import ImportLicense from './screen/Startup Screen/ImportLicense';
import ExportLicense from './screen/Startup Screen/ExportLicense';
import BstiPermission from './screen/Startup Screen/BstiPermission';
import TradeLicense from './screen/Startup Screen/TradeLicense';
import ProjectProfile from './screen/Startup Screen/ProjectProfile';
import UddoktaPackage from './screen/Startup Screen/UddoktaPackage';
import PartnershipRegistration from './screen/Startup Screen/PartnershipRegistration';
import Trademark from './screen/Intellectual Property Screen/Trademark';
import Copyright from './screen/Intellectual Property Screen/Copyright';
import Patent from './screen/Intellectual Property Screen/Patent';
import IndustrialDesign from './screen/Intellectual Property Screen/IndustrialDesign';
import IprEnforcement from './screen/Intellectual Property Screen/IprEnforcement';
import BtclDomain from './screen/Intellectual Property Screen/BtclDomain';
import WebDevelopment from './screen/Intellectual Property Screen/WebDevelopment';
import CivilLitigationScreen from './screen/Litigation/CivilLitigationScreen';
import CriminalLitigationScreen from './screen/Litigation/CriminalLitigationScreen';
import ForeignCompanyFormation from './screen/Foreign Entity/ForeignCompanyFormation';
import ForeignDirectInvestment from './screen/Foreign Entity/ForeignDirectInvestment';
import BidaPermission from './screen/Foreign Entity/BidaPermission';
import WorkPermitVisaInBangladesh from './screen/Foreign Entity/WorkPermitVisaInBangladesh';
import ForeignDelegates from './screen/Foreign Entity/ForeignDelegates';
import ForeignCompanyDirectorService from './screen/Foreign Entity/ForeignCompanyDirectorService';
import ForeignCompanyRegisteredAddress from './screen/Foreign Entity/ForeignCompanyRegisteredAddress';
import FireLicenseScreen from './screen/Regulatory License/FireLicenseScreen';
import FactoryLicense from './screen/Regulatory License/FactoryLicense';
import CustomsBondLicense from './screen/Regulatory License/CustomsBondLicense';
import EnvironmentClearanceCertificate from './screen/Regulatory License/EnvironmentClearanceCertificate';
import IndividualTax from './screen/Taxation/IndividualTax';
import CorporateTax from './screen/Taxation/CorporateTax';
import ValueAddedTax from './screen/Taxation/ValueAddedTax';
import ETINCertificate from './screen/Taxation/ETINCertificate';
import BINRegistration from './screen/Taxation/BINRegistration';
import AccountService from './screen/Taxation/Audit/AccountService';
import BookeepingServices from './screen/Taxation/Audit/BookeepingServices';
import SmallBusiness from './screen/Taxation/Audit/SmallBusiness';
import AccountingSoftware from './screen/Taxation/Audit/Business Software/AccountingSoftware';
import ERPSoftware from './screen/Taxation/Audit/Business Software/ERPSoftware';
import VatSoftware from './screen/Taxation/Audit/Business Software/VatSoftware';
import HRManagementSoftware from './screen/Taxation/Audit/Business Software/HRManagementSoftware';
import CompanyPortfolio from './screen/Taxation/Audit/Business Software/CompanyPortfolio';
import AboutUs from './screen/AboutUs';
import PaymentProcess from './screen/PaymentProcess';
import RefundAndReturnPlolicy from './screen/RefundAndReturnPlolicy';
import PrivacyPolicy from './screen/PrivacyPolicy';
import TermAndCondition from './screen/TermAndCondition';
import ContactUs from './screen/ContactUs';
import SiteMap from './screen/SiteMap';
import VATCalculatorPage from './screen/Footer/VATCalculatorPage';
import BlogCard from './screen/Footer/BlogCard';
import PropertyConsultancyPage from './screen/Footer/PropertyConsultancyPage';
import VisaConsultancyPage from './screen/Footer/VisaConsultancyPage';
import CoSpace from './screen/Footer/CoSpace';
import PrivateInvestmentVISA from './screen/Foreign Entity/PrivateInvestmentVISA';
import CompanyFormationViewFormPage from './screen/CompanyFormationViewFormPage';
import GetStartedPage from './screen/GetStartedPage';
function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Base/>} />
      <Route path="/company-formation" element={<CompanyFormation />} />
      <Route path="/one-person-company" element={<OnePersonCompany />} />
      <Route path="/society-registration" element={<SocietyRegistration />} />
      <Route path="/partnership-registration" element={<PartnershipRegistration />} />
      <Route path="/rjsc-return-filing" element={<RJSCReturnFilling />} />
      <Route path="/loan-consultancy" element={<LoanConsultancy />} />
      <Route path="/import-license" element={<ImportLicense />} />
      <Route path="/export-license" element={<ExportLicense />} />
      <Route path="/bsti-permission" element={<BstiPermission />} />
      <Route path="/trade-license" element={<TradeLicense />} />
      <Route path="/project-profile" element={<ProjectProfile />} />
      <Route path="/startup-packages" element={<UddoktaPackage />} />
      
      <Route path="/trademark" element={<Trademark />} />
      <Route path="/copyright" element={<Copyright />} />
      <Route path="/patent" element={<Patent />} />
      <Route path="/industrial-design" element={<IndustrialDesign />} />
      <Route path="/ipr-enforcement" element={<IprEnforcement />} />
      <Route path="/btcl-domain-registration" element={<BtclDomain />} />
      <Route path="/web-development" element={<WebDevelopment />} />

      <Route path="/individual-tax" element={<IndividualTax />} />
      <Route path="/corporate-tax" element={<CorporateTax />} />
      <Route path="/value-added-tax" element={<ValueAddedTax />} />
      <Route path="/etin-certificate" element={<ETINCertificate />} />
      <Route path="/bin-registration" element={<BINRegistration />} />
      <Route path="/account-service" element={<AccountService />} />
      <Route path="/bookkeeping-services" element={<BookeepingServices />} />
      <Route path="/small-business" element={<SmallBusiness />} />
      <Route path="/acounting-software" element={<AccountingSoftware />} />
      <Route path="/erp-software" element={<ERPSoftware />} />
      <Route path="/vat-software" element={<VatSoftware />} />
      <Route path="/hr-management-software" element={<HRManagementSoftware />} />
      <Route path="/company-portfolio" element={<CompanyPortfolio />} />

      <Route path="/civil-litigation" element={<CivilLitigationScreen />} />
      <Route path="/criminal-litigation" element={<CriminalLitigationScreen />} />

      <Route path="/foreign-company-formation" element={<ForeignCompanyFormation />} />
      <Route path="/foreign-direct-investment" element={<ForeignDirectInvestment />} />
      <Route path="/bida-permission" element={<BidaPermission />} />
      <Route path="/work-permit-visa" element={<WorkPermitVisaInBangladesh />} />
      <Route path="/private-investment-visa" element={<PrivateInvestmentVISA />} />
      <Route path="/foreign-delegates" element={<ForeignDelegates />} />
      <Route path="/foreign-company-director-service" element={<ForeignCompanyDirectorService />} />
      <Route path="/foreign-company-registered-address" element={<ForeignCompanyRegisteredAddress />} />

      <Route path="/fire-license" element={<FireLicenseScreen />} />
      <Route path="/factory-license" element={<FactoryLicense />} />
      <Route path="/customs-bond-license" element={<CustomsBondLicense />} />
      <Route path="/environment-clearance-certificate" element={<EnvironmentClearanceCertificate />} />

      <Route path="/about-us" element={<AboutUs />} />
      <Route path='/payment-process' element={<PaymentProcess/>}/>
      <Route path='/refund-policy' element={<RefundAndReturnPlolicy/>}/>
      <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
      <Route path='/term-condition' element={<TermAndCondition/>}/>
      <Route path='/contact-us' element={<ContactUs/>}/>
      <Route path='/sitemap' element={<SiteMap/>}/>

      <Route path='/vat-calculator' element={<VATCalculatorPage/>}/>
      <Route path='/blog' element={<BlogCard/>}/>
      <Route path='/visa-consultancy' element={<VisaConsultancyPage/>}/>
      <Route path='/property-consultancy' element={<PropertyConsultancyPage/>}/>
      <Route path='/co-space' element={<CoSpace/>}/>
      <Route path='/company-formation-form-page' element={<CompanyFormationViewFormPage/>}/>
      <Route path='/get-started-page' element={<GetStartedPage/>}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
