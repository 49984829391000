import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import civilImg from "../../assets/images/Civil-Litigation.png"
import criminalImg from "../../assets/images/Criminal-Litigation-1.png"
import fireImg from "../../assets/images/Fire-License-In-Bangladesh.png"
import factoryImg from "../../assets/images/Fire-License-In-Bangladesh-2.png"
import bondImg from "../../assets/images/Fire-License-In-Bangladesh-4.png"
import environmentImg from "../../assets/images/Environmental.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import criminalImg2 from "../../assets/images/Confusions-regarding-Criminal-Litigation-798x1024.png"

function CriminalLitigationScreen() {
    const faqItems = [
        {
            title: 'How many types of criminal Court are there in Bangladesh?',
            content: <strong>The criminal cases are tried in the criminal courts and there are two types of criminal
                courts – the Court of Sessions and the Magistrate&#39;s Court.</strong>
        },
        {
            title: 'What are the three most common types of criminal cases?',
            content: <strong>Three case types are included in this case-type category: Felonies, Misdemeanors, and
                Criminal Infractions.</strong>
        },
        {
            title: 'What is the structure of criminal court in Bangladesh?',
            content: <strong>Document Information. The criminal court structure of Bangladesh consists of the Supreme
                Court, which has an Appellate Division and a High Court Division.</strong>
        }
    ];
    return (
        <>
            <Meta title="Criminal Litigation"
                description="This page provides information about BSTI permission and related processes."
                image={criminalImg}
                keywords="keyword1, keyword2, keyword3"
            />
            
            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Criminal Litigation</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Litigation</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Criminal Litigation</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={criminalImg} alt='Criminal-Litigation-1' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>What is criminal Litigation</b>
                    </p>
                    <p>
                        Criminal litigation refers to the legal process of prosecuting or defending a person
                        who has been accused of committing a crime. It is the process by which a criminal
                        case is taken through the court system, from the initial arrest or indictment to the
                        verdict or plea bargain.<br /><br />
                        The criminal litigation process involves several stages, including arraignment,
                        pretrial hearings, discovery, trial, and sentencing. During the process, both the
                        prosecution and defence will gather evidence and present their case in court, with
                        the ultimate goal of proving the guilt or innocence of the accused.<br /><br />
                        Criminal litigation is a complex process that involves many legal procedures and rules,
                        as well as significant consequences for the accused, such as fines, imprisonment, and
                        loss of civil rights. It is important for anyone involved in a criminal case, whether
                        as the accused, the victim, or a witness, to have a thorough understanding of the
                        legal system and the criminal litigation process.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Difference between Civil Litigation and Criminal Litigation</b>
                    </p>
                    <p>
                        The key difference between civil litigation and criminal litigation is the nature of
                        the legal dispute and the parties involved.<br /><br />

                        Civil litigation involves disputes between private parties or entities, such as
                        individuals, businesses, or organizations. The goal of civil litigation is typically
                        to resolve a disagreement or dispute and to seek compensation for damages or losses.
                        Examples of civil litigation include contract disputes, personal injury claims, and
                        property disputes.<br /><br />

                        On the other hand, criminal litigation involves disputes between the government and
                        an individual who is accused of committing a crime. The goal of criminal litigation
                        is to determine whether the accused is guilty of a crime and to impose punishment if
                        they are found guilty. Examples of criminal litigation include cases of theft,
                        assault, murder, and drug offences.<br /><br />

                        Another key difference between civil and criminal litigation is the burden of proof.
                        In civil litigation, the burden of proof is typically lower, and the plaintiff must
                        only prove their case by a preponderance of the evidence, which means that it is more
                        likely than not that their claims are true. In criminal litigation, the burden of
                        proof is much higher, and the prosecution must prove their case beyond a reasonable
                        doubt, which means that there is no reasonable doubt in the minds of the jurors about
                        the guilt of the accused.<br /><br />

                        Overall, civil litigation and criminal litigation are two distinct legal processes
                        that have different goals, parties involved, and burden of proof requirements.
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid" src={criminalImg2} alt='Confusions-regarding-Criminal-Litigation-798x1024' />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">Confusions regarding Criminal Litigation</p>
                                <p>
                                    If you have any confusion regarding criminal litigation, It would be
                                    helpful if you clarify them to your lawyer. Here are some common areas of
                                    confusion related to criminal litigation that I can provide some insight on:
                                </p>
                                <p><b>1. Criminal charges:</b> One common area of confusion is understanding
                                    the nature of criminal charges and the process of being charged with a
                                    crime. If you have been charged with a crime, it is important to
                                    understand the specific charges against you, the potential
                                    consequences if you are convicted, and the steps involved in the
                                    criminal litigation process.
                                </p>

                                <p><b>2. Legal terminology:</b> Criminal litigation involves many legal terms
                                    and concepts that can be confusing to people who are not familiar with the
                                    legal system. It can be helpful to consult with an attorney or legal expert
                                    who can help explain the terminology and process in more detail.
                                </p>

                                <p>
                                    <b>3. Criminal defence:</b> If you have been accused of a crime, it is
                                    important to understand your rights and options for a criminal defence.
                                    A criminal defence attorney can help explain the options available to
                                    you and work to protect your rights throughout the criminal litigation
                                    process.
                                </p>
                                <p>
                                    <b>4. Evidence and witnesses:</b>The criminal litigation process involves
                                    presenting evidence and witnesses to support your case. It can be
                                    confusing to understand what types of evidence are admissible in court
                                    and how to present your case effectively. A legal expert can help guide
                                    you through this process and help you understand what evidence and
                                    witnesses can be presented in court.
                                </p>
                                <p>
                                    Overall, criminal litigation can be a complex process that involves many
                                    legal concepts and procedures.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>Tips to get better help for criminal litigation</b>
                    </p>
                    <p>
                        If you are seeking criminal litigation help, here are a few recommendations:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Hire an experienced criminal defence attorney: </b>It is important to hire an
                                attorney who has experience in criminal defence and has a good track record of
                                success in defending clients. Look for an attorney who has experience handling
                                cases similar to yours.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Check their credentials: </b>Make sure that the attorney you are considering
                                is licensed to practice law in your state and is in good standing with the bar
                                association. You can check an attorney’s credentials by contacting your state’s
                                bar association or by searching online.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Communicate openly and honestly with your attorney: </b>Your attorney needs to
                                have all the information about your case to provide you with the best possible
                                defence. Be sure to communicate openly and honestly with your attorney about all
                                aspects of your case.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Ask questions: </b>Don’t be afraid to ask your attorney questions about your
                                case, the legal process, and their strategy for defending you. A good attorney
                                will be happy to answer your questions and keep you informed.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Stay involved in your case: </b>It is important to stay involved in your case
                                and keep your attorney informed about any new developments. Attend all court
                                hearings and meetings with your attorney, and be proactive about providing any
                                additional information or evidence that could help your case.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Consider alternative dispute resolution options: </b>In some cases, it may be possible to
                                resolve a criminal case through alternative dispute resolution methods such as mediation or
                                arbitration. These options can be less expensive and time-consuming than going to trial,
                                but they are not appropriate for every case.
                            </span>
                        </div>
                        Remember, the best way to get better criminal litigation help is to hire an experienced
                        and qualified criminal defiance attorney and to stay involved in your case.
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={environmentImg}
                        title="Environment Clearance Certificate"
                        buttonText="Read More"
                        buttonLink="/environment-clearance-certificate"
                    />
                    <MarqueeCard
                        imageUrl={bondImg}
                        title="Custom Bond License"
                        buttonText="Read More"
                        buttonLink="/customs-bond-license"
                    />
                    <MarqueeCard
                        imageUrl={factoryImg}
                        title="Factory License"
                        buttonText="Read More"
                        buttonLink="/factory-license"
                    />
                    <MarqueeCard
                        imageUrl={fireImg}
                        title="Fire License"
                        buttonText="Read More"
                        buttonLink="/fire-license"
                    />
                    <MarqueeCard
                        imageUrl={criminalImg}
                        title="Criminal Litigation"
                        buttonText="Read More"
                        buttonLink="/criminal-litigation"
                    />
                    <MarqueeCard
                        imageUrl={civilImg}
                        title="Civil Litigation"
                        buttonText="Read More"
                        buttonLink="/civil-litigation"
                    />


                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Criminal Litigation FAQ</h2>
                <Faq items={faqItems} />
            </div>
            <Footer />
        </>
    )
}

export default CriminalLitigationScreen