import React from 'react'
import Meta from '../../components/Meta';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Faq from '../../components/Faq';
import { Link } from 'react-router-dom';
import openForeignCompany from "../../assets/images/open-Foreign-Company.png"
import BidaImg from "../../assets/images/Bida-.png"
import foreigncompanydirectorserviceImg from "../../assets/images/foreign-company-director-service-1.png"
import foreigncompanyregisteredaddressImg from "../../assets/images/foreign-company-registered-address-1.png"
import foreignDelegatesImg from "../../assets/images/Foreign-Delegates-1.png"
import directorServiceImg from "../../assets/images/Foreign-Direct-Investment-Investment-Bangladesh.png"
import privateInvestmentImg from "../../assets/images/Foreign-Direct-Investment-Investment-Bangladesh.png"
import workPermitVISA from "../../assets/images/Work-Permit.png"
import Marquee from "react-fast-marquee";
import MarqueeCard from '../../components/MarqueeCard'

function PrivateInvestmentVISA() {
    const faqItems = [
        {
            title: ' What is the current foreign direct investment in Bangladesh?',
            content: <strong>FDI in Figures. According to the UNCTAD's World Investment Report 2023, FDI inflows
                to Bangladesh increased by 20.2% to USD 3.48 billion in 2022 (compared to USD 2.89 billion in
                2021). In the same year, the total stock of FDI was estimated at USD 21.1 billion, representing
                only 4.6% of the country's GDP.</strong>
        },
        {
            title: 'Which sector has the highest foreign direct investment in Bangladesh? ',
            content: <strong>Sectoral distribution of FDI inflows
                There are three broad sectors of FDI inflows: infrastructure, manufacturing, and services. The
                shares of the three sectors are 46 percent, 27 percent and 27 percent respectively (Chart 3).</strong>
        },
        {
            title: 'How does FDI affect Bangladesh?',
            content: <strong> According to research, a ten percent increase in FDI can lead to a 3.7 percent
                increase in Bangladesh's GDP. A 13% annual growth in FDI can reduce poverty by 1%.
                Encouraging FDI growth is crucial to making a real impact on alleviating poverty.</strong>
        }
    ];
    return (
        <>
            <Meta title="Private Investment VISA"
                description="This page provides information about BSTI permission and related processes."
                image={privateInvestmentImg}
                keywords="keyword1, keyword2, keyword3"
            />
            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Private Investment VISA</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Foreign Entity</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Private Investment VISA</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={privateInvestmentImg} alt='Foreign-Direct-Investment-Investment-Bangladesh' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={openForeignCompany}
                        title="Foreign Company Formation"
                        buttonText="Read More"
                        buttonLink="/foreign-company-formation"
                    />
                    <MarqueeCard
                        imageUrl={workPermitVISA}
                        title="Work Permit Visa In Bangladesh"
                        buttonText="Read More"
                        buttonLink="/work-permit-visa"
                    />
                    <MarqueeCard
                        imageUrl={privateInvestmentImg}
                        title="Private Investment VISA"
                        buttonText="Read More"
                        buttonLink="/private-investment-visa"
                    />
                    <MarqueeCard
                        imageUrl={directorServiceImg}
                        title="Foreign Company Director Service"
                        buttonText="Read More"
                        buttonLink="/foreign-company-director-service"
                    />
                    <MarqueeCard
                        imageUrl={foreignDelegatesImg}
                        title="Foreign Delegates"
                        buttonText="Read More"
                        buttonLink="/foreign-delegates"
                    />
                    <MarqueeCard
                        imageUrl={foreigncompanyregisteredaddressImg}
                        title="Foreign Company Registered Address"
                        buttonText="Read More"
                        buttonLink="/foreign-company-registered-address"
                    />
                    <MarqueeCard
                        imageUrl={foreigncompanydirectorserviceImg}
                        title="Foreign Direct Investment"
                        buttonText="Read More"
                        buttonLink="/foreign-company-director-service"
                    />
                    <MarqueeCard
                        imageUrl={BidaImg}
                        title="BIDA Permission"
                        buttonText="Read More"
                        buttonLink="/bida-permission"
                    />
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Foreign DirectInvestment FAQ</h2>
                <Faq items={faqItems} />
            </div>

            <Footer />
        </>
    )
}

export default PrivateInvestmentVISA