import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'
import { FaHandshake } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaHandPeace } from "react-icons/fa";
import SlideShare from '../components/SlideShare';
import { IoPerson } from "react-icons/io5";
import { FaPhoneVolume } from "react-icons/fa6";

function AboutUs() {
    return (
        <>
            <NavBar />
          
            <Container className='about-page' style={{ marginTop: '100px', padding: '10px', marginBottom: '20px' }}>
                <h2>Who we are?</h2>
                <p>
                    "JK Associates" is top-ranked Corporate Consultancy and Law firm in Bangladesh with the international
                    presence provides specialized services in Intellectual Property Rights, Corporate Affairs, Foreign
                    Trade and Investment, Taxation, and Litigation.<br /><br />

                    The firm has a constellation of experienced and proficient lawyers in the relevant fields of
                    Intellectual Property i.e. Trademarks, Patents, Copyright, Industrial Designs, Brand Protection, and
                    Brand enforcement. The proactive team members are also experienced in Company Startup planning,
                    Company Registration, Export-Import License, Business related licenses registration, Income Tax, VAT
                    Litigation and, Company Law.<br /><br />

                    With the latest Information Technology tools, JK Associates provides high quality, low cost, and
                    speedy Legal Services. The firm is proud to provide services to many local and world-renowned
                    companies.<br /><br />

                    JK Associates always keeps clients’ needs at the center of focus and take the time to learn about
                    their concerns. On a strong scaffold of professional ethics and meticulously crafted operational
                    procedures, JK Associates treats every client with the individuality that he or she deserves.<br /><br />

                    Our sincere thanks for your valued business and profession. We appreciate having you as a part of us
                    and look forward to serving you.
                </p>
            </Container>

            <Container className='flex'>
                <div>
                    <FaPeopleGroup />
                    <p>16+</p>
                    <p>specialists</p>
                </div>
                <div>
                    <FaHandshake />
                    <p>1120+</p>
                    <p>happy clients</p>
                </div>
                <div>
                    <FaHandPeace />
                    <p>2150+</p>
                    <p>successful cases</p>
                </div>
            </Container>

            <Container style={{ textAlign: 'center' }}>
                <h3>Appreciation make us HAPPY!</h3>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '40px' }}>
                    <div style={{ width: '50%' }} className='slidep'>
                        <SlideShare />
                    </div>
                </div>
            </Container>

            <Container className='about-page-down'>
                <h2><center>Why JK Associates?</center></h2>
                <p>
                    <b>DIVERSITY and INCLUSION</b><br /><br />

                    Because, We have designed our firm to be a reflection of our society and including
                    promoting individuals to leadership positions regardless of race, religion or orientation.
                    But Maintaining and expanding the diversity of our team is a central tenet of our firm.
                    We actively recruit diverse team members. We ensure all team members receive challenging
                    work assignments and that all lawyers receive client exposure opportunities consistent
                    with their demonstrated skill and ability.<br /><br />

                    <b>FLEXIBLE, VALUE-DRIVEN APPROACH</b><br /><br />

                    Because, In approaching each case, each team member is encouraged to have ownership of
                    the clients and results. With our team’s experience, we effectively provide clients with
                    the attention and commitment that they expect and deserve. We strive to provide a
                    value-driven approach to each case by communicating and strategizing with our clients to
                    meet their needs and goals.<br /><br />

                    <b>CLIENT-FOCUSED SOLUTIONS AND RESULTS</b><br /><br />

                    Because, Our firm is focused on our clients’ demands for an exceptional legal service
                    model that provides value. We understand that, for clients, the value is not a mere hourly
                    rate comparison. Value means efficient processes for tracking and reporting; creative
                    approaches to reducing legal risk; enhanced cost certainty; and RESULTS.<br /><br />

                    With each representation, our team is focused on the desired results. While some firms may
                    only focus on the next step in the process, our team focuses the strategy at each stage of
                    litigation on the end result. Focusing on the client’s preferred result is an integral
                    part of the strategic process for each case.<br /><br />

                    We have designed a system focused on client service. We have done so by aligning our
                    culture, structure, and processes with one goal in mind- CLIENT SATISFACTION.<br /><br />

                    <b>AVAILABILITY</b><br /><br />

                    Because, our attorneys are always available to make time for their clients. They are here
                    to answer questions and address concerns and are always just a phone call or email away.
                    We also provide nighttime and weekend appointments to fit any busy schedule.
                </p>
            </Container>

            <Container>
                <h2 style={{ color: 'blue', padding: '20px' }}><center>They are also happy with us...</center></h2>
            </Container>
            <Container className='about-page' style={{ marginTop: '30px', padding: '10px', marginBottom: '20px' }}>
                <center>
                    <h2>Our Team</h2>
                </center>
                <div className="flex-container">
                    <div className="flex-item">
                        <IoPerson style={{ fontSize: '10em' }} />
                        <h4>Jakaria Kowser</h4>
                        <h6>Lead Consultant</h6>
                        <h6><FaPhoneVolume /> 01511404971</h6>
                    </div>
                    <div className="flex-item">
                        <IoPerson style={{ fontSize: '10em' }} />
                        <h4>Abrar Khan Taslim</h4>
                        <h6>Senior Consultant</h6>
                        <h6><FaPhoneVolume /> 01511404971</h6>
                    </div>
                    <div className="flex-item">
                        <IoPerson style={{ fontSize: '10em' }} />
                        <h4>Abdullah Al Hadi</h4>
                        <h6>Senior Consultant</h6>
                        <h6><FaPhoneVolume /> 01511404971</h6>
                    </div>
                    <div className="flex-item">
                        <IoPerson style={{ fontSize: '10em' }} />
                        <h4>Md. Rezaul Huqe Jony</h4>
                        <h6>Associate</h6>
                        <h6><FaPhoneVolume /> 01511404971</h6>
                    </div>
                </div>

            </Container>

            <Footer />
        </>
    )
}

export default AboutUs