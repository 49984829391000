import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import BtclImg from "../../assets/images/JK-Banner.png"
import copyrightImg from "../../assets/images/copyRight-Registration-2.png"
import industrialImg from "../../assets/images/Industrial-Design.png"
import iprImg from "../../assets/images/Intellectual-property-rights-Bangladesh.png"
import patentImg from "../../assets/images/Patent-registration.png"
import trademarkImg from "../../assets/images/Trade-license-Registration.png"
import webdevelopImg from "../../assets/images/web-development-1.jpg"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import btclImg2 from "../../assets/images/BTCL-Domain-Registration.png"

function BtclDomain() {
    const faqItems = [
        {
            title: 'How do I apply for BTCL connection?',
            content: <strong>After downloading and installing &#39;Telesheba&#39; app from Google Play Store by using
                android phones or by visiting the website: www.telesheba.gov.bd, one can apply for new
                telephone connections of the BTCL.</strong>
        },
        {
            title: 'How much is BTCL call rate in Bangladesh?',
            content: <strong>To popularise the landline services, BTCL&#39;s latest offer allowed its landline users to
                make unlimited local calls to the landline at a cost of Tk 150 per month. Besides, a BTCL
                landline user can also make calls to any mobile phone operator or any other operator at Tk 0.52
                per minute.</strong>
        },
        {
            title: 'What is GPON in BTCL?',
            content: <strong>Under the Gigabit Passive Optical Network (GPON) prepaid service, the BTCL has
                brought 11 internet packages from 5 mbps to 100 mbps ranging from Tk 500 to Tk 4,200.</strong>
        }
    ];
    return (
        <>
            <Meta title="BTCL Domain"
                description="This page provides information about BSTI permission and related processes."
                image={BtclImg}
                keywords="keyword1, keyword2, keyword3"
            />
            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">BTCL Domain Registration</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Intellectual Property</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">BTCL Domain Registration</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={BtclImg} alt='JK-Banner' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>A trusted .bd domain partners for Foreign client and company.
                            Dot com Dot Bd registration / renew fee 55 USD for two years.</b>
                    </p>
                    <p>
                        We are the authorized country domain bd domain name registration service provider of BTCL.
                        Jk associatews is the Internet country code top-level domain (ccTLD) for Bangladesh. It is
                        administered by BTCL (Bangladesh Telecommunications Company Limited). Registration is
                        available to anyone under the .com.bd, .net.bd, .org.bd, .gov.bd, .info.bd, .mail.bd, .ac.bd
                        and .edu.bd sub domains.
                    </p>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid" src={btclImg2} alt='BTCL-Domain-Registration' />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">BSTI Registration</p>
                                <p>
                                    <span>
                                        <strong>8 (eight) steps for obtaining Certification of Marks (CM) license.</strong>
                                    </span>
                                    <br />

                                    <span>
                                        Any person / organization from all over the world can apply for registration
                                        of BD domain and it takes one to two working days to active the domain upon
                                        submitting an order. DOT BD Domain can be registered at first for two years
                                        and subsequently renewable for further periods of as many years as you like.
                                        We support submitting an order for minimum 2 years. BD domain registration
                                        is very easy, require document is NID/Passport/trade license for Bangladeshi
                                        people.
                                    </span><br />
                                    <span>
                                        Jk associates is happy to supply domain name registration services for the
                                        Bangladeshi domain name (.BD). .COM.BD domain name presents a huge chance
                                        for local and global companies wanting to perform their company in
                                        Bangladesh. By listing a .com.bd domain name, your corporation can give
                                        relevant and localized information regarding your brand and services. It is
                                        a great opportunity to open a new marketplace in Bangladesh by registering
                                        your .BD domain name.
                                    </span><br />
                                    <p className="color-text fs-5 mb-4"> Domain registration policy and timeline</p>
                                    <span>
                                        1. You will send your BD domain order by email or our website. Then we will submit your
                                        application for domain registration. [1st day]
                                    </span><br />
                                    <span>
                                        2. Accepting of domain registration BTCL publish a Demand note. [2nd Day]
                                    </span><br />
                                    <span>
                                        3. After receiving the approval bill copy we make the payment at the bank
                                        and send a paid bill copy to the BTCL. Then BTCL active your domain within
                                        one day. [3rd Day]
                                    </span><br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={webdevelopImg}
                        title="Foreign Company Formation"
                        buttonText="Read More"
                        buttonLink="/web-development"
                    />
                    <MarqueeCard
                        imageUrl={trademarkImg}
                        title="Trademark"
                        buttonText="Read More"
                        buttonLink="/trademark"
                    />
                    <MarqueeCard
                        imageUrl={patentImg}
                        title="Patent"
                        buttonText="Read More"
                        buttonLink="/patent"
                    />
                    <MarqueeCard
                        imageUrl={iprImg}
                        title="IPR Enforcement"
                        buttonText="Read More"
                        buttonLink="/ipr-enforcement"
                    />
                    <MarqueeCard
                        imageUrl={industrialImg}
                        title="Industrial Design"
                        buttonText="Read More"
                        buttonLink="/industrial-design"
                    />
                    <MarqueeCard
                        imageUrl={copyrightImg}
                        title="Copyright"
                        buttonText="Read More"
                        buttonLink="/copyright"
                    />
                    <MarqueeCard
                        imageUrl={BtclImg}
                        title="BTCL Domain Registration"
                        buttonText="Read More"
                        buttonLink="/btcl-domain-registration"
                    />

                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">BTCL Domain FAQ</h2>
                <Faq items={faqItems} />
            </div>
            <Footer />
        </>
    )
}

export default BtclDomain