import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import Partnership from '../../assets/images/Partnership-.png'
import PartnershipRegistrationImg from '../../assets/images/Partnership-Registration-1.png'
import PartnershipRequiredDocumentsImg from '../../assets/images/Partnership-Required-Documents.png'
import BSTIPermissionImg from '../../assets/images/BSTI-permission.png'
import compnayFormationImg from "../../assets/images/Company-Formation.jpg"
import onePersionCompanyImg from "../../assets/images/One-man-Company-e1605617207411.png"
import societyRegistrationIMg from "../../assets/images/Society-Registration.png"
import partnershipImg from "../../assets/images/Partnership-.png"
import rjscImg from "../../assets/images/RJSC-Filling.png"
import  loanImg from "../../assets/images/Bank-loan.png"
import imprortImg from "../../assets/images/Import-Registration.png"
import exportImg from "../../assets/images/Export-Inmport-New.jpg"
import tradeImg from "../../assets/images/Trade-license-Registration.png"
import projectImg from "../../assets/images/Project-Profile-1.jpg"
import uddoktaImg from "../../assets/images/বিশাল-মূল্যছাড়.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'

function PartnershipRegistration() {

   const faqItems = [
      {
         title: 'How to register a partnership firm in Bangladesh?',
         content: <strong>A partnership may be registered with Registrar of Joint Stock Companies
         and Firm of Bangladesh (“RJSC”).</strong>
      },
      {
         title: 'What is the minimum and maximum members of partnership in Bangladesh?',
         content: <strong>There must be a minimum of 2 partners and maximum of 20 partners.</strong>
      },
      {
         title: 'Is a partnership agreement required?',
         content: <strong>YES</strong>
      }
   ];

   return (
      <>
         <Meta title="Partnership Registration"
               description="This page provides information about BSTI permission and related processes."
               image={partnershipImg}
               keywords="keyword1, keyword2, keyword3"
         />
         
         <NavBar />

         <div className="container-fluid hero-header bg-light py-5 ">
            <div className="container py-5">
               <div className="row g-5 align-items-center">
                  <div className="col-lg-6">
                     <h1 className="display-4 mb-3 animated slideInDown">Partnership Registration</h1>
                     <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item"><Link to="#">Startup</Link></li>
                           <li className="breadcrumb-item active" aria-current="page">Partnership Registration</li>
                        </ol>
                     </nav>
                  </div>
                  <div className="col-lg-6 animated fadeIn">
                     <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={Partnership} alt='Partnership' />
                  </div>
               </div>
            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <p className="color-text fs-5 mb-4">
                  <b>PARTNERSHIP BUSINESS</b>
               </p>
               <p>
                  What is called a partnership business deed ? Partnership deed is a written legal document
                  that contains an agreement made between two or more individuals who have the intention of
                  doing business with each other and share profits and losses. It is also called a partnership
                  agreement.
               </p>
               <p>
                  A Partnership is defined by the Partnership Act, 1932, (the “Partnership Act”) as ‘the
                  relation between persons who have agreed to share profits of the business carried on by all
                  or any of them acting for all’. This definition gives three minimum requirements to
                  constitute a partnership:
               </p>
            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <div className="row g-5 align-items-center">
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                     <img className="img-fluid" src={PartnershipRegistrationImg} alt='Partnership-Registration-1' />
                  </div>
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                     <div className="h-100">
                        <p>
                           There must be an agreement entered into orally or in writing by the persons who
                           desire to form a partnership,the object of the agreement must be to share the
                           profits of business intended to be carried on by the partnership, and The
                           business must be carried on by all the partners or by any of them acting for
                           all of them. The term ‘person’ is not defined by the Partnership Act.<br /><br />
                           It is not compulsory to register your partnership firm as there are no penalties
                           for non-registration. However, it is advisable since the following rights are
                           denied to an unregistered firm:<br /><br />
                           A partner cannot file a suit in any court against the firm or other partners for
                           the enforcement of any right arising from a contract or right conferred by the
                           Partnership Act<br /><br />
                           A right arising from a contract cannot be enforced in any Court by or on behalf
                           of your firm against any third party<br /><br />
                           Further, the firm or any of its partners cannot claim a set-off (i.e. mutual
                           adjustment of debts owed by the disputant parties to one another) or other
                           proceedings in a dispute with a third party.
                        </p>
                        <p className="color-text fs-5 mb-4">PARTNERSHIP BUSINESS QUICK FACTS<br /><br />LIABILITY</p>
                        <p>
                           A partnership is considered as a separate legal identity (i.e. separate from its
                           owners) in Bangladesh only if the partnership is registered. All the partners of
                           a partnership are liable severally and jointly for the liability of the
                           partnership. The concept of Limited Liability Partnership does not exist in
                           Bangladesh.
                        </p>
                        <p className="color-text fs-5 mb-4">TAXATION</p>
                        <p>
                           From a tax perspective, partnerships in Bangladesh are not taxed at the entity
                           level and profits are treated as part of each partners’ personal income and are
                           taxed at personal income tax rates.
                        </p>
                        <p className="color-text fs-5 mb-4">MEMBERS & MANAGEMENT</p>
                        <p>
                           There must be a minimum of 2 partners and maximum of 20 partners. The partners
                           can be natural persons or companies. Unlike private or public limited companies,
                           a partnership in Bangladesh does not have directors, shareholder or secretary,
                           instead the partners own and run the business.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <p className="color-text fs-5 mb-4">
                  <b>PARTNERSHIP DOCUMENTS REQUIRED</b>
               </p>
               <p>
                  In order to register a partnership in Bangladesh, the following information/documents are
                  needed:
               </p>
               <p>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Proposed partnership name;
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Partnership agreement duly notarized;
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Form I;
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Particulars of the partners;.
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Residential address of the partners;
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Details of the registered address for the partnership; and
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Percentage of the share of profit of each partner
                     </span>
                  </div>
               </p>
               <p className="color-text fs-5 mb-4">
                  <b>PARTNERSHIP REGISTRATION PROCEDURE</b>
               </p>
               <p>
                  A partnership may be registered with Registrar of Joint Stock Companies and Firm of
                  Bangladesh (“RJSC”).<br /><br />
                  The partnership registration process consists of two steps: a) name reservation; and b)
                  registration of the entity. Under normal circumstances, a partnership registration can be
                  completed within one/two days.
               </p>
            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <div className="row g-5 align-items-center">
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                     <div className="h-100">
                        <p className="color-text fs-5 mb-4">STEP 1- CHOOSING THE PARTNERSHIP NAME</p>
                        <p>
                           <p>
                              The partners are free to choose any name as they desire for their partnership
                              firm subject to the following rules:-
                           </p>
                           <p>
                              1. The names must not be too identical or similar to the name of another existing
                              firm doing similar business so as to lead to confusion. The reason for this rule
                              being that the reputation or goodwill of a firm may be injured, if a new firm
                              could adopt an allied name.<br /><br />
                              2. The name must not contain certain words expressing or implying the sanction,
                              approval or patronage of Govt. Once you select a name, you should apply for name
                              clearance using the website of RJSC.
                           </p>
                           <p className="color-text fs-5 mb-4">STEP 2 – PREPARE A PARTNERSHIP BUSINESS DEED</p>
                           <p>
                              You should prepare a partnership deed. Usually, a partnership deed contains the following
                              clauses:
                           </p>
                           <div className="d-flex align-items-center mb-4">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Name and Address of the firm as well as all the partners;
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-4">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Nature of business to be carried on;
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-4">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Date of Commencement of business;
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-4">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Duration of Partnership (whether for a fixed period/indefinite time);
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-4">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Capital contribution by each partner;
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-4">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Profit-sharing ratio among the partners;
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-4">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Rules to be followed in case of retirement, death and admission of a partner; and
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-4">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 The above are the minimum essentials which are required in all partnership
                                 deeds. The partners may also mention any additional clauses.
                              </span>
                           </div>
                           The Partnership Deed should be on a stamp paper in accordance with the Stamp Act
                           and should be signed by all partners. Then it should be notarized.
                        </p>
                        <p className="color-text fs-5 mb-4">STEP 3- REGISTER PARTNERSHIP DEED WITH RJSC</p>
                        <p>
                           The partnership deed and filled up Form I should be filed with RJSC. These
                           documents will be reviewed by the officials of the RJSC. When the officials are
                           satisfied with the points stated in the partnership deed, he shall record an entry
                           of the statement in a register called the Register of Firms and issue a
                           Certificate of Registration.<br /><br />
                           Various types of business exist in Bangladesh. One of them is Partnership Firm and
                           others are Proprietorship and Limited Company. A Partnership Firm is formed under
                           the provision of the Partnership Act 1932. RJSC (The Registrar of Joint Stock
                           Companies and Firms) is the sole authority to Register Partnership Firm in
                           Bangladesh. For Partnership Registration in Bangladesh we should follow some steps
                           those will discuss at the end of this article.<br /><br />
                           Especially similar minded or similar professionals two or more people when a
                           business start and create a voluntary agreement with the intention of making and
                           sharing profit among themselves that is called Partnership Business. For form a
                           Partnership Business minimum two and maximum twenty people can include.<br /><br />
                           Now days for opening Bank Account for Partnership Business, Partnership
                           Registration from RJSC is essential. For this many of the unregistered Partnership
                           Business owner going to register their Partnership Firm from RJSC. On the other
                           hand to get some legal facilities Partnership Business Registration from RJSC is
                           mandatory.
                        </p>
                     </div>
                  </div>
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                     <img className="img-fluid" src={PartnershipRequiredDocumentsImg} alt='Partnership-Required-Documents' />
                  </div>
               </div>
            </div>
         </div>

         <div className="container-xxl py-0">
            <div className="container">
               <p className="color-text fs-5 mb-4">
                  <b>Partnership Agreement:</b>
               </p>
               <p>
                  Partnership Agreement is well known as the Partnership Deed. Usually, following terms and
                  conditions are including in a Partnership Deed:
               </p>
               <p>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Name, Present Address, Permanent Address of the Partner.
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Name of the Partnership Firm
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Nature of Business
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Business Addressp
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Duration of Partnership
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Share of profit taken by each Partner
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Amount of capital contribution of each partner
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Terms of Retirement
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Mode of Management
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Interest of Borrowings
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Dissolution of Partnershi
                     </span>
                  </div>
               </p>
               <p className="color-text fs-5 mb-4">
                  <b>Steps of Partnership Registration in Bangladesh:</b>
               </p>
               <p>
                  A partnership firm can be registered at the time of creation or even subsequently. An
                  application should file to the RJSC where following information should include:
               </p>
               <p>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Name Clearance Certificate of the Partnership Firm
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Date of Establishment of the Partnership Firm
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Duration of the Partnership Firm
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Principal place of business with full Address
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Joining Date of Partners
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Particulars of Partners
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Particulars of Business
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        The application must be submitted on the prescribed Form- I
                     </span>
                  </div>
                  We are suggesting you register your existing Partnership Firm immediately for avoiding
                  unexpected circumstances. Also if you start a new partnership firm you must be Registration
                  at RJSC in Bangladesh. Please check the details about more RJSC.
               </p>

               <p className="color-text fs-5 mb-4">
                  <b>Benefits of Partnership Business:</b>
               </p>
               <p>
                  For the nature of business start-up capital funds by the partners, so the more partners, the
                  more capital they can invest in their business, which will permit more flexibility and more
                  potential for progress.<br /><br />

                  A partnership firm is usually easier to register, run, and manage. For partnership firms,
                  Rules and Regulations are not strictly regulated than companies. As long as all the partners
                  can agree, they run their business as they like.<br /><br />

                  Every partner brings new ideas and skills to a business.  Easier to make a decision for
                  solving a problem because of more brains to think about it. Every partner can share their
                  duties and responsibilities for the business.<br /><br />

                  After completing Partnership Registration in Bangladesh to start operation your business you
                  should get the following documents:
               </p>
               <p>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Trade License
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        TIN Certificate for your newly Registered Partnership Firm.
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Bank Account
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        VAT Registration Certificate
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Import Registration Certificate (In case of Import Business))
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Export Registration Certificate (In case of Export Business
                     </span>
                  </div>
               </p>
            </div>
         </div>

         <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>
            
            <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
                <MarqueeCard
                    imageUrl={uddoktaImg}
                    title="Startup Packages"
                    buttonText="Read More"
                    buttonLink="/startup-packages"
                />
                <MarqueeCard
                    imageUrl={projectImg}
                    title="Project Profile"
                    buttonText="Read More"
                    buttonLink="/project-profile"
                />
                <MarqueeCard
                    imageUrl={tradeImg}
                    title="Trade License"
                    buttonText="Read More"
                    buttonLink="/trade-license"
                />
                <MarqueeCard
                    imageUrl={exportImg}
                    title="Export License"
                    buttonText="Read More"
                    buttonLink="/export-license"
                />
                <MarqueeCard
                    imageUrl={imprortImg}
                    title="Import License"
                    buttonText="Read More"
                    buttonLink="/import-license"
                />
                <MarqueeCard
                    imageUrl={loanImg}
                    title="Loan Consultancy"
                    buttonText="Read More"
                    buttonLink="/loan-consultancy"
                />
                <MarqueeCard
                    imageUrl={rjscImg}
                    title="RJSC Return Filling"
                    buttonText="Read More"
                    buttonLink="/rjsc-return-filing"
                />
                 <MarqueeCard
                    imageUrl={partnershipImg}
                    title="Partnership Registration"
                    buttonText="Read More"
                    buttonLink="/partnership-registration"
                />
                 <MarqueeCard
                    imageUrl={societyRegistrationIMg}
                    title="Society Registration"
                    buttonText="Read More"
                    buttonLink="/society-registration"
                />
                 <MarqueeCard
                    imageUrl={onePersionCompanyImg}
                    title="One Permission Company"
                    buttonText="Read More"
                    buttonLink="/one-person-company"
                />
                 <MarqueeCard
                    imageUrl={compnayFormationImg}
                    title="Company Formation"
                    buttonText="Read More"
                    buttonLink="/company-formation"
                />
                 <MarqueeCard
                    imageUrl={BSTIPermissionImg}
                    title="BSTI Permission"
                    buttonText="Read More"
                    buttonLink="/btcl-domain-registration"
                />
               
            </Marquee>
            </div>

         <div className="container py-5 my-0">
            <h2 className="color-text text-center mb-4">Partnership Registration FAQ</h2>
            <Faq items={faqItems} />
         </div>

         <Footer />
      </>
   )
}

export default PartnershipRegistration