import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import RegulatoryComplience from '../assets/images/Regulatory_Complience.png'
import { FaStar } from "react-icons/fa6";

function Header() {

  const [entity, setEntity] = useState('');
  const [state, setState] = useState('');

  const handleEntityChange = (event) => {
    setEntity(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  return (
    <>
      <div className="container-fluid hero-header bg-light py-5 mb-5">
        <div className="container pt-5">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <Link target='_blank' to="https://www.google.com/maps/place/JK+Associates+%7C+Income+Tax+%7C+VAT+%7C+Company+Registration+%7C+Copyright+%7C+Trademark/@23.7927683,90.374504,17z/data=!4m8!3m7!1s0x3755b8bf5300eaad:0xe50b10a249f6394e!8m2!3d23.7927683!4d90.374504!9m1!1b1!16s%2Fg%2F11c54h2yxw?entry=ttu">
                <span className='home-top bg-secondary opacity-75 p-2'>
                  Excellent 5 out of 5&nbsp;
                  <FaStar style={{ color: "yellow", marginBottom: "5px" }} /> Google
                </span>
              </Link>
              <h1 className="display-4 mt-4 mb-3 animated slideInDown float-up">Make Your Business With Us</h1>
              <p className="animated slideInDown" style={{ textAlign: 'justify' }}><b>"JK Associates"</b> is top-ranked Corporate Consultancy and Law
                firm in Bangladesh with the international presence provides specialized services in Intellectual
                Property Rights, Corporate Affairs, Foreign Trade and Investment, Taxation, and Litigation.</p>

              <div className="mb-3 d-flex align-items-center justify-content-center dropdown-11">
                <label htmlFor="formFile" className="form-label me-5 mb-0">Entity</label>
                <select className="form-select mb-0" aria-label="Default select example" value={entity} onChange={handleEntityChange}>
                  <option value="">Select Entity</option>
                  <option value="Limited / Ltd.">Limited / Ltd.</option>
                  <option value="One Person Company (OPC)">One Person Company (OPC)</option>
                  <option value="Society Registration">Society Registration</option>
                  <option value="Partnership Firm Registration">Partnership Firm Registration</option>
                </select>
              </div>

              <div className="mb-3 d-flex align-items-center justify-content-center dropdown-11">
                <label htmlFor="formFile" className="form-label me-5 mb-0">Division</label>
                <select className="form-select mb-0" aria-label="Default select example" value={state} onChange={handleStateChange}>
                  <option value="">Select Division</option>
                  <option value="Dhaka">Dhaka</option>
                  <option value="Chittagong">Chittagong</option>
                  <option value="Khulna">Khulna</option>
                  <option value="Rajshahi">Rajshahi</option>
                </select>
              </div>

              {(entity && state) ? (
                <Link to='/get-started-page' className="btn btn-primary py-2 px-4 animated slideInDown">Get Started</Link>
              ) : (
                <button className="btn btn-disabled py-2 px-4 animated slideInDown" disabled></button>
              )}
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={RegulatoryComplience} alt="hero-1" />
              {/* <img className="centered-img mt-5 img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={ceo} alt="hero-1" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header