import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo-120x120.png";

function OfferedPackages() {
    const [cardSet1Visible, setCardSet1Visible] = useState(true);
    const [cardSet2Visible, setCardSet2Visible] = useState(false);
    const [cardSet3Visible, setCardSet3Visible] = useState(false);
    const [cardSet4Visible, setCardSet4Visible] = useState(false);
    const [cardSet5Visible, setCardSet5Visible] = useState(false);

    const toggleCards = (cardSetId) => {
        setCardSet1Visible(cardSetId === 'cardSet1');
        setCardSet2Visible(cardSetId === 'cardSet2');
        setCardSet3Visible(cardSetId === 'cardSet3');
        setCardSet4Visible(cardSetId === 'cardSet4');
        setCardSet5Visible(cardSetId === 'cardSet5');
    };
    return (
        <>

            <div className="button-container mt-4 mb-4">

                <button id="button2" className={`button12 ${cardSet2Visible ? 'active' : ''}`} onClick={() => toggleCards('cardSet2')}>USA</button>
                <button id="button3" className={`button12 ${cardSet3Visible ? 'active' : ''}`} onClick={() => toggleCards('cardSet3')}>UK</button>
                <button id="button1" className={`button12 ${cardSet1Visible ? 'active' : ''}`} onClick={() => toggleCards('cardSet1')}>Bangladesh</button>
                <button id="button4" className={`button12 ${cardSet4Visible ? 'active' : ''}`} onClick={() => toggleCards('cardSet4')}>DUBAI</button>
                <button id="button5" className={`button12 ${cardSet5Visible ? 'active' : ''}`} onClick={() => toggleCards('cardSet5')}>SINGAPORE</button>

            </div>
            <div className="container-xxl" id="cardSet1" hidden={!cardSet1Visible}>
                <div className="flex-container12">
                    {/* Card 1 */}
                    <div className="flex-item12">
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP BASIC</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*EMI Facilities</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (10 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 2 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className="flex-item12" style={{ backgroundColor: "#6AA9D8", color: "#E5E7E9", borderRadius: "10px" }}>

                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP EXCLUSIVE</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*EMI Facilities</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (50 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className="flex-item12" style={{ backgroundColor: "#32a0b5", color: "#E5E7E9", borderRadius: "10px" }}>
                        <div className="card-content ">
                            <h5 className="card-title text-center">STARTUP PREMIUM</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*EMI Facilities</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (1 core Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                                <li>Domain & Hosting (1 year)</li>
                                <li>Company Website</li>
                                <li>Android Apps</li>
                                <li>Company Logo</li>
                                <li>Social Media Setup (Digital Marketing)</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link >
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl" id="cardSet2" hidden={!cardSet2Visible}>
                <div className="flex-container12">
                    {/* Card 1 */}
                    <div className="flex-item12">
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP BASIC</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*USA</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (10 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 2 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className="flex-item12" style={{ backgroundColor: "#6AA9D8", color: "#E5E7E9", borderRadius: "10px" }}>

                        <div className="card-content">
                            <h5 className="card-title  text-center">STARTUP EXCLUSIVE</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*USA</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (50 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className="flex-item12" style={{ backgroundColor: "#32a0b5", color: "#E5E7E9", borderRadius: "10px" }}>
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP PREMIUM</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*USA</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (1 core Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                                <li>Domain & Hosting (1 year)</li>
                                <li>Company Website</li>
                                <li>Android Apps</li>
                                <li>Company Logo</li>
                                <li>Social Media Setup (Digital Marketing)</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link >
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl" id="cardSet3" hidden={!cardSet3Visible}>
                <div className="flex-container12">
                    {/* Card 1 */}
                    <div className="flex-item12">
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP BASIC</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*UK</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (10 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 2 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className="flex-item12" style={{ backgroundColor: "#6AA9D8", color: "#E5E7E9", borderRadius: "10px" }}>

                        <div className="card-content ">
                            <h5 className="card-title text-center">STARTUP EXCLUSIVE</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*UK</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (50 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className="flex-item12" style={{ backgroundColor: "#32a0b5", color: "#E5E7E9", borderRadius: "10px" }}>
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP PREMIUM</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*UK</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (1 core Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                                <li>Domain & Hosting (1 year)</li>
                                <li>Company Website</li>
                                <li>Android Apps</li>
                                <li>Company Logo</li>
                                <li>Social Media Setup (Digital Marketing)</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link >
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl" id="cardSet4" hidden={!cardSet4Visible}>
                <div className="flex-container12">
                    {/* Card 1 */}
                    <div className="flex-item12">
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP BASIC</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*DUBAI</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (10 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 2 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 2 */}

                    <div className="flex-item12" style={{ backgroundColor: "#6AA9D8", color: "#E5E7E9", borderRadius: "10px" }}>
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP EXCLUSIVE</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*DUBAI</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (50 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 3 */}

                    <div className="flex-item12" style={{ backgroundColor: "#32a0b5", color: "#E5E7E9", borderRadius: "10px" }}>
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP PREMIUM</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*DUBAI</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (1 core Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                                <li>Domain & Hosting (1 year)</li>
                                <li>Company Website</li>
                                <li>Android Apps</li>
                                <li>Company Logo</li>
                                <li>Social Media Setup (Digital Marketing)</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link >
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl" id="cardSet5" hidden={!cardSet5Visible}>
                <div className="flex-container12">
                    {/* Card 1 */}
                    <div className="flex-item12">
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP BASIC</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*SINGAPORE</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (10 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 2 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className="flex-item12" style={{ backgroundColor: "#6AA9D8", color: "#E5E7E9", borderRadius: "10px" }}>

                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP EXCLUSIVE</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*SINGAPORE</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (50 Lac Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link>

                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className="flex-item12" style={{ backgroundColor: "#32a0b5", color: "#E5E7E9", borderRadius: "10px" }}>
                        <div className="card-content">
                            <h5 className="card-title text-center">STARTUP PREMIUM</h5>
                            <img style={{ display: "block", margin: "auto" }} src={logo} width={80} alt='logo' />
                            <p className="card-text">*SINGAPORE</p>
                            <ul>
                                <li>Business Consultation</li>
                                <li>Document Preparation</li>
                                <li>Limited Company Registration (1 core Authorized Capital)</li>
                                <li>Company TIN Registration</li>
                                <li>Trade License (Up to 10 Lac Paid up)</li>
                                <li>Bank Account Opening Assistance</li>
                                <li>Company Stamp</li>
                                <li>BIN Registration</li>
                                <li>Trademark First Application</li>
                                <li>Copyright Registration</li>
                                <li>One Consultation with certified TAX Expert</li>
                                <li>Domain & Hosting (1 year)</li>
                                <li>Company Website</li>
                                <li>Android Apps</li>
                                <li>Company Logo</li>
                                <li>Social Media Setup (Digital Marketing)</li>
                            </ul>
                            <p style={{ color: '#8b0000', textAlign: 'end' }}>*Terms & condition apply</p>
                            <Link to="/contact-us" className="getqot btn btn-primary">Get Quotation</Link >
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default OfferedPackages