import React from 'react'
import HomeSlideShare from '../components/HomeSlideShare'
import HomeCard from '../components/HomeCard'
import { Link } from 'react-router-dom'
import { FaHourglassStart } from "react-icons/fa6";
import { TbListDetails } from "react-icons/tb";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { IoDocuments } from "react-icons/io5";
import VATCalculator from '../components/VATCalculator'
import Faq from '../components/Faq';
import BangldeshiCompany from '../assets/images/Bangldeshi_Company.png'
import EMIIMSGE from '../assets/images/EMI-IMSGE-1536x407.png'
import Howtoopenacompany from '../assets/images/How-to-open-a-company-1536x437.png'
import OfferedPackages from '../components/OfferedPackages';
import { FaHandshakeAngle } from "react-icons/fa6";
import { FaPeopleGroup } from "react-icons/fa6";
import { GiProgression } from "react-icons/gi";
import GoogleReviews from '../components/GoogleReviews';

function HomeScreen() {

  const faqItems = [
    {
      title: 'Accordion Item #1',
      content: <strong>Home Formation</strong>
    },
    {
      title: 'Accordion Item #2',
      content: <strong>This is the second item's accordion body.</strong>
    },
    {
      title: 'Accordion Item #3',
      content: <strong>This is the third item's accordion body.</strong>
    }
  ];

  return (
    <>
      <div className="container-xxl py-0">
        <div className="container">
          <HomeSlideShare />
        </div>
      </div>

      <hr style={{ width: '86.7%', margin: '0 auto', border: '3px solid blue', borderRadius: '5px' }} />

      <div className="container-xxl  center-background1">
        <h2>Business Setup Packages</h2>
      </div>

      <OfferedPackages />

      <div className="container-xxl">
        <div>
          <VATCalculator />
        </div>
      </div>

      <div className='container-xxl text-center'>
        <h1>Where Global Entrepreneurs Thrive</h1>
        <p className="fs-5 text-center">We make launching your Bangladesh business easy, fast, and effective</p>
        <button className='btn btn-primary fs-3 button-2'>Get Started</button>
      </div>

      <div className="container-xxl">
        <HomeCard />
      </div>

      <div className="container-xxl py-4 mb-4 homecardBoxShadow">
        <Link to='/contact-us'>
          <img src={BangldeshiCompany} alt='Bangldeshi_Company' width='100%' height='auto' />
        </Link>
      </div>

      <div className="container-xxl py-4 homecardBoxShadow">
        <img src={EMIIMSGE} alt='EMI-IMSGE-1536x407' width='100%' height='auto' />
      </div>

      <div className="container-xxl  center-background">
        <img src={Howtoopenacompany} alt='How-to-open-a-company' width='100%' height='auto' />
      </div>

      <div className="container-xxl  center-background">
        <div className="flex-container1">
          {/* Card 1 */}
          <div className="flex-item1">
            <div className="card-content">
              <h3 style={{ color: '#1e73be' }}>Step-1</h3>
              <FaHourglassStart style={{ fontSize: '40px', color: '#239B56' }} />
              <p style={{ color: '#1e73be', fontWeight: '700', fontSize: '20px', textAlign: 'center' }}>Preparation</p>
              <p>
                Request free company namesearch we check the eligibility of the name, and make suggestion if
                necessary.
              </p>

            </div>
          </div>

          {/* Card 2 */}
          <div className="flex-item1">

            <div className="card-content">
              <h3 style={{ color: '#1e73be' }}>Step 2</h3>
              <TbListDetails style={{ fontSize: '40px', color: '#239B56' }} />
              <p style={{ color: '#1e73be', fontWeight: '700', fontSize: '20px', textAlign: 'center' }}>Your Bangladeshi company Details</p>
              <p>
                <ul>
                  <li>Register or login and fill in the company names and director/shareholder(s).</li>
                  <li>Fill in company address oe special request(if any).</li>
                </ul>
              </p>

            </div>
          </div>

          {/* Card 3 */}
          <div className="flex-item1">
            <div className="card-content">
              <h3 style={{ color: '#1e73be' }}>Step 3</h3>
              <FaFileInvoiceDollar style={{ fontSize: '40px', color: '#239B56' }} />
              <p style={{ color: '#1e73be', fontWeight: '700', fontSize: '20px', textAlign: 'center' }}>Payment for your favourite Bangladeshi comapny</p>
              <p>
                Choose your payment method (We accept payment by Credit/Debit Card, Bank Transfer, Cash on Delivery)
              </p>

            </div>
          </div>

          {/* Card 4 */}
          <div className="flex-item1">
            <div className="card-content">
              <h3 style={{ color: '#1e73be' }}>Step 4</h3>
              <IoDocuments style={{ fontSize: '40px', color: '#239B56' }} />
              <p style={{ color: '#1e73be', fontWeight: '700', fontSize: '20px', textAlign: 'center' }}>Send the company kit to your address</p>
              <p>
                <ul>
                  <li>You will receive soft copies of necessary documents including: Certificate of Incorporation,
                    Memorandum and Articles of Association, Form XII etc.Then your new company in a jurisdiction
                    is ready to do business.
                  </li>
                  <li>
                    You can brigng the documents in company kit to open corporate bank account or we can help you with
                    our long experience of Banking support service.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container-xxl center-background d-flex flex-wrap justify-content-around mt-4' style={{ backgroundColor: "#e4f6fa" }}>
        <div className='col-lg-4 col-md-6 col-sm-12 d-flex flex-column align-items-center p-5'>
          <div style={{ borderRadius: '50%', border: '5px solid #4caf50', padding: '20px' }}>
            <FaHandshakeAngle style={{ fontSize: '10rem', color: '#4caf50' }} />
          </div>
          <div>
            <h2 className='mt-3' style={{ color: '#4caf50' }}>200+</h2>
          </div>
          <div>
            <h2 style={{ color: '#4caf50' }}>
              Global Clients
            </h2>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 d-flex flex-column align-items-center p-5'>
          <div style={{ borderRadius: '50%', border: '5px solid #4caf50', padding: '20px' }}>
            <FaPeopleGroup style={{ fontSize: '10rem', color: '#4caf50' }} />
          </div>
          <div>
            <h2 className='mt-3' style={{ color: '#4caf50' }}>10+</h2>
          </div>
          <div>
            <h2 style={{ color: '#4caf50' }}>
              Specialists
            </h2>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 d-flex flex-column align-items-center p-5'>
          <div style={{ borderRadius: '50%', border: '5px solid #4caf50', padding: '20px' }}>
            <GiProgression style={{ fontSize: '10rem', color: '#4caf50' }} />
          </div>
          <div>
            <h2 className='mt-3' style={{ color: '#4caf50' }}>6+</h2>
          </div>
          <div>
            <h2 style={{ color: '#4caf50' }}>
              Year of experience
            </h2>
          </div>
        </div>
      </div>

      {/* <GoogleReviews /> */}

      <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">Frequently Asked Questions</h2>
        <Faq items={faqItems} />
      </div>
    </>
  )
}

export default HomeScreen