import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Faq from '../../components/Faq'
import Meta from '../../components/Meta'
import BtclImg from "../../assets/images/JK-Banner.png"
import copyrightImg from "../../assets/images/copyRight-Registration-2.png"
import industrialImg from "../../assets/images/Industrial-Design.png"
import iprImg from "../../assets/images/Intellectual-property-rights-Bangladesh.png"
import patentImg from "../../assets/images/Patent-registration.png"
import trademarkImg from "../../assets/images/Trade-license-Registration.png"
import webdevelopImg from "../../assets/images/web-development-1.jpg"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import iprImg2 from "../../assets/images/Intelectual-Property-rights-process.png"

function IprEnforcement() {
   const faqItems = [
      {
         title: 'What are the three pillars of IPR?',
         content: <strong>Intellectual property a complex world, and it&#39;s constantly under attack. Today, Brian
            Flaherty breaks down the four pillars of IP ownership: trademarks, trade secrets, patents, and
            (the big story right now), copyrights.</strong>
      },
      {
         title: 'What is the IPR in international law?',
         content: <strong>Second, intellectual property laws recognize as intangible property the rights arising
            from the efforts of human endeavor. A trademark is a sign (such as a word or symbol) that
            indicates the trade origin of particular goods or services. It provides a connection in the course
            of trade between the trademark and a trader.</strong>
      },
      {
         title: 'What is the time limit for IPR?',
         content: <strong>It is an exclusive right that protects the rights of the inventor and prevents other people
            to unauthorizedly use and misappropriate the registered patent. A patent is granted for a term
            of 20 (twenty) years from the date of filling of the application.</strong>
      }
   ];
   return (
      <>
         <Meta title="IPR Enforcement"
            description="This page provides information about BSTI permission and related processes."
            image={iprImg}
            keywords="keyword1, keyword2, keyword3"
         />
         <NavBar />

         <div className="container-fluid hero-header bg-light py-5 mb-5">
            <div className="container py-5">
               <div className="row g-5 align-items-center">
                  <div className="col-lg-6">
                     <h1 className="display-4 mb-3 animated slideInDown">IPR Enforcement</h1>
                     <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item"><Link to="#">Intellectual Property</Link></li>
                           <li className="breadcrumb-item active" aria-current="page">IPR Enforcement</li>
                        </ol>
                     </nav>
                  </div>
                  <div className="col-lg-6 animated fadeIn">
                     <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={iprImg} alt='Intellectual-property-rights-Bangladesh' />
                  </div>
               </div>
            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <p className="color-text fs-5 mb-4">
                  <b>Intellectual Property Rights</b>
               </p>
               <p>
                  To respect intellectual property rights (IPR Enforcement), Bangladesh has enacted intellectual
                  property laws. It has incorporated the relevant provisions from international standards in
                  this regard.<br /><br />

                  Bangladesh has enacted the following laws on IPR Enforcement:<br /><br />
               </p>
               <p>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Copyright Act, 2000 (amended in 2005)
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Patent and Design Act, 1911
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Trademarks Act, 2009
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Geographical Indication (Registration and Protection) Act, 2013
                     </span>
                  </div>
               </p>

            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <div className="row g-5 align-items-center">
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                     <img className="img-fluid" src={iprImg2} alt='Intelectual-Property-rights-process' />
                  </div>
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                     <div className="h-100">
                        <p>
                           <b>The Patents and Designs Act, 1911: </b>Under this act, the Department of
                           Patents, Design, and Trademark (DPDT) provides patent protection (registration)
                           to the patent holders for 16 years on payment of prescribed fees. The duration of
                           protection may be renewed for a further period. A patent confers on the patentee
                           the exclusive privilege of making, selling, and using the invention throughout
                           Bangladesh and of authorizing others to do so. For details and updated
                           information on the protection of patents and design, visit www.dpdt.gov.bd<br /><br />
                           <b>Trade Marks:</b> Under the Trade Marks Act, 2009, protection is granted for
                           seven (7) years and it can be renewed after every expiry for further ten (10)
                           years on payment of renewal fees.<br /><br />

                           For details and updated information on Trademarks, visit www.dpdt.gov.bd<br /><br />

                           <b>Copyright: </b>The Copyrights Act, 2000 (amended in 2005) provides protection
                           to authors, artists or dramatists. Copyright law protects only the form of
                           expression of ideas, not the ideas themselves. In order to get a copyright,
                           the owner has to show that the work is original. For details on Copyright
                           protection in Bangladesh, visit www.copyrightoffice.gov.bd<br /><br />

                           <b>Industrial Design/ Design:</b> Under the Patents and Designs Act, 1911, any
                           person claiming to be the proprietor of any new or original design not previously
                           published in Bangladesh may register the design. The registered proprietor of the
                           design shall have copyright in the design for five (5) years from the date of
                           registration. For details and updated information on Patents and Designs, visit
                           www.dpdt.gov.bd<br /><br />

                           <b>Geographical Indication: </b>Bangladesh has enacted ‘The Geographical
                           Indication of Goods (Registration and Protection) Act, 2013’. In order to
                           implement the Act, the Geographical Indication of Goods (Registration and
                           Protection) Rules, 2015 has been enacted Registration of GI goods shall remain
                           valid for an indefinite period unless it is rescinded or otherwise declared
                           void.<br /><br />

                           In the GI Act there are also provisions for producers/gatherers/manufacturers/
                           processors of GI products to be registered as authorized users of these products.
                           The duration of registration of authorized users of GI goods will be five (5)
                           years and is renewable for a further period of 3 (three) years. For details and
                           updated information on Geographical Indications of Goods, visit www.dpdt.gov.bd.
                        </p>

                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="container-xxl py-0">
            <div className="container">
               <p className="color-text fs-5 mb-4">
                  <b>Bangladesh and Trade-related Intellectual Property Rights (IPR Enforcement)</b>
               </p>
               <p>
                  The Agreement on Trade-Related Aspects of Intellectual Property Rights (TRIPS) is administered
                  by the World Trade Organization (WTO).Its aim is to ensure protection of IP rights of
                  intellectual property owners from the WTO member countries. The areas of intellectual property
                  that are covered under the TRIPS Agreement are:
               </p>
               <p>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        <b>Patents</b> including the protection of new varieties of plants.
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        <b>copyright</b>  and related rights (i.e. the rights of performers, producers of sound
                        recordings and broadcasting organizations);
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        <b>trademarks</b> including service marks;
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        <b>geographical</b> indications including appellations of origin;
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        <b>industrial designs;</b>
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        <b>the layout-designs of integrated circuits</b> and
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        <b> Undisclosed information </b>including trade secrets and test data
                     </span>
                  </div>
                  Although the obligations under TRIPS apply equally to all member states, however developing
                  countries and least developed countries (LDCs) were allowed extra time to implement the
                  applicable changes to their national laws. The transition period for developing countries
                  expired in 2005. But the transition period for LDCs to implement TRIPS in general was
                  extended to 2021, and for pharmaceutical patents the same has been extended until 1 January
                  2033.
               </p>
               <p className="color-text fs-5 mb-4">
                  <b>Bangladesh Customs’ role in the enforcement of TRIPS Agreement provisions</b>
               </p>
               <p>
                  The National Board of Revenue has taken steps to incorporate necessary provisions under its
                  Customs legislation. For example, Section 15 under Chapter IV (Prohibition and Restriction of
                  Importation and Exportation) of the Customs Act, 1969 provides the enforcement mechanism to
                  deal with the infringement of TRIPS provisions in relation to import and exportation. Such
                  measures, as provided for under Section 15, are highlighted below:
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Goods having applied thereto a counterfeit trademark within the meaning of the Penal
                        Code, 1860, or a false trade description within the meaning of the Trademarks Act, 2009;
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Goods made or produced outside Bangladesh and having applied thereto any name or
                        trademark, being or purporting to be the name or trademark of any manufacturer, dealer
                        or trader in Bangladesh unless-
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        The name or trademark is, as to every application thereof, accompanied by a definite
                        indication of the goods having been made or produced in a place outside Bangladesh and
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        The country in which that place is situated is in that indication shown in letters as
                        large and conspicuous as any letter in the name or trademark, and in the same language
                        and character as the name or trademark
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Goods made or produced outside Bangladesh and intended for sale, and having applied
                        thereto, a design in which copyright exists under the Patents And Designs Act, 1911
                        and in respect of the class to which the goods belong and any fraudulent or obvious
                        imitation of such design except when the application of such design has been made
                        with the license or written consent of the registered proprietor of the design; and
                        IPR Enforcement
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Goods or items produced outside Bangladesh involving infringement of Copyright Act,
                        2000 or infringement of layout design of integrated circuit that are intended for sale
                        or use for commercial purposes within the territory of Bangladesh.
                     </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                     <span>
                        Section 17 of the Act provides for detention and confiscation of goods imported or exported in breach of Section 15.
                     </span>
                  </div>
                  The penalty provisions for any infringement of such IPRs are also incorporated in the Customs
                  Act in Clause 9 under Section 156(1). According to Clause 9, for such violation or
                  infringement in relation to an imported or exported goods, “such goods shall be liable to
                  confiscation; and any person concerned in the offence shall also be liable to a penalty not
                  exceeding two times the value of the goods”.
               </p>
               <p className="color-text fs-5 mb-4">
                  <b>WIPO-Administered Treaties (IPR Enforcement)<br></br>Contracting Parties Bangladesh</b>
               </p>
               <Table striped bordered hover variant="light blue" >
                  <thead>
                     <tr>
                        <th>Contracting Party</th>
                        <th>Treaty</th>
                        <th>Instrument	</th>
                        <th>In Force</th>
                        <th>Details</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>Bangladesh</td>
                        <td>Berne Convention</td>
                        <td>Accession: February 4, 1999</td>
                        <td>May 4, 1999</td>
                        <td>Details</td>
                     </tr>
                     <tr>
                        <td>Bangladesh</td>
                        <td>Paris Convention</td>
                        <td>Accession: November 29, 1990</td>
                        <td>March 3, 1991</td>
                        <td>Details</td>
                     </tr>
                     <tr>
                        <td>Bangladesh</td>
                        <td>WIPO Convention</td>
                        <td>Accession: February 11, 1985</td>
                        <td>May 11, 1985</td>
                        <td>Details</td>
                     </tr>
                  </tbody>
               </Table>
               <p className="color-text fs-5 mb-4">
                  <b>Notifications Bangladesh</b>
               </p>
               <Table striped bordered hover variant="light blue" >
                  <thead>
                     <tr>
                        <th>No</th>
                        <th>Notification Title</th>
                        <th>Treaty</th>
                        <th>Notification Date</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>No 269</td>
                        <td>Declaration by the People's Republic of Bangladesh</td>
                        <td>Berne Convention</td>
                        <td>September 5, 2014</td>
                     </tr>
                     <tr>
                        <td>No 234</td>
                        <td>Declaration by the People's Republic of Bangladesh Relating to Articles II and III of the Appendix to the Paris Act (1971)</td>
                        <td>Berne Convention</td>
                        <td>June 1, 2004</td>
                     </tr>
                     <tr>
                        <td>No 200</td>
                        <td>Accession by the People's Republic of Bangladesh</td>
                        <td>Berne Convention</td>
                        <td>February 4, 1999</td>
                     </tr>
                     <tr>
                        <td>No 122</td>
                        <td>Accession by the People's Republic of Bangladesh</td>
                        <td>Paris Convention</td>
                        <td>December 3, 1990</td>
                     </tr>
                     <tr>
                        <td>No 133</td>
                        <td>Accession by the People's Republic of Bangladesh</td>
                        <td>WIPO Convention</td>
                        <td>February 12, 1985</td>
                     </tr>
                  </tbody>
               </Table>

            </div>
         </div>

         <div className='container-xxl center-background1'>
            <h2> Related Articles</h2>
         </div>

         <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
               <MarqueeCard
                  imageUrl={webdevelopImg}
                  title="Foreign Company Formation"
                  buttonText="Read More"
                  buttonLink="/web-development"
               />
               <MarqueeCard
                  imageUrl={trademarkImg}
                  title="Trademark"
                  buttonText="Read More"
                  buttonLink="/trademark"
               />
               <MarqueeCard
                  imageUrl={patentImg}
                  title="Patent"
                  buttonText="Read More"
                  buttonLink="/patent"
               />
               <MarqueeCard
                  imageUrl={iprImg}
                  title="IPR Enforcement"
                  buttonText="Read More"
                  buttonLink="/ipr-enforcement"
               />
               <MarqueeCard
                  imageUrl={industrialImg}
                  title="Industrial Design"
                  buttonText="Read More"
                  buttonLink="/industrial-design"
               />
               <MarqueeCard
                  imageUrl={copyrightImg}
                  title="Copyright"
                  buttonText="Read More"
                  buttonLink="/copyright"
               />
               <MarqueeCard
                  imageUrl={BtclImg}
                  title="BTCL Domain Registration"
                  buttonText="Read More"
                  buttonLink="/btcl-domain-registration"
               />

            </Marquee>
         </div>

         <div className="container py-5 my-0">
            <h2 className="color-text text-center mb-4">IPR Enforcement FAQ</h2>
            <Faq items={faqItems} />
         </div>
         <Footer />
      </>
   )
}

export default IprEnforcement