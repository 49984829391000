import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'

function TermAndCondition() {
    return (
        <>
            <NavBar />
            <Container style={{ marginTop: '100px', padding: '10px' }}>
                <center>
                    <h2>Terms and Conditions</h2>
                </center>
                <p>
                    Jkassociates.com.bd owns and operates this Website.  This document governs your relationship with
                    Jkassociates.com.bd. By using the Services, you are agreeing to all of the Terms and conditions, as
                    may be updated by us from time to time. You should check this page regularly to take notice of any
                    changes we may have made to the Terms of Conditions.<br /><br />
                    Access to this Website is permitted on a temporary basis, and we reserve the right to withdraw or
                    amend the Services without notice. We will not be liable if for any reason this Website is unavailable
                    at any time or for any period. From time to time, we may restrict access to some parts or this entire
                    Website.
                </p>
                <h5>Information you give us</h5>
                <p>
                    You agree that you have provided, and will continue to provide accurate, up to date, and complete
                    information about yourself. We need this information to provide you with the products. If you use
                    Our Website, you are responsible for maintaining the confidentiality of your details and for
                    preventing any unauthorized person from using your computer. We reserve the right to refuse your
                    access to Our Website
                </p>
                <h5>Privacy Policy</h5>
                <p>
                    Our privacy policy, which sets out how we will use your information, can be found at Privacy
                    Policies. By using this Website, you consent to the processing described therein and warrant that
                    all data provided by you is accurate.
                </p>
                <h5>Buyers</h5>
                <p>
                    You may purchase products offered online by us by completing the registration form available on our
                    website or over the phone call.  By placing an order, you confirm that you are agreed with Terms
                    and Conditions and all information you provide is accurate, complete and not misleading.<br /><br />

                    You agree to deal with us in good faith and in compliance with all applicable Terms and Conditions.
                    We may cancel orders where we consider that you have not dealt with us in good faith or have
                    otherwise breached applicable Terms and Conditions, even if the order has been confirmed and your
                    payment is done.  If your payment is done for the purchase and we cancel the order, we will notify
                    you and send you back the amount you paid.
                </p>
                <h5>Orders</h5>
                <p>
                    You have to register with our website to be able to make purchases from our Consultancy Shop. If
                    you face any difficulties to register our support teams will help you to register. Once you submit
                    an order, you cannot cancel it after we shipped the ordered products.  All orders are subject to
                    acceptance by us, at our discretion.
                </p>
                <h5>Special offers</h5>
                <p>
                    We may provide special offers in relation to our goods from time to time. At any time, we may
                    terminate any special offer without advance notice
                </p>
                <h5>Pricing and Availability</h5>
                <p>
                    All prices are in Bangladeshi Taka. All prices are subject to change without notice.<br /><br />

                    We try and ensure that all details, descriptions and prices which appear on this Website are
                    accurate, errors may occur. If we discover an error in the price of any goods which you have
                    ordered we will inform you of this as soon as possible and give you the option of reconfirming
                    your order at the correct price or cancelling it. If we are unable to contact you we will treat
                    the order as cancelled. If we cancel any order in such situation and you have already paid for the
                    goods, you will receive a full refund.
                </p>
                <h5>Payment</h5>
                <p>
                    The full payment for your order (including the product price and applicable delivery charges) can
                    be made in multiple ways. One is advanced payment by your Credit card and Debit Card or bkash
                    wallet and SSLCommerz<br /><br />

                    In case of large transactions we will require confirmation on payment details before we proceed to
                    fill the order. In this event, we will contact you. In some cases this may delay your order by a
                    short period.
                </p>
                <h5>Contacting us</h5>
                <p>
                    You can contact us using any of the following methods:<br /><br />

                    Phone:  +8801511-404971  , +8801973-404971
                    info@jkassociates.com.bd<br /><br />
                    Head Office: 752/2, Begum Rokeya Ave, Mirpur, Dhaka 1216
                </p>
                <h5>Notices to you</h5>
                <p>
                    We may notify you by email, a general notice on this website or by other reliable method to an
                    address or using contact information previously provided by you.
                </p>
                <h5>Intellectual Property, Software and Content</h5>
                <p>
                    The intellectual property rights in all software and content (including photographic images) made
                    available to you on or through this Website remains the property of Jkassociates.com.bd or its
                    licensors and are protected by copyright laws and treaties around the world. All such rights are
                    reserved by Jkassociates.com.bd and its licensors. You may store, print and display the content
                    supplied solely for your own personal use. You are not permitted to publish, manipulate,
                    distribute or otherwise reproduce, in any format, any of the content or copies of the content
                    supplied to you or which appears on this Website nor may you use any such content in connection
                    with any business or commercial enterprise.
                </p>
                <h5>Prohibitions</h5>
                <p>
                    You must not misuse this Website. You will not: commit or encourage a criminal offence transmit or
                    distribute a virus, Trojan, worm, logic bomb or any other material which is malicious,
                    technologically harmful, in breach of confidence or in any way offensive or obscene; hack into any
                    aspect of the Service; corrupt data; cause annoyance to other users; infringe upon the rights of
                    any other person’s proprietary rights; send any unsolicited advertising or promotional material,
                    commonly referred to as “spam”; or attempt to affect the performance or functionality of any
                    computer facilities of or accessed through this Website. Breaching this provision would
                    constitute a criminal offence and Jkassociates.com.bd will report any such breach to the
                    relevant law enforcement authorities and disclose your identity to them.
                </p>
                <h5>Invalidity</h5>
                <p>
                    If any part of the Terms and conditions is unenforceable (including any provision in which we
                    exclude our liability to you) the enforceability of any other part of the Terms and conditions
                    will not be affected all other clauses remaining in full force and effect. So far as possible where
                    any clause/sub-clause or part of a clause/sub-clause can be severed to render the remaining part
                    valid, the clause shall be interpreted accordingly. Alternatively, you agree that the clause shall
                    be rectified and interpreted in such a way that closely resembles the original meaning of the
                    clause /sub-clause as is permitted by law.
                </p>
                <h5>Errors</h5>
                <p>
                    From time to time there may be errors on our website such as wrong pricing, wrong descriptions
                    and offers for products that are not available or are no longer available. Despite anything else
                    in these Terms, to the extent legally permitted, we may decline or cancel any orders that are
                    affected by the error, even if the order has been confirmed and your payment is done.  If your
                    payment is done for the purchase and we cancel the order, we will notify you and send you back
                    the amount you paid.
                </p>
                <h5>Indemnity</h5>
                <p>
                    You agree to indemnify, defend and hold harmless Bangla-toys.com, its directors, officers,
                    employees, consultants, agents, and affiliates, from any and all third party claims, liability,
                    damages and/or costs (including, but not limited to, legal fees) arising from your use this
                    Website or your breach of the Terms of Service.
                </p>
                <h5>Complaints</h5>
                <p>
                    We operate a complaints handling procedure which we will use to try to resolve disputes when they
                    first arise, please let us know if you have any complaints or comments.
                </p>
                <h5>Entire Agreement</h5>
                <p>
                    These Terms and conditions including any set out the entire agreement between you and us concerning
                    its subject matter and supersede all prior communications.
                </p>
                <h5>Alterations</h5>
                <p>
                    We may alter these Terms and Conditions from time to time by posting the altered version on our
                    website. You should visit the website regularly to keep up to date with any alterations. In the
                    case of an alteration, the altered Terms only apply to goods ordered via the website after the
                    alteration takes effect. Jkassociates.com.bd shall have the right in its absolute discretion at
                    any time and without notice to amend, remove or vary the Services and/or any page of this Website.
                </p>
                <h5>Dispute Resolution</h5>
                <p>
                    In the event of a dispute arising out of or in connection with these terms or any contract
                    between you and us, then you agree to attempt to settle the dispute by engaging in good faith
                    with us in a process of mediation before commencing arbitration or litigation.
                </p>
            </Container>
            <Footer />
        </>
    )
}

export default TermAndCondition