import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import CoSpaceForm from '../../components/CoSpcaeForm';

function CoSpace() {
  const services = [
    {
      image: "img/room.jpeg",
      title: "Virtual Office",
      description: "Get a prestigious business address, mail handling, and virtual receptionist services as part of our monthly package."
    },
    {
      image: "img/room.jpeg",
      title: "Room on Desk Use",
      description: "Access to shared desk space in a modern coworking environment, equipped with amenities, included in our monthly package."
    },
    {
      image: "img/room.jpeg",
      title: "Hourly Physical Office",
      description: "Book a physical office space on an hourly basis with access to meeting rooms, included in our monthly package."
    },
  ];

  return (
    <div className="visa-consultancy-page">
      <NavBar />
      
      <div className="section services-section">
        <h2>JK Co-Space Packages</h2>
       
        <div className="service-list">
          {services.map((service, index) => (
            <div key={index} className="service">
              <img src={service.image} alt={service.title} />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
      

      <div>
        <CoSpaceForm />
      </div>
      <Footer />
    </div>
  );
}

export default CoSpace;
