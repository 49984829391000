import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import BtclImg from "../../assets/images/JK-Banner.png"
import copyrightImg from "../../assets/images/copyRight-Registration-2.png"
import industrialImg from "../../assets/images/Industrial-Design.png"
import iprImg from "../../assets/images/Intellectual-property-rights-Bangladesh.png"
import patentImg from "../../assets/images/Patent-registration.png"
import trademarkImg from "../../assets/images/Trade-license-Registration.png"
import webdevelopImg from "../../assets/images/web-development-1.jpg"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import copyrightImg2 from "../../assets/images/Copyright-Registration-Process.png"
import copyrightImg3 from "../../assets/images/What-are-the-Objects-of-Copyright.png"

function Copyright() {
  const faqItems = [
    {
      title: 'How long is copyright in BD?',
      content: <strong>The terms for literary, musical, theatrical, cinematographic, and photographic works
        are 60 years from the start of the calendar year that comes after the author&#39;s passing. For works
        created by international organisations, the duration is 60 years from the work&#39;s initial publication.</strong>
    },
    {
      title: 'How many times can you get copyright?',
      content: <strong>If you get 3 copyright strikes: Your account, along with any associated channels, is
        subject to termination. All the videos uploaded to your account will be removed. You can&#39;t create
        new channels.</strong>
    },
    {
      title: 'What is not copyrighted?',
      content: <strong>For example, copyright does not protect factual information or data, titles, short word
        combinations, names, characters, slogans, themes, plots, or ideas.</strong>
    }
  ];
  return (
    <>
      <Meta title="Copyright"
        description="This page provides information about BSTI permission and related processes."
        image={copyrightImg}
        keywords="keyword1, keyword2, keyword3"
      />
      <NavBar />

      <div className="container-fluid hero-header bg-light py-5 mb-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h1 className="display-4 mb-3 animated slideInDown">Copyright (C)</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Intellectual Property</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Copyright (C)</li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={copyrightImg} alt='copyRight-Registration-2' />
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>Copyright registration in Bangladesh</b>
          </p>
          <p>
            Copyright Registration in Bangladesh, Copyright Office is responsible for maintaining the
            register of copyrighted works. Any application for registration, transfer, abandonment,
            modification of register should be made to the Registrar.<br /><br />
            Copyright deals with the protection of the right of intellectual property. It does not have
            any relation with other materialistic property moveable or immovable. Research,
            literary works, drama, arts, and paintings, music, audio-video production, film, photography,
            sculpture, recording, software, e-mail, website and broadcasting of radio and television are
            considered as major intellectual property in the present-day world. According to the World
            Intellectual Property Organization (WIPO), ‘Intellectual Property refers to creations of the
            mind; Inventions, literary and artistic works, and symbols, names, images, and designs used
            in commerce.’<br /><br />
            Copyright registration has not been made compulsory in the Copyright Act, 2000. It is not
            also compulsory in other countries. But the copyright registration is a pre-requisite to
            avoid any possible conflict over the ownership of intellectual property. Being a member
            country of WIPO, World Trade Organization (WTO) and the signatory at Berne Convention,
            Universal Copyright Registration Convention, and Bangladesh is bound to abide by all
            conditions of these conventions related to copyright and the Trade-Related Aspects of
            Intellectual Property Rights (TRIPS) Agreement. The Bangladesh Copyright Act 2000 was
            enacted incorporating the nationally and globally important provisions of all major
            international copyright rules. It was further amended in 2005 for updating. Later, the
            copyright Rules 2006 (SRO No-219) was formulated under section 103 of the Copyright Act to
            strengthen copyright management through proper implementation of the law.
          </p>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <img className="img-fluid" src={copyrightImg2} alt='Copyright-Registration-Process' />
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <p className="color-text fs-5 mb-4">
                  What is Copyright?
                </p>
                <p>
                  Copyright is a legal means of protecting an author’s work. It is a type of
                  intellectual property that provides exclusive publication, distribution, and
                  usage rights for the author. This means whatever content the author created
                  cannot be used or published by anyone else without the consent of the author
                </p>
                <p className="color-text fs-5 mb-4">
                  Meaning of copyright
                </p>
                <p>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Copyright means inter alia the exclusive right
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      to reproduce the work
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      to issue copies of the work to the public
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      to perform or broadcast the work
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      to make any translation or adaption of the work.
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      In addition, special moral rights lie with the author.
                    </span>
                  </div>
                </p>
                <p className="color-text fs-5 mb-4">
                  Who can apply?
                </p>
                <p>
                  According to section 56 of the Copyright Act, 2000 Registration of
                  copyrights.-(1) The author or publisher of, or the owner of, or other people
                  interested in the copyright in, any work may make an application in the
                  prescribed form accompanied by the prescribed fees to the Registrar for
                  entering particulars of the work in the Register of Copyrights.<br /><br />

                  (2) On receipt of an application in respect of any work under sub-section (1),
                  the Registrar may, after holding such inquiry as he may deem fit, enter the
                  particulars of the work in the Register of Copyrights and issue a certificate of
                  such registration to the applicant unless, for reasons to be recorded in writing,
                  he considers that such entry should not be made in respect of any work.<br /><br />

                  After receiving the application the Registrar serves notice along with the said
                  application to every person who has any interest in the subject matter of said
                  application. After that, If any objection arises the Registrar hold such as he
                  deems fit.
                </p>
                <p className="color-text fs-5 mb-4">
                  Who is the owner of Copyright?
                </p>
                <p>
                  The first owner of the copyright, in general, is the author.<br />

                  The owner of the copyright may assign the copyright or grant any interest in the
                  copyright by license. Licenses may also be granted by the Copyright Board.<br />

                  Registration of copyright with the Copyright Office is not obligatory, but if
                  registration has taken place the Register of Copyrights gives prima facie
                  evidence of the particulars entered therein.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-0">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>What are the Requirements to get copyright protection?</b>
          </p>
          <p>
            Copyright is basically the right to respect the author’s creation because an original work is
            the brain-child of the author. Before claiming copyright the work apart from being original,
            should satisfy the following conditions:<br /><br />

            <b>1.</b> In the case of published work, it has to be published first in Bangladesh but if it
            is first published in foreign country, the author must be a citizen of Bangladesh or domicile
            in Bangladesh at the date of publication, or where the author is dead at the time of
            publication and the work is published after his death, the author must be citizen of
            Bangladesh or domicile in Bangladesh at the time of his death. It is important to note that
            if any work is published in Bangladesh and any other country simultaneously, the work should
            be considered to be first published in Bangladesh. The work shall be considered to be
            simultaneously published if the difference of days between the publication in Bangladesh and
            publication in any other country more than 30 days or the time fixed by the Government.<br /><br />
            <b>2.</b> In case of unpublished work, the author is on the date of making of the work a
            citizen of Bangladesh or domicile in Bangladesh.
          </p>
          <p className="color-text fs-5 mb-4">
            <b>Term of Copyright</b>
          </p>
          <p>
            Copyright in a literary, dramatic, musical or artistic work published within the lifetime of
            the author subsists until 60 years from the beginning of the calendar year next following the
            year in which the author dies.<br /><br />

            Copyright in a cinematographic film, a sound recording, a photograph, a computer programmed or
            a work of the Government, a local authority or an international organization subsists until
            60 years from the beginning of the calendar year next following the publication of the work.
          </p>
          <p>
            <img className="mx-auto responsive-img" src={copyrightImg3} alt='What-are-the-Objects-of-Copyright' style={{ width: 1200, height: 400 }} />
          </p>

          <p className="color-text fs-5 mb-4">
            <b>What are the Objects of Copyright?</b>
          </p>
          <p>
            According to section 15 copyright subsists in
          </p>
          <p>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Literary works
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Dramatic works
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Musical works
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Artistic works (i.e. painting, sculpture, drawing, engraving or a photograph, a work of architecture and any other work of artistic craftsmanship)
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Cinematographic films
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Sound recordings
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                And includes computer programs as well as addresses and speeches.
              </span>
            </div>
            Foreign works are covered by section 69 read with the International Copyright Order, 2005
          </p>

          <p className="color-text fs-5 mb-4">
            <b>Registration Procedure of Copyright</b>
          </p>
          <p>
            An application for copyright should be supported by the following documents:
          </p>
          <p>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                A completed application form
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Treasury challah
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Copies of the work to be registered
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Transfer deed of work in stamp paper (if applicable)
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                Legal paper (Okalat nama) (if a lawyer submits the application form on behalf of the author)
              </span>
            </div>
            Once the Registrar received any application for copyright, s/he shall serve notice of the
            concerned application to every person who has any interest in the subject matter of that
            application. If the Registrar receives any objection s/he may after holding such inquiry as
            s/he deems fit, enter such particulars of work in the register of copyright, which s/he
            considers proper. After registration, the Registrar shall sends copies of the entries made in
            the register to the parties concerned.
          </p>
          <p className="color-text fs-5 mb-4">
            <b>TRANSFER OF COPYRIGHT</b>
          </p>
          <p>
            Copyright may be assigned, transferred or licensed. The Copyright Office has prescribed form for
            transfer.
          </p>
          <p className="color-text fs-5 mb-4">
            <b>REMEDIES AGAINST INFRINGEMENT</b>
          </p>
          <p>
            There are three kinds of remedies against infringement of copyright, namely:
          </p>
          <p className="color-text fs-5 mb-4">
            <b>CIVIL REMEDIES</b>
          </p>
          <p>
            The owner of the copyright can bring civil action in which reliefs such as Anton Pillar
            Order (Search Order) injunction, accounts and damages can be sought. A suit or other civil
            proceedings relating to infringement of copyright is to be filed in the Court of District
            Judge, within whose jurisdiction the plaintiff resides or carries on business or where the
            cause of action arose irrespective of the place of residence or place of business of the
            defendant.
          </p>
          <p className="color-text fs-5 mb-4">
            <b>CRIMINAL REMEDIES</b>
          </p>
          <p>
            Criminal remedies provides for the imprisonment of the accused or imposition of fine or both,
            seizure of infringing copies etc. Criminal proceedings are available in order to punish
            the persons who have violated the copyright law.
          </p>

          <p className="color-text fs-5 mb-4">
            <b>ADMINISTRATIVE REMEDIES</b>
          </p>
          <p>
            Administrative remedies consist of moving to the Registrar of copyrights to ban the import of
            infringing copies into Bangladesh, when the infringement is by way of such importation and
            the delivery of the confiscated infringing copies to the owner of the copyright.
          </p>
        </div>
      </div>

      <div className='container-xxl center-background1'>
        <h2> Related Articles</h2>
      </div>

      <div className='container-xxl center-background1'>
        <Marquee className="marquee-container">
          <MarqueeCard
            imageUrl={webdevelopImg}
            title="Foreign Company Formation"
            buttonText="Read More"
            buttonLink="/web-development"
          />
          <MarqueeCard
            imageUrl={trademarkImg}
            title="Trademark"
            buttonText="Read More"
            buttonLink="/trademark"
          />
          <MarqueeCard
            imageUrl={patentImg}
            title="Patent"
            buttonText="Read More"
            buttonLink="/patent"
          />
          <MarqueeCard
            imageUrl={iprImg}
            title="IPR Enforcement"
            buttonText="Read More"
            buttonLink="/ipr-enforcement"
          />
          <MarqueeCard
            imageUrl={industrialImg}
            title="Industrial Design"
            buttonText="Read More"
            buttonLink="/industrial-design"
          />
          <MarqueeCard
            imageUrl={copyrightImg}
            title="Copyright"
            buttonText="Read More"
            buttonLink="/copyright"
          />
          <MarqueeCard
            imageUrl={BtclImg}
            title="BTCL Domain Registration"
            buttonText="Read More"
            buttonLink="/btcl-domain-registration"
          />

        </Marquee>
      </div>

      <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">Copyright FAQ</h2>
        <Faq items={faqItems} />
      </div>
      <Footer />
    </>
  )
}

export default Copyright