import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import vatlImg from "../../assets/images/Vat-Consultant-1.png"
import individualImg from "../../assets/images/income-Tax-1.png"
import etinImg from "../../assets/images/e-tin.png"
import corporateImg from "../../assets/images/Corporate-Tax-Rate-in-Bangladesh.png"
import binImg from "../../assets/images/BIN-Registration-1.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import binImg2 from "../../assets/images/BIN-Registration.png"

function BINRegistration() {
    const faqItems = [
        {
            title: 'How can I get my BD bin number?',
            content: <strong>To get a BIN you need to be carrying on an enterprise. If your BIN application has been successful your details are stored in the IVAS (Integrated VAT Administration System) at NBR.
                There are options available if the application has been refused.</strong>
        },
        {
            title: 'What is the difference between VAT and bin registration?',
            content: <strong>VAT is a consumption tax that's applicable to the value added at each stage of production or distribution. BIN, on the other hand,
                is a unique identification number assigned to businesses for regulatory purposes</strong>
        },
        {
            title: 'What is a bin number?',
            content: <strong>On every credit card, you'll find a bank identification number (BIN). The BIN represents the card's first six to eight numbers,
                which together identify the financial institution that issued the card.Apr 12, 2023</strong>
        }
    ];
    return (
        <>
            <Meta title="BIN Registration"
                description="This page provides information about BSTI permission and related processes."
                image={binImg}
                keywords="keyword1, keyword2, keyword3"
            />
            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 ">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">BIN Registration</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Taxation</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">BIN Registration</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={binImg} alt='BIN-Registration-1' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>VAT Registration in Bangladesh | BIN Registration BD<br />
                            How to Obtain a BIN/VAT Registration Certificate in Bangladesh</b>
                    </p>
                    <p>
                        Value Added Tax or VAT is such an indirect tax that is paid by the consumer through a registered person. Value Added Tax or VAT on a particular good or service is determined on the basis of adding an actual level of value of the said goods or service adjusting input tax payable against the supplied goods or service.
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid" src={binImg2} alt='BIN-Registration' />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">
                                    <b>BIN/VAT REGISTRATION CERTIFICATE</b>
                                </p>
                                <p>
                                    REQUIRED SUPPORTING DOCUMENTS FOR BIN / VAT REGISTRATION CERTIFICATE:<br />
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            NID
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Picture
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Phone Number
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Email address
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Passport If, foreign 100% equity company
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            BIDA Registration Certificate (If, foreign 100% equity company)
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Copy of valid Trade License
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Tax Identification Number (TIN)
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Bank Solvency Certificate
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Rental Deed
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Copy of membership certificate from local Chamber of Commerce and Industry or relevant Trade Association
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            If a partnership business: Incorporation Certificate
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            If a limited company:
                                        </span><br />
                                    </div>
                                    <span className='px-4'>1. Certificate of Incorporation</span><br />
                                    <span className='px-4'>
                                        2. Articles of Association
                                    </span><br />
                                    <span className='px-4'>
                                        3. Memorandum of Association</span>
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    <b>VAT Online Service:</b>
                                </p>
                                <p>
                                    After vat registration you need to file vat return every month date of 15th by using vat online services, NBR
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>Who pays the VAT?</b>
                    </p>
                    <p>
                        The procedure of Value Added Tax or VAT is an automatic taxing procedure based on
                        input tax credit against supply or sale. The supplier accepts the paid-up VAT on the
                        purchase tax of the goods or service during the purchase as credit as against the VAT
                        included in the price during the supply of such goods or services and he pays the net
                        amount of VAT.<br /><br />

                        Therefore, the seller realizes the VAT from within the price obtained from the buyer
                        & deposits the net amount of VAT to the Government treasury after taking on VAT at
                        the purchase level. As VAT is considered to be a consumer tax, the consumer shall pay
                        the whole amount of VAT as applicable on goods or service. As an intermediary
                        procedure, the producer, the supplier, all at every level of sale, shall realize VAT
                        on behalf of the Government and deposit to the Government treasury.<br /><br />

                        The VAT payer is the last consumer of goods or service in the Value Added Tax

                        procedure. The supplier or seller realizes VAT from the purchaser at every level of
                        sale and deposits to the Government treasury.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>How to apply for VAT registration?</b>
                    </p>
                    <p>
                        Applying for vat registration number , but there are requirements you have to meet to be entitled. You need to provide the right information to support your application. This will also reduce possible delays in processing your request.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>What is BIN Registration or Enlistment?</b>
                    </p>
                    <p>
                        Under the Value Added Tax procedure, any importer, exporter or taxable supplier has
                        to be involved in the VAT Procedure. This involvement takes place with the VAT
                        procedure through registration or enlistment. He has to abide by the VAT law through
                        taking Business Identification Number in the prescribed manner under the purview of
                        the VAT law. And under the purview of the VAT law, taking Business Identification
                        Number in the prescribed manned is called registration or enlistment.<br /><br />

                        When shall I have to take BIN Registration or Enlistment?<br /><br />
                        According to the Value Added Tax and Supplementary Duty Act 2012, VAT registration of
                        all business entities has been made compulsory.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>For those for whom VAT registration is mandatory:</b>
                    </p>
                    <p>
                        Those with an annual sale price of between tk. 50 lakh and tk. 3 crore will have to register for turnover VAT.
                        But it does not include the following sale price, namely:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                the price of the exempted supply;
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                the selling price of capital assets;
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                the selling price of an institution or part of an economic activity; Or
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                the value of supplies made as a result of the permanent cessation of economic activities;
                            </span>
                        </div>
                        Those whose annual selling price is above Tk 3 crore will have to register for compulsory VAT.<br /><br />
                        Also the following persons / businesses have to take compulsory VAT registration irrespective of the selling price:<br /><br />

                        All persons / organizations supplying / producing / importing goods / services for which supplementary duty is levied
                        All persons / organizations who provide goods / services against tender / contract / work order
                        Individuals / institutions engaged in import-export business
                        Manufacturers / Service Providers / Business Suppliers / Shops located in Super shops and Shopping Malls / Establishments in City Corporations and District Cities as per General Order No. 17.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>For those for whom VAT registration is not mandatory:</b>
                    </p>
                    <p>
                        Whose annual sales value is less than tk. 50 lakh and not related to the nature of the business mentioned above.
                        Goods and services exempted from VAT mentioned in the first schedule.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>What is a Business Identification Number (BIN) ?</b>
                    </p>
                    <p>
                        Business Identification Number is the business identification number taken by an individual on the basis of turnover of his economic activities. This identification number can’t determine the nature and activities of the individual’s economic activities. It is a specific number of 9 digits by which a taxpayer can be identified. A tax payer’s state of business affairs can be identified through this number from the website of the NBR.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>How can I obtain a BIN?</b>
                    </p>
                    <p>
                        If the limit of the annual turnover of my economic activities exceeds the prescribed limit, an application has to be submitted to the Commissioner in Form- VAT 2.1.<br /><br />

                        After proper scrutiny and examination, a Registration Certificate/Turnover Tax Enlistment Certificate will be issued in your favor in Form-VAT 2.1 containing a BIN from the VAT system within three working days.<br /><br />

                        Not everybody is entitled to a BIN. Although everybody can apply for free, only those who are entitled will get and hold a BIN.
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={binImg}
                        title="BIN Registration"
                        buttonText="Read More"
                        buttonLink="/bin-registration"
                    />
                    <MarqueeCard
                        imageUrl={corporateImg}
                        title="Corporate TAX"
                        buttonText="Read More"
                        buttonLink="/corporate-tax"
                    />
                    <MarqueeCard
                        imageUrl={etinImg}
                        title="E-TIN Certificate"
                        buttonText="Read More"
                        buttonLink="/etin-certificate"
                    />
                    <MarqueeCard
                        imageUrl={individualImg}
                        title="Individual TAX"
                        buttonText="Read More"
                        buttonLink="/individual-tax"
                    />
                    <MarqueeCard
                        imageUrl={vatlImg}
                        title="Value Added TAX"
                        buttonText="Read More"
                        buttonLink="/value-added-tax"
                    />
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">BIN Registration FAQ</h2>
                <Faq items={faqItems} />
            </div>
            <Footer />
        </>
    )
}

export default BINRegistration