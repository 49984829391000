import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import openForeignCompany from "../../assets/images/open-Foreign-Company.png"
import BidaImg from "../../assets/images/Bida-.png"
import foreigncompanydirectorserviceImg from "../../assets/images/foreign-company-director-service-1.png"
import foreigncompanyregisteredaddressImg from "../../assets/images/foreign-company-registered-address-1.png"
import foreignDelegatesImg from "../../assets/images/Foreign-Delegates-1.png"
import directorServiceImg from "../../assets/images/Foreign-Direct-Investment-Investment-Bangladesh.png"
import privateInvestmentImg from "../../assets/images/Foreign-Direct-Investment-Investment-Bangladesh.png"
import workPermitVISA from "../../assets/images/Work-Permit.png"
import Marquee from "react-fast-marquee";
import MarqueeCard from '../../components/MarqueeCard'
import workPermitVISA2 from "../../assets/images/Work-Permit-process.png"
import workImg from "../../assets/images/Available-Visa-in-Bangladesh.png"

function WorkPermitVisaInBangladesh() {
    const faqItems = [
        {
            title: 'How long does it take to get a work visa for Bangladesh?',
            content: <strong> The visa processing time may take 5 to 7 working days. However, it can vary, so it's
                best to apply well in advance of your intended travel date. Foreign nationals employed in
                Bangladesh must obtain a work visa, which is typically sponsored by their employer.</strong>
        },
        {
            title: 'What is work permit status?',
            content: <strong>Some types of visas also allow you to work there, like digital nomad/remote work visas.
                Whereas, a work permit is a license specifically obtained to authorize you to earn income in a
                foreign country. On occasion, it must be obtained regardless of your visa status.</strong>
        },
        {
            title: 'Which is work permit number?',
            content: <strong> The most recent Visitor Record, Study Permit, Work Permit or Temporary Resident
                Permit issued to you. The document number is found on the top right of your study permit, or
                IRCC document. It is a letter followed by 9 numbers (e.g.F123456789) on the top right.</strong>
        }
    ];
    return (
        <>
            <Meta title="Work Permit VISA"
                description="This page provides information about BSTI permission and related processes."
                image = {workPermitVISA}
                keywords = "keyword1, keyword2, keyword3"
            />
            
            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Work Permit Visa In Bangladesh</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Foreign Entity</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Work Permit Visa In Bangladesh</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={workPermitVISA} alt='Work-Permit' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p>
                        Work Permit VISA is mandatory for every foreign national seeking employment in
                        Bangladesh. Three government authorities issue a work permit in Bangladesh.
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                For private sector industrial enterprise, branch office and liaison office,
                                outside of Export Processing Zone (EPZ) – Bangladesh Investment Development
                                Authority (BIDA).
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                For employment of foreign national in the EPZ– Bangladesh Export Processing
                                Zones Authority (BEPZA).
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                For employment of foreign national in any NGO – NGO Affairs Bureau.
                            </span>
                        </div>
                        Private sector industrial enterprises, branch office, liaison/representative office
                        desiring to employ foreign nationals are required to apply to BIDA in their
                        prescribed form. While issuing a work permit, BIDA usually follows the following
                        guidelines:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Only nationals of countries recognized by Bangladesh are considered for
                                employment.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Employment of expatriate personnel is considered only in industrial/
                                commercial establishments which are sanctioned/registered by the appropriate
                                authority.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Employment of foreign nationals is normally considered for the job for which
                                local experts/technicians are not available.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Persons below 18 years of age are not eligible for employment.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                A decision of the board of directors of the concerned company for new
                                employment/employment extension is to be furnished in each case.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The number of foreign employees should not exceed 5% in the industrial
                                sector and 20% in the commercial sector of the total employees, including
                                top management personnel.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Initially, employment of any foreign national is considered for a term of
                                one year, which may be extended on the basis of merit of the case.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Security clearance will be required from the Ministry of Home Affairs.
                            </span>
                        </div>
                    </p>
                </div>
            </div>

            <div className="container-xxl py-3">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid" src={workPermitVISA2} alt='Work-Permit-process' />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">PROCESS OF ISSUING WORK PERMIT BY BIDA:</p>
                                <p>
                                    <b> FIRST STEP: PUBLICATION OF NEWSPAPER ADVERTISEMENT.</b> The employer
                                    is required to publish a newspaper advertisement or online advertisement
                                    for recruitment. This requirement is not always mandatory.<br /><br />
                                    <b>SECOND STEP: APPLICATION FOR ISSUANCE OF E VISA RECOMMENDATION.</b>
                                    Local sponsor (Liaison/Branch Office or local company) will apply to BIDA
                                    online for issuance of E visa recommendation in favor of the desired
                                    foreign national. The following documents are usually required for this
                                    application:
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Copy of permission letter for branch/liaison/representative office
                                            or Memorandum of articles of association and certificate of
                                            incorporation in case of a locally incorporated company.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Board Resolution for the employment of foreign nation(s)
                                            mentioning Expatriate Name, Nationality & Passport Number.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Photograph of the Expatriate.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Copy of passport of the Expatriate/Investor/Employee (Whole of the used part).
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Appointment Letter/transfer order/service contract or agreement for expatriate/investors.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Certificate of all academic qualification & professional experience for the employees.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Paperclipping showing advertisement made for recruitment of local
                                            personal prior to the appointment of the expatriate(s).
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Specific activities of the company.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Statement of manpower showing a list of local and expatriate
                                            personal employed with the designation, salary break-up,
                                            nationality, and date of the first appointment. (Latest Copy).
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Encashment certificate of inward remittance of minimum US
                                            $50,000.00 as initial establishment cost for branch/liaison/
                                            representative office and locally incorporated/ joint venture
                                            and 100% foreign ownership companies.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Up-to-date Income tax clearance certificate (Organization).
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Attachment of company’s comments as per remarks (if any)
                                        </span>
                                    </div>
                                    NB: All documents shall have to be attested by the Chairman/ CEO /
                                    Managing director/ Country Manager/ Chief executive of the Company/
                                    firms. THIRD STEP: APPLICATION FOR E VISA IN THE RESPECTIVE BANGLADESH
                                    EMBASSY OR HIGH COMMISSION Once BIDA issue the E visa recommendation,
                                    the foreign national should apply for an E visa in the respective
                                    Bangladesh Embassy or High Commission. Once the E visa is issued, the
                                    foreign national should enter Bangladesh with the E visa. FOURTH STEP:
                                    APPLICATION FOR WORK PERMIT IN BANGLADESH. Within 15 days from the date
                                    of arrival of the foreign national, application for a work permit should
                                    be submitted to BIDA. A fee of BDT 5,000 is applicable in this regard.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>PROCESS OF ISSUING WORK PERMIT BY BIDA:</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                All documents stated in the second step.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Attested photocopy of passport with arrival stamp ‘E’ type visa for the employee.
                            </span>
                        </div>
                        FIFTH STEP: SECURITY CLEARANCE. Once a work permit is issued by BIDA, a copy of the
                        work permit would then sent a letter to the Ministry of Home Affairs for security
                        clearance by BIDA. After some scrutiny, security clearance will be issued.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Private Investment- PI VISA</b>
                    </p>
                    <p>
                        PI is applicable for investors whose name is on the shareholder’s list. The process of
                        PI visa and work permit is almost similar to E visa and work permit. When a foreigner
                        enters Bangladesh, he enters with specific visas as set by the requirements of the
                        land. Procuring a work permit and visas in Bangladesh could be a hurdle some process
                        at times. If a foreign expat wants to enter Bangladesh, he needs to apply and obtain
                        work permit from the Board of Investment in Bangladesh. Obtaining visa and work permit
                        was previously very difficult but since the Government of Bangladesh is encouraging
                        more foreign investments within the country, the sector of providing visa and work
                        permit has flourished and the system has been now dealt on an online basis, for
                        convenience. In the following we have discussed the procedure of obtaining some
                        important visas, you may also look into the short description of our Immigration
                        services here, which are often applied by the foreign expats, to enter Bangladesh:
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Top Priority-based</b>
                    </p>
                    <p>
                        <img className="mx-auto responsive-img" src={workImg}
                            alt='Available-Visa-in-Bangladesh' style={{ width: 1400, height: 300 }}
                        />
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>A3 Visa</b>
                    </p>
                    <p>
                        Applicable to Experts/advisers / officials / staff members / labors working in any
                        project under the bilateral / multilateral agreement between Bangladesh government and
                        development partner agencies. The visa is issued upon submitting all the required
                        documents to the respective Bangladeshi Embassy along with the recommendation of the
                        Embassy or relevant ministry/ Economic Relation Department/ relevant Government Agency.
                        The length of the visa given is for the period of the employment or five years, whichever
                        is shorter, depending upon the desecration of the visa issuing authority (respective
                        Bangladeshi embassy). Upon expiry of the visa, the expatriate can apply for the extension
                        of the visa to the Department of Immigration and Passport (DIP). The DIP upon receiving
                        the recommendation of the concerned Ministry / Economic Relation Department of the
                        Ministry of Finance / relevant government agency of Bangladesh, Security Clearance and
                        taking into account the conditions of the agreement between the Government and the
                        development partner can extend the visa for the full employment period or up to five
                        years, whichever is shorter.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>B Visa</b>
                    </p>
                    <p>
                        Applicable to businessmen/business representatives. The visa is issued by the respective
                        Bangladeshi Embassy upon receiving all the required documents along with the
                        recommendation of recognized chambers of commerce OR recommendation of the local sponsor
                        in Bangladesh. Embassy issues a maximum of six-month visa. Upon expiry of the visa, the
                        expatriate can apply for the extension of the visa to the Department of Immigration and
                        Passport (DIP). The DIP upon receiving security clearance and the recommendation of the
                        local sponsor can extend the visa for a maximum of three months.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>E Visa</b>
                    </p>
                    <p>
                        Applicable to Experts / advisers / employees / individuals appointed in government /
                        semi-government/ autonomous bodies / projects and equivalent organizations, OR
                        individuals employed in local/ foreign government / semi-government/ liaison / Industrial
                        / commercial organizations or other equivalent organizations OR individuals appointed
                        under local / foreign government / semi-government contractor ship and in equivalent
                        organizations. Embassy upon receiving all the required documents along with the
                        recommendation from the concerned Ministry in Bangladesh / Bangladesh Board of Investment
                        (BOI) / Bangladesh Export Processing Zones Authority (BEPZA) issue the visa for a
                        maximum of three months. Upon expiry of the visa, the expatriate can apply for the
                        extension of the visa to the Department of Immigration and Passport (DIP). The DIP upon
                        receiving the security clearance and the work permit can extend the visa for the full
                        employment period or a maximum of three years, whichever is shorter.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Other Information</b>
                    </p>
                    <p>
                        Foreign citizens/nationals of India, Pakistan, and South Korea traveling to Bangladesh by
                        air with a valid visa for 90 days or more are required to REGISTER at the airport. In case
                        of entry through the land port, such registration should be completed at the nearest
                        District Special Branch (DSB) / City Special Branch (CSB). Application for extension of
                        visa should be submitted before the expiration of the existing visa. Penalty for over-stay
                        will be imposed if the application for extension of visa is submitted after the date of
                        the expiry of the visa. If the overstay is less than a month, fine can be paid to the
                        customs authority while departing from Bangladesh. However, if the overstay is more than
                        one month, then DIP needs to be informed and they shall inform the visa holder about the
                        required procedure to be followed. If the overstay is less than 15 days then the fine is
                        BDT 200 to 300 per day and if the overstay is for than 15 days then the fine is BDT 1000.
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={openForeignCompany}
                        title="Foreign Company Formation"
                        buttonText="Read More"
                        buttonLink="/foreign-company-formation"
                    />
                    <MarqueeCard
                        imageUrl={workPermitVISA}
                        title="Work Permit Visa In Bangladesh"
                        buttonText="Read More"
                        buttonLink="/work-permit-visa"
                    />
                    <MarqueeCard
                        imageUrl={privateInvestmentImg}
                        title="Private Investment VISA"
                        buttonText="Read More"
                        buttonLink="/private-investment-visa"
                    />
                    <MarqueeCard
                        imageUrl={directorServiceImg}
                        title="Foreign Company Director Service"
                        buttonText="Read More"
                        buttonLink="/foreign-company-director-service"
                    />
                    <MarqueeCard
                        imageUrl={foreignDelegatesImg}
                        title="Foreign Delegates"
                        buttonText="Read More"
                        buttonLink="/foreign-delegates"
                    />
                    <MarqueeCard
                        imageUrl={foreigncompanyregisteredaddressImg}
                        title="Foreign Company Registered Address"
                        buttonText="Read More"
                        buttonLink="/foreign-company-registered-address"
                    />
                    <MarqueeCard
                        imageUrl={foreigncompanydirectorserviceImg}
                        title="Foreign Direct Investment"
                        buttonText="Read More"
                        buttonLink="/foreign-company-director-service"
                    />
                    <MarqueeCard
                        imageUrl={BidaImg}
                        title="BIDA Permission"
                        buttonText="Read More"
                        buttonLink="/bida-permission"
                    />
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Work Permit Visa In Bangladesh FAQ</h2>
                <Faq items={faqItems} />
            </div>
            <Footer />
        </>
    )
}

export default WorkPermitVisaInBangladesh