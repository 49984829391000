import React from 'react'
import NavBar from '../components/NavBar'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HomeScreen from './HomeScreen'
import Meta from '../components/Meta'
import RegulatoryComplience from '../assets/images/Regulatory_Complience.png'

function Base() {
  return (
    <>

      <Meta title={"JK Consultancy | Tax | VAT | Company Registration | Copyright"}
        description="'JK Associates' is top-ranked Corporate Consultancy and Law firm in Bangladesh with the international presence provides specialized services in Intellectual Property Rights, Corporate Affairs, Foreign Trade and Investment, Taxation, and Litigation."
        image={RegulatoryComplience}
        keywords="JK Consultancy, Company Registration, Tax"
      />

      <NavBar />
      <Header />
      <HomeScreen />
      <Footer />
      
    </>
  )
}

export default Base