import React from 'react'
import NavBar from '../../../../components/NavBar'
import Footer from '../../../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../../../components/Faq';
import Meta from '../../../../components/Meta';
import accountngImg from "../../../../assets/images/Accounting-Software.png"
import companyImg from "../../../../assets/images/Company-Portfolio-1.jpg"
import erpImg from "../../../../assets/images/ERP-Software-1.png"
import hrImg from "../../../../assets/images/HR-Management-Software-1.png"
import vatImg from "../../../../assets/images/VAT-Software.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../../../components/MarqueeCard'

function CompanyPortfolio() {
    const faqItems = [
        {
            title: 'How do I create a company portfolio?',
            content: <strong>How to create a business portfolio
                Choose a format or portfolio tool. ...
                Include basic business information. ...
                Refine the mission statement and objectives. ...
                Detail products and services. ...
                List affiliations and business relationships. ...
                Regularly review the portfolio.</strong>
        },
        {
            title: 'What is a company portfolio example?',
            content: <strong>What is a business portfolio example? An example of a business portfolio is the group of products, services, and assets
                that describe a company such as Coca-Cola and provides it with the opportunities to attain its objectives</strong>
        },
        {
            title: 'What is the difference between a company profile and a company portfolio?',
            content: <strong>In the beginning, there is no difference
                between "company profile" and "company portfolio." Both of them the company's mission, goals, vision, and history,
                The company profile provides an overview of the company, its products, and services it offers.</strong>
        }
    ];
    return (
        <>
            <Meta title="Company Portolio"
                description="This page provides information about BSTI permission and related processes."
                image={companyImg}
                keywords="keyword1, keyword2, keyword3"
            />
            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 ">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Company Portfolio</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Taxation</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Audit</Link></li>
                                    <li className="breadcrumb-item "><Link to="#">Business Software</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Company Portfolio</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src="img/Company-Portfolio-1.jpg" alt='Company-Portfolio-1' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>What is the company portfolio?</b>
                    </p>
                    <p>
                        A company portfolio is a collection of information about a company’s products,
                        services, and business operations. It includes a summary of the company’s history,
                        mission statement, vision, and values, as well as descriptions of its current and past
                        products and services, target markets, and competitive positioning.<br /><br />

                        The company portfolio may also include financial information such as revenue, profits,
                        and growth projections, as well as details about the company’s leadership team,
                        organisational structure, and any awards or recognitions it has received.<br /><br />

                        The purpose of a company portfolio is to provide an overview of the company’s
                        offerings and capabilities to potential customers, investors, partners, and employees.
                        It can be used in sales and marketing efforts, investor pitches, and recruitment
                        materials, among other purposes.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Information that needed to be included in the company portfolio</b>
                    </p>
                    <p>
                        A company portfolio should include a variety of information to provide a comprehensive
                        overview of the company and its offerings. Here is a list of some of the information
                        that a company portfolio may include:
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Company Overview: </b>It should provide an overview of the company’s
                                history, mission statement, vision, and values.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Products and Services: </b>It describes the company’s current and past products and
                                services, along with any upcoming products or services in the pipeline.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Market Analysis: </b>This section should provide an analysis of the
                                target market and the competitive landscape, including information on key
                                competitors and market trends.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Financial Information: </b>It includes financial information such as
                                revenue, profits, and growth projections.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Leadership Team: </b>This section should provide information about the
                                company’s leadership team, including biographical information and their
                                roles and responsibilities.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Organizational Structure: </b> It  provides an overview of the company’s
                                organizational structure and how different teams and departments work
                                together.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Awards and Recognition: </b> This section should highlight any awards or
                                recognitions that the company has received, such as industry awards or
                                accolades from customers.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Contact Information: </b> It provides contact information for the
                                company, such as a phone number, email address, and physical address.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Testimonials: </b> This includes customer testimonials and case studies
                                that demonstrate the company’s value and effectiveness.
                            </span>
                        </div>
                        Overall, a company portfolio should provide a clear and compelling overview of the
                        company, its products and services, and its competitive positioning in the market.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Importance of the company portfolio</b>
                    </p>
                    <p>
                        The company portfolio is an important tool for any business to communicate its
                        capabilities, strengths, and offerings to potential customers, partners, and investors.
                        Here are some of the key reasons why the company portfolio is important:
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Establishes a Professional Image: </b>A well-designed and comprehensive
                                company portfolio can help establish a professional image and create a
                                positive first impression among potential customers and partners.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Provides an Overview of Products and Services: </b>The company portfolio
                                provides a detailed overview of the company’s products and services, which
                                can help customers understand what the company offers and whether it is a
                                good fit for their needs.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Demonstrates Competitive Advantage: </b>A company portfolio can showcase
                                the company’s competitive advantage and highlight how its products and
                                services are better than those of its competitors.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Attracts Investors: </b>Investors often look for companies with a clear
                                business plan and a well-articulated company portfolio can provide them with
                                the necessary information to make informed investment decisions.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Helps in Recruitment: </b>A company portfolio can help in attracting top
                                talent to the company, as it provides a clear picture of the company’s
                                mission, values, and culture, which can be important factors for potential
                                employees.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Acts as a Sales Tool: </b>The company portfolio can be used as a sales
                                tool to showcase the company’s capabilities and strengths to potential
                                customers, which can help close deals and generate revenue.
                            </span>
                        </div>
                        Overall, a company portfolio is an essential tool for any business to effectively
                        communicate its offerings, strengths, and competitive positioning, and can have a
                        significant impact on the success of the business.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>How to improve company portfolio</b>
                    </p>
                    <p>
                        Improving your company portfolio can help you to better communicate your strengths and
                        capabilities to potential customers, investors, and partners. Here are some tips on how
                        to improve your company portfolio:
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Focus on Your Unique Value Proposition: </b>Clearly articulate your
                                unique value proposition and what sets your company apart from the
                                competition. This can help customers better understand the benefits of
                                working with your company.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Use High-Quality Visuals: </b>Use high-quality visuals, such as
                                photographs and graphics, to make your portfolio visually appealing and
                                engaging. This can help to draw in potential customers and investors.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Provide Clear and Concise Information: </b>Make sure the information in
                                your portfolio is easy to understand and presented in a clear and concise
                                manner. Avoid jargon or technical terms that may be confusing to potential
                                customers.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Incorporate Customer Testimonials:</b> Including customer testimonials in
                                your portfolio can help to demonstrate the value of your products and
                                services and build credibility with potential customers.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Highlight Achievements and Awards: </b>If your company has received any
                                industry awards or accolades, make sure to highlight them in your portfolio.
                                This can help to build credibility and establish your company as a leader
                                in your field.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Keep It Up-to-Date: </b> Make sure to update your company portfolio
                                regularly to reflect any changes to your products or services, achievements,
                                or new information about your business.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Get Feedback: </b> Finally, consider getting feedback from customers,
                                investors, and other stakeholders to identify areas for improvement in your
                                company portfolio. This can help you to identify any weaknesses and make
                                improvements to better meet the needs of your target audience.
                            </span>
                        </div>
                        By following these steps, you can build a professional company portfolio that
                        effectively communicates your strengths, capabilities, and competitive positioning,
                        and helps to build credibility with potential customers, investors, and partners.
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={vatImg}
                        title="VAT Software"
                        buttonText="Read More"
                        buttonLink="/vat-software"
                    />
                    <MarqueeCard
                        imageUrl={hrImg}
                        title="HR Management Software"
                        buttonText="Read More"
                        buttonLink="/hr-management-software"
                    />
                    <MarqueeCard
                        imageUrl={erpImg}
                        title="ERP Management Software"
                        buttonText="Read More"
                        buttonLink="/erp-software"
                    />
                    <MarqueeCard
                        imageUrl={companyImg}
                        title="Company Portfolio"
                        buttonText="Read More"
                        buttonLink="/company-portfolio"
                    />
                    <MarqueeCard
                        imageUrl={accountngImg}
                        title="Accounting Software"
                        buttonText="Read More"
                        buttonLink="/acounting-software"
                    />
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Company Portfolio FAQ</h2>
                <Faq items={faqItems} />
            </div>
            <Footer />
        </>
    )
}

export default CompanyPortfolio