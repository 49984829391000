import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import BtclImg from "../../assets/images/JK-Banner.png"
import copyrightImg from "../../assets/images/copyRight-Registration-2.png"
import industrialImg from "../../assets/images/Industrial-Design.png"
import iprImg from "../../assets/images/Intellectual-property-rights-Bangladesh.png"
import patentImg from "../../assets/images/Patent-registration.png"
import trademarkImg from "../../assets/images/Trade-license-Registration.png"
import webdevelopImg from "../../assets/images/web-development-1.jpg"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import patentImg2 from "../../assets/images/Patent-Registration-Process.png"

function Patent() {
   const faqItems = [
      {
         title: 'Which Cannot be patented?',
         content: <strong>Chapter II, Section 3 of the Indian Patents Act stipulates what is not considered an
            invention under the law and is therefore not patentable in India: inventions being frivolous or
            contrary to public order, morality, public health, the environment, etc.</strong>
      },
      {
         title: 'Can I use an image from a patent?',
         content: <strong>Subject to limited exceptions reflected in 37 CFR 1.71(d) &amp; (e) and 1.84(s) , the text
            and drawings of a patent are typically not subject to copyright restrictions. The caveat is that the
            images may be copyrighted, but then they should be marked as such.</strong>
      },
      {
         title: 'How long does patent last?',
         content: <strong>For example, a laptop computer can involve hundreds of inventions, working together.
            How long does patent protection last? Patent protection is granted for a limited period,
            generally 20 years from the filing date of the application.</strong>
      }
   ];
   return (
      <>
         <Meta title="Patent"
            description="This page provides information about BSTI permission and related processes."
            image={patentImg}
            keywords="keyword1, keyword2, keyword3"
         />
         <NavBar />

         <div className="container-fluid hero-header bg-light py-5 mb-5">
            <div className="container py-5">
               <div className="row g-5 align-items-center">
                  <div className="col-lg-6">
                     <h1 className="display-4 mb-3 animated slideInDown">Patent</h1>
                     <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item"><Link to="#">Intellectual Property</Link></li>
                           <li className="breadcrumb-item active" aria-current="page">Patent</li>
                        </ol>
                     </nav>
                  </div>
                  <div className="col-lg-6 animated fadeIn">
                     <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={patentImg} alt='Patent-registration' />
                  </div>
               </div>
            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <p className="color-text fs-5 mb-4">
                  <b>How to a Patent registration in Bangladesh</b>
               </p>
               <p>
                  An application for a patent may be made by any Bangladeshi or any foreigner, and by alone or
                  jointly with any other person. The application must be made to the Department of Patents,
                  Designs and Trade Marks (DPDT) in the prescribed form. The application must contain a
                  declaration that the applicant is in possession of an invention, whereof he, or in the case
                  of a joint application one at least of the applicants, claims to be the true and first
                  inventor or the legal representative or assign of such inventor and for which he desires to
                  obtain a patent, and must be accompanied by complete specification.
               </p>
            </div>
         </div>

         <div className="container-xxl py-5">
            <div className="container">
               <div className="row g-5 align-items-center">
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                     <img className="img-fluid" src={patentImg2} alt='Patent-Registration-Process' />
                  </div>
                  <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                     <div className="h-100">
                        <p className="color-text fs-5 mb-4">
                           Patent in Bangladesh
                        </p>
                        <p>
                           An application for a patent may be made by any Bangladeshi or any foreigner, and
                           by alone or jointly with any other person. The application must be made to the
                           Department of Patents, Designs and Trade Marks (DPDT) in the prescribed form.
                           The application must contain a declaration that the applicant is in possession
                           of an invention, whereof he, or in the case of a joint application one at least
                           of the applicants, claims to be the true and first inventor or the legal
                           representative or assign of such inventor and for which he desires to obtain a
                           patent, and must be accompanied by complete specification.
                        </p>
                        <p className="color-text fs-5 mb-4">
                           SPECIFICATIONS
                        </p>
                        <p>
                           An application must contain a complete specification or provisional specification.
                           A provisional specification must describe the nature of the invention. A complete
                           specification must particularly describe and ascertain the nature of the invention
                           and the manner in which the same is to be performed. A specification, whether
                           provisional or complete, must commence with the title, and in the case of a
                           complete specification must end with a distinct statement, of the invention
                           claimed. If the applicant does not leave a complete specification with his
                           application, s/he may leave it at any subsequent time within nine months from
                           the date of the application<br /><br />
                           The Registrar may require that suitable drawings/model or sample of anything
                           illustrating the invention shall be supplied and such drawings/model or sample
                           shall be part of the complete specification.
                        </p>

                        <p className="color-text fs-5 mb-4">
                           FILING REQUIREMENT
                        </p>
                        <p>
                           To file a Patent Application following information are required:
                        </p>
                        <p>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Name of the inventor (applicant)
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Address(s) and nationality of the inventors
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Two sets of specification and one set of drawing on tracing paper (transparent)
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 One set Legalized Deed of Assignment (if any)
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Power of Attorney [Form – 31]
                              </span>
                           </div>
                           <div className="d-flex align-items-center mb-2">
                              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                              <span>
                                 Certified copy of the foreign patent (in case of claiming priority)
                              </span>
                           </div>
                        </p>
                        <p className="color-text fs-5 mb-4">
                           ADVERTISEMENT ON ACCEPTANCE OF APPLICATION
                        </p>
                        <p>
                           On the acceptance of an application the Registrar shall give notice thereof to
                           the applicant and shall advertise the acceptance and with the drawings (if any)
                           shall be open to public inspection.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="container-xxl py-0">
            <div className="container">
               <p className="color-text fs-5 mb-4">
                  <b>OPPOSITION</b>
               </p>
               <p>
                  Any person at any time within four months from the date of the advertisement of the
                  acceptance of an application give notice at the DPDT of opposition to the grant of the
                  patent. The opponent must state the grounds of his opposition.
               </p>
               <p className="color-text fs-5 mb-4">
                  <b>GRANT AND SEALING OF PATENT</b>
               </p>
               <p>
                  If there is no opposition a patent shall be granted, subject to such conditions as the
                  authority thinks expedient, to the applicant, or in the case of a joint application to the
                  applicants jointly, and the Controller shall cause the Patent to be sealed with the seal of
                  the Patent Office.
               </p>

               <p className="color-text fs-5 mb-4">
                  <b>TERM OF PATENT</b>
               </p>
               <p>
                  The term limited in every patent for the duration thereof is sixteen years from its date and
                  renewal is required after four years up to 15 years. In case of priority, the commencement
                  of four years shall start from the date of priority application.
               </p>

               <p className="color-text fs-5 mb-4">
                  <b>REMEDY FOR INFRINGEMENT</b>
               </p>
               <p>
                  A patentee may institute a suit in a District Court having jurisdiction to try the suit
                  against any person who makes, sells or uses the invention without his license, or
                  counterfeits it, or imitates it. A successful plaintiff is entitled to the relief in the
                  form of injection, damages or an account of profit.
               </p>
               <p className="color-text fs-5 mb-4">
                  <b>
                     Procedure for filing a patent application in the Department of Patents, Designs and
                     Trademarks, Ministry of Industries, Bangladesh:
                  </b>
               </p>
               <p>
                  <b>1. </b>Anybody whether Bangladeshi or not and whether alone or jointly can file an
                  application for Patent in the Department of Patents, Designs and Trademarks, Ministry of
                  Industries, 91, Motijheel C/A, Dhaka-1000.<br /><br />
                  <b>2. </b>In case of foreigners’ application should be made through Bangladeshi local
                  law Firm.<br /><br />
                  <b>3. </b>To file a Patent application the following documents and information should
                  be furnished:<br /><br />
                  <b>4. </b>A forwarding letter addressed to the Registrar of the Department of Patents,
                  Designs and Trademarks.<br /><br />
                  <b>5. </b>A forwarding letter addressed to the Registrar of the Department of Patents,
                  Designs and Trademarks.<br /><br />
                  <b>6. </b>Bank draft or duly filled in challah form of Tk. 2,000/- (Two thousands) only in
                  case of ordinary application having specification of 25 pages and 10 claims. More than 25
                  pages and 10 claims each page and each claim will be charged at Tk. 100/-
                  (One hundred) only<br /><br />
                  <b>7. </b>In case of priority application. Application fee is Tk.10, 000/-(Ten thousands)
                  only for 25 pages of specification and 10 claims. More than 25 pages and 10 claims each
                  page and each claim will be charged at Tk. 100/- (One hundred) only.<br /><br />
                  <b>8. </b>One set of drawing sheets (if any) in tracing paper with duplicate in photocopy.<br /><br />
                  <b>9. </b>Complete specification two sets (in Form3A).<br /><br />
                  <b>10. </b>The complete specification should include the followings:<br /><br />
                  <b>11. </b>Title of the invention, the nature of the invention and the manner in which it is to
                  be performed.<br /><br />
                  <b>12. </b>Whether the invention is a product/device/product or process or the both.<br /><br />
                  <b>13. </b> Filed of the invention.<br /><br />
                  <b>14. </b>Prior art of the invention.<br></br><br></br>
                  <b>15. </b>Objective of the invention.<br /><br />
                  <b>16. </b>Brief description of the drawings (if any).<br /><br />
                  <b>17. </b>Detailed description of the invention with reference to the accompanied drawings (if any).<br /><br />
                  <b>18. </b>Novelty of the invention.<br /><br />
                  <b>19. </b>Inventive steps of the invention.<br /><br />
                  <b>20. </b>Industrial applicability of the invention.<br /><br />
                  <b>21. </b>Claims (showing novelty).<br /><br />
                  <b>22. </b>Abstract.<br /><br />
                  <b>23. </b>Computer printed specification with font size 11-13 in A4 size offset paper.<br /><br />
                  <b>24. </b>Drawings (if any) should be according to rule 15 to 18 of the P&D Rules, 1933 and include:<br /><br />
                  <b>25. </b>A4 size tracing paper according to the provisions of the Patents& Designs Rules 1933 along with a duplicate in photo copy.<br /><br />
                  <b>27. </b>Name and number of the drawings should be referred to the description of the complete specification.<br /><br />
                  <b>28. </b>Margin in each side.<br /><br />
                  <b>29. </b>Applicants name and application number should be mentioned in the left top corner of the drawing sheets.<br /><br />
                  <b>30. </b>Sheet number and the consecutive number of sheets should be mentioned in the right top corner of each drawing sheets.<br /><br />
                  <b>31. </b>Each sheet of the drawings should be signed in the right bottom of each sheet by the applicant or the agent of the applicant.<br /><br />
                  <b>32. </b>In case of priority application, the priority documents should be submitted along with the application or within 12 months from the date of first filing as provided by the Paris Convention.<br /><br />
                  <b>33. </b>The normal deadline for acceptance of a Patent application is 18 months with 3 months extension from the date of filing.<br /><br />
                  <b>34. </b>In case of foreign application power of Attorney (in favor of the applicant) is to be submitted along with the application.<br /><br />
                  <b>35. </b>All types of Form and Fee Schedule are available in the DPDT’ website: www.dpdt.gov.bd.<br /><br />
                  <b>36. </b>Information relating to Attorney/agent is also available in the website.<br /><br />
               </p>
            </div>
         </div>

         <div className='container-xxl center-background1'>
            <h2> Related Articles</h2>
         </div>

         <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
               <MarqueeCard
                  imageUrl={webdevelopImg}
                  title="Foreign Company Formation"
                  buttonText="Read More"
                  buttonLink="/web-development"
               />
               <MarqueeCard
                  imageUrl={trademarkImg}
                  title="Trademark"
                  buttonText="Read More"
                  buttonLink="/trademark"
               />
               <MarqueeCard
                  imageUrl={patentImg}
                  title="Patent"
                  buttonText="Read More"
                  buttonLink="/patent"
               />
               <MarqueeCard
                  imageUrl={iprImg}
                  title="IPR Enforcement"
                  buttonText="Read More"
                  buttonLink="/ipr-enforcement"
               />
               <MarqueeCard
                  imageUrl={industrialImg}
                  title="Industrial Design"
                  buttonText="Read More"
                  buttonLink="/industrial-design"
               />
               <MarqueeCard
                  imageUrl={copyrightImg}
                  title="Copyright"
                  buttonText="Read More"
                  buttonLink="/copyright"
               />
               <MarqueeCard
                  imageUrl={BtclImg}
                  title="BTCL Domain Registration"
                  buttonText="Read More"
                  buttonLink="/btcl-domain-registration"
               />

            </Marquee>
         </div>

         <div className="container py-5 my-0">
            <h2 className="color-text text-center mb-4">Pantent FAQ</h2>
            <Faq items={faqItems} />
         </div>
         <Footer />
      </>
   )
}

export default Patent