import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import civilImg from "../../assets/images/Civil-Litigation.png"
import criminalImg from "../../assets/images/Criminal-Litigation-1.png"
import fireImg from "../../assets/images/Fire-License-In-Bangladesh.png"
import factoryImg from "../../assets/images/Fire-License-In-Bangladesh-2.png"
import bondImg from "../../assets/images/Fire-License-In-Bangladesh-4.png"
import environmentImg from "../../assets/images/Environmental.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import fireImg2 from "../../assets/images/process-for-obtaining-a-fire-License.png"

function FireLicenseScreen() {
    const faqItems = [
        {
            title: 'How can I get fire Licence in Bangladesh?',
            content: <strong>How to Obtain Fire License in Bangladesh
                1. Duly filled-in prescribed application form.
                2. Trade license.
                3. Yearly Valuation Certificate from the relevant City Corporation or Municipality&#39;s
                zonal office.
                4. Deed of Agreement and reciept of rent.</strong>
        },
        {
            title: 'What is the fire license Act in Bangladesh?',
            content: <strong>A fire license is a necessary document for the garments and all factories established in
                Bangladesh. This license is issued under the Fire Prevention and Fire Fighting Act 2003 and
                issued by the Fire Service and Civil Defence (FSCD).</strong>
        },
        {
            title: 'What is the work of fire and safety?',
            content: <strong>Fire safety is the set of practices intended to reduce destruction caused by fire. Fire
                safety measures include those that are intended to prevent the ignition of an uncontrolled fire
                and those that are used to limit the spread and impact of a fire.</strong>
        }
    ];
    return (
        <>
            <Meta title="Fire License"
                description="Fire License"
                image={fireImg2}
                keywords="Fire License,Health License"
            />

            <NavBar />

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Fire License</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Regulatory License</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Fire License</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={fireImg} alt='Fire-License-In-Bangladesh' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>What is Fire License?</b>
                    </p>
                    <p>
                        In Bangladesh, a Fire License is a permit issued by the government or local
                        authorities to ensure that a building or facility complies with the fire safety
                        regulations and standards. The license is issued after an inspection of the building
                        or facility to verify that adequate fire prevention and protection measures are in
                        place, such as fire alarms, smoke detectors, fire extinguishers, emergency exits, and
                        evacuation plans.<br /><br />

                        The Fire License is mandatory for all types of commercial, industrial, and residential
                        buildings in Bangladesh, including offices, factories, warehouses, shopping malls,
                        hospitals, and educational institutions. The license is renewable annually and failure
                        to obtain or renew the license can result in fines or closure of the building or
                        facility.<br /><br />

                        In summary, a Fire License in Bangladesh is a legal requirement that ensures the
                        safety of the building occupants in case of a fire emergency.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Who issues the Fire License in Bangladesh?</b>
                    </p>
                    <p>
                        The Fire License in Bangladesh is issued by the Fire Service and Civil Defense (FSCD)
                        department of the government. The FSCD is responsible for enforcing fire safety
                        regulations and ensuring that buildings and facilities comply with fire safety
                        standards.<br /><br />

                        To obtain a Fire License, the owner or occupant of the building or facility must
                        submit an application to the FSCD along with the required documents, such as building
                        layout plans, fire safety equipment certificates, and other relevant information. The
                        FSCD then conducts an inspection of the premises to verify compliance with fire
                        safety regulations before issuing the Fire License.<br /><br />

                        It’s important to note that different regions in Bangladesh may have their own Fire
                        Service and Civil Defense offices that issue Fire Licenses.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>What are the criteria for obtaining a Fire License in Bangladesh?</b>
                    </p>
                    <p>
                        To obtain a Fire License in Bangladesh, a building or facility must meet certain
                        criteria and comply with fire safety regulations. The criteria for obtaining a Fire
                        License in Bangladesh typically include the following:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Fire safety equipment: </b>The building must be equipped with fire safety
                                equipment, including fire alarms, smoke detectors, fire extinguishers, and
                                sprinkler systems. The equipment must be properly installed and maintained
                                to ensure its effectiveness.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Emergency exits and evacuation plans: </b>The building must have clearly
                                marked emergency exits that are easily accessible, and evacuation plans must
                                be in place to ensure a safe and orderly evacuation in case of a fire
                                emergency.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Electrical safety: </b>The building’s electrical wiring and fixtures must
                                be installed and maintained to prevent fire hazards.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Storage and handling of flammable materials: </b>Any flammable materials,
                                such as chemicals or fuels, must be stored and handled in accordance with
                                safety regulations to prevent the risk of fire.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Structural safety: </b>The building’s structure and materials must meet
                                safety standards and be able to withstand fire hazards.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Compliance with local regulations: </b>The building must comply with all
                                relevant local regulations and building codes related to fire safety.
                            </span>
                        </div>
                        The specific criteria and requirements for obtaining a Fire License may vary
                        depending on the type of building or facility and its intended use. The Fire Service
                        and Civil Defense department of the government typically conducts an inspection of
                        the building or facility to verify compliance with fire safety regulations before
                        issuing the Fire License.
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid" src={fireImg2} alt='process-for-obtaining-a-fire-License' />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">
                                    What types of businesses or buildings require a Fire License in
                                    Bangladesh?
                                </p>
                                <p>
                                    In Bangladesh, a Fire License is mandatory for most types of buildings
                                    and facilities where people work, gather, or reside. The following are
                                    some examples of businesses or buildings that require a Fire License:
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            <b>Commercial buildings: </b>It includes offices, shopping malls,
                                            supermarkets, banks, and other similar establishments.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            <b>Industrial establishments: </b>The factories, warehouses, and other manufacturing facilities.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            <b>Educational institutions: </b>It includes schools, colleges, and universities.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            <b>Hospitals and healthcare facilities: </b>Also, includes
                                            hospitals, clinics, and other healthcare facilities.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            <b>Places of assembly: </b>This includes movie theaters,
                                            auditoriums, stadiums, and other places where people gather for
                                            events or performances.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            <b>Hotels and guest houses: </b>And it includes hotels, motels, and guest
                                            houses.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            <b>Residential buildings: </b>This adds multi-storied apartments,
                                            residential complexes, and other buildings where people live.
                                        </span>
                                    </div>
                                    It’s important to note that the specific requirements for obtaining a
                                    Fire License may vary depending on the type of building or facility and
                                    its intended use. The Fire Service and Civil Defense department of the
                                    government typically conducts an inspection of the building or facility
                                    to verify compliance with fire safety regulations before issuing the
                                    Fire License.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>What is the process for obtaining a fire License in Bangladesh?</b>
                    </p>
                    <p>
                        The process for obtaining a Fire License in Bangladesh typically involves the following
                        steps:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Application: </b>The owner or occupant of the building or facility must
                                submit an application to the local Fire Service and Civil Defense (FSCD)
                                office. The application form may be available online or can be obtained from
                                the FSCD office.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Documentation: </b>The application must be accompanied by supporting
                                documents, such as building layout plans, fire safety equipment certificates,
                                and other relevant information.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Inspection: </b>Once the application is received, the FSCD conducts an
                                inspection of the building or facility to verify compliance with fire safety
                                regulations. During the inspection, the FSCD checks for the presence and
                                proper functioning of fire safety equipment, emergency exits, and evacuation
                                plans.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Correction of deficiencies: </b>If any deficiencies are identified during
                                the inspection, the owner or occupant of the building or facility is
                                required to correct them before a Fire License can be issued.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Issuance of Fire License: </b>If the building or facility meets all the
                                criteria and complies with fire safety regulations, the FSCD issues a Fire
                                License. The license is usually valid for one year and must be renewed
                                annually.
                            </span>
                        </div>
                        It’s important to note that the specific requirements and procedures for obtaining a
                        Fire License may vary depending on the region and type of building or facility. It’s
                        best to check with the local Fire Service and Civil Defense office for the exact
                        requirements and procedures in your area.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>The Documents needed to apply for a Fire License In Bangladesh</b>
                    </p>
                    <p>
                        To apply for a Fire License in Bangladesh, the following documents are typically required:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Building layout plan: </b>This is a detailed plan of the building or
                                facility that shows the layout, dimensions, and location of rooms, doors,
                                windows, and other features.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Fire safety equipment certificate: </b>The certificate verifies that the
                                fire safety equipment, such as fire alarms, smoke detectors, fire
                                extinguishers, and sprinkler systems, are installed and functioning
                                properly.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Electrical safety certificate: </b>The certificate verifies that the
                                building’s electrical wiring and fixtures are installed and maintained to
                                prevent fire hazards.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Certificate of occupancy: </b>This certificate verifies that the building
                                or facility has been inspected and approved for occupancy by the relevant
                                local authority.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Trade license: </b>It is a license issued by the local municipality or
                                city corporation that authorizes the business to operate in that area.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Tax identification number (TIN): </b>A unique identification number
                                issued by the National Board of Revenue that is required for tax purposes.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Any other relevant documents: </b>Depending on the type of building or
                                facility, additional documents may be required, such as a certificate of
                                incorporation or partnership deed for businesses, or a land ownership
                                certificate for residential buildings.
                            </span>
                        </div>
                        It’s important to note that the specific documents required for obtaining a Fire
                        License may vary depending on the region and type of building or facility. It’s best
                        to check with the local Fire Service and Civil Defense office for the exact
                        requirements in your area.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Safety issues that businesses must comply with to obtain a Fire License</b>
                    </p>
                    <p>
                        The following are some of the fire safety regulations in Bangladesh that businesses
                        and buildings must comply with to obtain a Fire License:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Building construction: </b>This must be constructed with fire-resistant
                                materials and should have adequate fire protection systems, such as
                                fire-rated walls, doors, and windows.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Fire safety equipment: </b>Fire safety equipment: The building must have
                                fire safety equipment, such as fire extinguishers, smoke detectors, and fire
                                alarms, which should be properly installed, maintained, and tested regularly.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Emergency exits: </b>There must be sufficient emergency exits that are
                                clearly marked, well-lit, and free from obstructions. The emergency exits
                                must also be connected to the building’s fire alarm system.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Evacuation plan: </b>The building must have a clearly defined evacuation
                                plan that is communicated to all occupants and employees. The plan should
                                include procedures for alerting people in case of fire, evacuation routes,
                                and designated assembly points.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Training: </b>The owner or occupant must provide training to all
                                employees on fire safety procedures, emergency response, and evacuation
                                plans.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Electrical safety: </b>The building’s electrical wiring and fixtures must
                                be installed and maintained in accordance with national electrical codes to
                                prevent electrical fires.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Smoking restrictions: </b>Smoking should be prohibited inside the
                                building or facility, and designated smoking areas should be provided
                                outside the building.
                            </span>
                        </div>
                        It’s important to note that the specific fire safety regulations may vary depending
                        on the type of building or facility. It’s best to check with the local Fire Service
                        and Civil Defense office for the exact requirements in your area.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>What are the consequences of operating a business or occupying a building without a Fire License</b>
                    </p>
                    <p>
                        Operating a business or occupying a building without a Fire License in Bangladesh is a
                        violation of the law and can have serious consequences. Some of the consequences
                        include:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Fines: </b>The owner or occupant of the building may be fined for
                                operating without a Fire License. The amount of the fine may vary depending
                                on the severity of the violation and can range from a few thousand to
                                several hundred thousand taka.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Closure of business: </b>The local authorities may order the closure of
                                the business or facility until a Fire License is obtained. This can result
                                in loss of revenue, business interruption, and damage to the reputation of
                                the business.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Legal action: </b>The local authorities may take legal action against the
                                owner or occupant of the building for violating fire safety regulations.
                                This can result in legal fees, court appearances, and potentially, criminal
                                charges.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Increased risk of fire: </b>Operating without a Fire License can increase
                                the risk of fire in the building or facility. This can put the lives of
                                occupants and employees at risk and may result in property damage or loss.
                            </span>
                        </div>
                        It’s important to note that the consequences of operating without a Fire License may
                        vary depending on the region and type of building or facility. It’s best to check
                        with the local Fire Service and Civil Defense office for the exact consequences in
                        your area.
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={environmentImg}
                        title="Environment Clearance Certificate"
                        buttonText="Read More"
                        buttonLink="/environment-clearance-certificate"
                    />
                    <MarqueeCard
                        imageUrl={bondImg}
                        title="Custom Bond License"
                        buttonText="Read More"
                        buttonLink="/customs-bond-license"
                    />
                    <MarqueeCard
                        imageUrl={factoryImg}
                        title="Factory License"
                        buttonText="Read More"
                        buttonLink="/factory-license"
                    />
                    <MarqueeCard
                        imageUrl={fireImg}
                        title="Fire License"
                        buttonText="Read More"
                        buttonLink="/fire-license"
                    />
                    <MarqueeCard
                        imageUrl={criminalImg}
                        title="Criminal Litigation"
                        buttonText="Read More"
                        buttonLink="/criminal-litigation"
                    />
                    <MarqueeCard
                        imageUrl={civilImg}
                        title="Civil Litigation"
                        buttonText="Read More"
                        buttonLink="/civil-litigation"
                    />


                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Fire License FAQ</h2>
                <Faq items={faqItems} />
            </div>
            <Footer />
        </>
    )
}

export default FireLicenseScreen