import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';

import vatlImg from "../../assets/images/Vat-Consultant-1.png"
import individualImg from "../../assets/images/income-Tax-1.png"
import etinImg from "../../assets/images/e-tin.png"
import corporateImg from "../../assets/images/Corporate-Tax-Rate-in-Bangladesh.png"
import binImg from "../../assets/images/BIN-Registration-1.png"
import Marquee from 'react-fast-marquee';
import MarqueeCard from '../../components/MarqueeCard'
import vatImg2 from "../../assets/images/VAT-Consultant-process.png"

function ValueAddedTax() {
    const faqItems = [
        {
            title: 'What is an example of a value-added tax?',
            content: <strong>Value Added Tax (VAT) - Overview, How To Calculate, Example
                The VAT is assessed incrementally at each stage of the production process, where value is added. However,
                it is ultimately passed on to the final retail consumer. For example, if there is a 20% VAT on a product that costs $10,
                the consumer will end up paying a price of $12.</strong>
        },
        {
            title: 'What is 12% value added tax?',
            content: <strong>On importation of goods - twelve percent (12%) based on the total value used by the Bureau of Customs in determining tariff and customs duties, plus customs duties, excise taxes, if any, and other charges, such as tax to be paid by
                the importer prior to the release of such goods from customs custody; provided.</strong>
        },
        {
            title: 'How do you calculate VAT value added tax?',
            content: <strong>For a purchase price of x, we multiply x by 15%. But recall that 15% means 15 per 100 or 15/100. So the VAT amount on x is simply x multiplied by 15/100 = (x)(15/100).
                This means that there is VAT payable of R7 on a purchase price of R50</strong>
        }
    ];
    return (
        <>
            <Meta title="Value Added TAX || VAT"
                description="This page provides information about BSTI permission and related processes."
                image={vatlImg}
                keywords="keyword1, keyword2, keyword3"
            />
            
            <NavBar />

            <div className="container-fluid hero-header bg-light py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Value Added TAX</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Taxation</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Value Added TAX</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={vatlImg} alt='Vat-Consultant-1' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>Monthly VAT Return – in View of Bangladesh</b>
                    </p>
                    <p>
                        This paper represents an overview of Value Added Tax (VAT) in Bangladesh. It depicts the basic features of Value Added Tax and its implication, and importance in the growing economy of Bangladesh. Bangladesh faces many problems in raising sufficient tax revenues to fund its economic and social development. To address this problem and to improve economic efficiency and growth, a major tax reform program was initiated in 1991 which centered on the introduction of a value-added tax (VAT) to replace a range of narrowly-based consumption taxes. This study works as a linkage between theory and practice on Value Added Tax.  The article represents the  social  and economic development of  the country  with the basic awareness which  is going at a steady pace among the  people and the organization considering it as a key  to further  economic Development. The awareness of Value Added Tax (VAT) is not very old, but still within a short span of time it has shown a remarkable change in the corporate sector and the economy of the country. It has developed a complete sense of care and responsibility towards the country and the welfare of the people.
                    </p>

                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid" src={vatImg2} alt='VAT-Consultant-process' />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">
                                    1. Introduction
                                </p>
                                <p>
                                    A value-added tax or value-added tax (VAT) is a form of consumption tax. From
                                    the perspective of the buyer, it is a tax on the purchase price, whereas, from
                                    the seller‘s point of view, it is a tax only on the "value-added" to a product,
                                    material or service, at the stage of its manufacture or distribution. The
                                    manufacturer remits to the government the difference between these two amounts
                                    and retains the rest for themselves to offset the taxes them
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    2. An Overview of Value Added Tax
                                </p>
                                <p>
                                    Referred to as a sort of national sales tax, though it functions very differently. Sales tax is imposed on the total retail price of the item sold, while VAT tax is imposed on the value added at each stage of production and distribution. And though more complicated than sales tax, value-added tax systems have more checks against tax fraud because the tax is assessed at more than one point in the distribution process.
                                </p>
                                <p>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            on the basis of coverage of stages - throughout the production and distribution stages,  or confined to limited stages  - manufacturing plus wholesale, or wholesale plus retail;
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            On the basis of the method of calculation - tax credit method, subtraction Method, and addition method; and
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Two sets of specification and one set of drawing on tracing paper (transparent)
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            On the basis of tax treatment of final product capital goods such as machinery, equipment, and supplies - the consumption form, the income from, and the product variety. Thus the three broad types of VAT are the gross national product (GNP) type, income type, and consumption type. A consumption-type VAT is an indirect tax. An income type or a GNP type VAT might be considered as a direct tax but a commodity tax cannot be considered so. Consumption type VAT is also considered as an alternative form of 'sales tax'.
                                        </span>
                                    </div>
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    3. The Vat Assessment Process
                                </p>
                                <p>
                                    The process of assessing value-added tax occurs roughly as follows:
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The manufacturer adds value to a product; the amount of value-added can be described as the difference between the cost of the materials used to make the product and the price charged to the customer (often a wholesaler).
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The manufacturer pays value-added tax (a percentage of the value-added), which is then included in the purchase price charged to the customer (wholesaler).
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The manufacturer gets a rebate from the government for VAT paid on the materials.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The customer (wholesaler) pays VAT on the value they add, which can be described as the difference between what they paid to the manufacturer and the price at which they sell it to their customer (retailer). This VAT amount is included in the price charged to the retailer.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The wholesaler gets a rebate for VAT from the government for the VAT paid to the manufacturer.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The retailer pays value-added tax on the value they add, which can be described as the price charged to customers less the wholesale cost and includes the VAT in the final sales price of the product. The retail store collects value-added tax from the person buying the product (retail price thus includes all VATs collected at each stage of this process) and gets a rebate for the VAT paid to the wholesaler.
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        4. Basic Characteristics of Value Added Tax
                    </p>
                    <p>
                        <b>Basis for VATs</b>
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    <MarqueeCard
                        imageUrl={binImg}
                        title="BIN Registration"
                        buttonText="Read More"
                        buttonLink="/bin-registration"
                    />
                    <MarqueeCard
                        imageUrl={corporateImg}
                        title="Corporate TAX"
                        buttonText="Read More"
                        buttonLink="/corporate-tax"
                    />
                    <MarqueeCard
                        imageUrl={etinImg}
                        title="E-TIN Certificate"
                        buttonText="Read More"
                        buttonLink="/etin-certificate"
                    />
                    <MarqueeCard
                        imageUrl={individualImg}
                        title="Individual TAX"
                        buttonText="Read More"
                        buttonLink="/individual-tax"
                    />
                    <MarqueeCard
                        imageUrl={vatlImg}
                        title="Value Added TAX"
                        buttonText="Read More"
                        buttonLink="/value-added-tax"
                    />
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Value Added Tax FAQ</h2>
                <Faq items={faqItems} />
            </div>
            <Footer />
        </>
    )
}

export default ValueAddedTax