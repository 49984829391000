import React from 'react';
import Slider from 'react-slick';
import img1 from "../assets/images/Foreign-Entity.png"
import img2 from "../assets/images/2.png"
import img3 from "../assets/images/3.png"
import img4 from "../assets/images/4.png"

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function HomeSlideShare() {
    const slides = [
        { id: 1, imageUrl: img1 },
        { id: 2, imageUrl: img2 },
        { id: 3, imageUrl: img3 },
        { id: 4, imageUrl: img4 },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        centerPadding: '0',
        customPaging: (i) => (
            <button style={{ color: 'blue', fontSize: '30px' }}>
                {/* Adjust the fontSize to make the dots larger */}
            </button>
        ),
        appendDots: (dots) => (
            <div style={{ position: 'absolute', bottom: '10px', width: '100%', textAlign: 'center' }}>
                {/* Position the dots at the bottom and center of the slider */}
                <ul style={{ margin: '0', padding: '0', display: 'inline-block' }}>{dots}</ul>
            </div>
        ),
    };

    return (
        <div>
            <Slider {...settings}>
                {slides.map((slide) => (
                    <div key={slide.id}>
                        <img
                            src={slide.imageUrl}
                            alt={`Slide ${slide.id}`}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default HomeSlideShare;
