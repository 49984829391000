import React from 'react';
import BlogComponent from '../../components/BlogComponent';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { blogPosts } from '../../data/blogPosts ';
import { Link } from 'react-router-dom';

function BlogCard() {
  return (
    <div className="App">
      <NavBar />

      <nav className=" container-xxl  navbar navbar-expand-lg navbar-light bg-light" style={{ textAlign: 'center', marginTop: '150px' }}>
        <div className="container-fluid">
          <Link style={{fontSize: "25px"}} className="navbar-brand" href="#">Blog</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
            <li className="nav-item dropdown">
                <Link style={{fontSize: "18px"}} className="nav-link dropdown-toggle active" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Start A Business
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li><Link className="dropdown-item" href="#">Proprietorship</Link></li>
                  <li><Link className="dropdown-item" href="#">Partnership</Link></li>
                  <li><Link className="dropdown-item" href="#">Company Registration In Bangladesh</Link></li>
                  <li><Link className="dropdown-item" href="#">Liason or branch office in Bangladesh</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link style={{fontSize: "18px"}}  className="nav-link" aria-current="page" href="#">License and Approval</Link>
              </li>
              <li className="nav-item">
                <Link style={{fontSize: "18px"}} className="nav-link" href="#">Legal System</Link>
              </li>
              <li className="nav-item">
                <Link style={{fontSize: "18px"}} className="nav-link" href="#">Foreign Investment</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <h2 style={{ textAlign: 'center', marginTop: '150px' }}>Latest Blog Posts</h2>
      <div className="blog-grid container-xxl py-5">
        {blogPosts.map((post, index) => (
          <BlogComponent
            key={index}
            image={post.image}
            title={post.title}
            summary={post.summary}
            author={post.author}
            date={post.date}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default BlogCard;
