import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EmailForm from './EmailForm';
import { FaWhatsapp } from 'react-icons/fa';
import { RiWechat2Line } from 'react-icons/ri';
import { MdOutlineEmail } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import ChatBotComponent from './MessengerCustomerChat';
import JKAWAUP from '../assets/images/JKA-WA-UP.png';
import JKACopyright from '../assets/images/JKA-Copyright.png';
import JKAWeChatUP from '../assets/images/JKA-WeChat-UP.png';
import JKApayments from '../assets/images/JKA-payments.png';
import send from '../assets/images/Send.png';
import jklogolong from '../assets/images/jk logo-long.png';

function Footer() {
  return (
    <>
      <div className="text-center mt-0">
        <img className="mx-auto " src={send} alt='send' style={{ width: '100%', height: '50%' }} />
      </div>

      <div className="container-fluid bg-light footer pt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container">
          <div className="row g-3">

            <EmailForm />

            <div className="text-center">
              <p className="heading">We Accept EMI</p>
            </div>

            <div className="col-lg-12">
              <div className="text-center">
                <img className="mx-auto responsive-img" src={JKApayments} alt='JKA-payments' />
              </div>
            </div>

            <div className="col-lg-4 ">
              <div className="text-center">
                <img className="mx-auto responsive-img" src={JKAWAUP} alt='Whatsapp' />
              </div>
            </div>

            <div className="col-lg-4 ">
              <div className="text-center">
                <img className="mx-auto responsive-img" src={JKACopyright} alt='copyright' />
              </div>
            </div>

            <div className="col-lg-4 ">
              <div className="text-center">
                <img className="mx-auto responsive-img" src={JKAWeChatUP} alt='wechat' />
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <h5 className="mb-4">ABOUT US</h5>
              <img src={jklogolong} alt='send' style={{ width: '60%', height: '40px' }} />
              <p style={{ textAlign: 'justify' }}>
                JK Associates is the most prominent Corporate Consultancy and Law firm in Bangladesh.
                We provide specialized services in Business Setup (Local and Foreign company), Intellectual
                Property Rights (Trademark, Copyright), Corporate Affairs, Foreign Direct Investment (FDI)
                and Taxation (Tax, VAT).<br />
                <Link className='mb-0 btn btn-link' to="/about-us">Know more...</Link>
              </p>
            </div>

            <div className="col-lg-2 col-md-6" >
              <h5 className="mb-2">LET’S GROW!</h5>
              <Link to='/contact-us'><p className="mb-0 btn btn-link">Contact Us</p></Link>
              <Link to='/privacy-policy'><p className="mb-0 btn btn-link">Privacy Policy</p></Link>
              <Link to='/term-condition'><p className="mb-0 btn btn-link">Terms and Conditions </p></Link>
              <Link to="/sitemap"><p className="mb-0 btn btn-link">Sitemap</p></Link>
              <Link to="/payment-process"><p className="mb-0 btn btn-link">Payment Process</p></Link>
              <Link to="/refund-policy"><p className="mb-0 btn btn-link">Refund & Return Policy</p></Link>
            </div>
            <div className="col-lg-2 col-md-6" style={{ marginTop: '40px' }}>
              <Link to='https://trademarkbd.com/blog/'><p className="mb-0 btn btn-link">Trademark Search </p></Link>
              <Link to='/co-space'><p className="mb-0 btn btn-link">JK Cospace</p></Link>
              <Link to="/vat-calculator"><p className="mb-0 btn btn-link">VAT Calculator</p></Link>
              <Link to='/visa-consultancy'><p className="mb-0 btn btn-link">VISA Consultancy</p></Link>
              <Link to="/property-consultancy"><p className="mb-0 btn btn-link">Property Consultancy</p></Link>
              <Link to="/blog"><p className="mb-0 btn btn-link">Resources</p></Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <h5 className="mb-4">Get In Touch</h5>
              <Link><p className="mb-0 btn btn-link-1"><FaLocationDot style={{ fontSize: '16px' }} /> 752/2, Begum Rokeya
                Ave, Mirpur, Dhaka 1216</p></Link>
              <Link><p className="mb-0 btn btn-link-1"><FaPhoneAlt style={{ fontSize: '16px' }} /> +8801511-404971, +8801973-404971</p></Link>
              <Link><p className="mb-0 btn btn-link-1"><MdOutlineEmail style={{ fontSize: '16px' }} /> info@jkassociates.com.bd</p></Link>
              <Link to="https://api.whatsapp.com/send?phone=01511404971"><p className="mb-0 btn btn-link-1"><FaWhatsapp style={{ fontSize: '16px' }} /> Whatsapp</p></Link>
              <Link to="https://api.wechat.com/send?phone=01511404971"><p className="mb-0 btn btn-link-1"><RiWechat2Line style={{ fontSize: '18px' }} /> We Chat</p></Link>
            </div>
          </div>
        </div>
        <div className="container-fluid copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center d-flex align-items-center justify-content-center mb-3 mb-md-0">
                © Registered company SC 333597 | JK Consultancy is a trading name of&nbsp; <Link target='_blank' to="https://jkassociates.com.bd/">JK Associates</Link>
              </div>
              <div className="col-md-6 text-center d-flex align-items-center justify-content-center mb-3 mb-md-0">
                <div className="d-flex" style={{ gap: '20px' }}>
                  <Link className="btn btn-square rounded-circle me-1" target='_blank' to="#"><i className="fab fa-twitter" /></Link>
                  <Link className="btn btn-square rounded-circle me-1" target='_blank' to="https://www.facebook.com/jkassociates.com.bd"><i className="fab fa-facebook-f" /></Link>
                  <Link className="btn btn-square rounded-circle me-1" target='_blank' to="#"><i className="fab fa-youtube" /></Link>
                  <Link className="btn btn-square rounded-circle me-1" target='_blank' to="https://www.linkedin.com/company/31521176/admin/feed/posts/"><i className="fab fa-linkedin-in" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top">
        <i className="bi bi-arrow-up"></i>
      </a>
      {/* WhatsApp button with tooltip */}
      <Link
        to="https://api.whatsapp.com/send?phone=01511404971"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
        style={{ position: 'fixed', bottom: '18px', right: '80px' }}
        title="WhatsApp us"
      >
        <FaWhatsapp />
      </Link>

      {/* Messenger button with tooltip */}
      {/* <Link
        to="https://m.me/160620561023773"  // Updated link
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
        style={{ position: 'fixed', bottom: '80px'}}
        title="Any Query"
      >
        <FaFacebookMessenger/>
      </Link> */}

      <div className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top" style={{ position: 'fixed', bottom: '80px' }}>
        <ChatBotComponent />
      </div>
    </>
  )
}

export default Footer